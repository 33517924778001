import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/lib/locale/it';
import '@mdi/font/css/materialdesignicons.css';
import locales from '@/plugins/i18n';

Vue.use(Vuetify);

export default process.env.NODE_ENV === 'demo'
  // Demo Theme
  ? new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
      light: true,
      themes: {
        light: {
          bgColor: {
            base: '#062b5e',
          },
          primary: {
            lighten1: '#cfefe7',
            base: '#35d1ad',
            darken1: '#1e7e84',
          },
          secondary: {
            lighten1: '#c7ced8',
            lighten2: '#2f435e',
            base: '#062b5e',
            darken1: '#1b2540',
            darken2: '#1b2540',
            darken3: '#1b2540',
            darken4: '#1b2540',
          },
          kpi: {
            lighten1: '#ddd8d9',
            base: '#e0e9f7',
          },
          kpitext: {
            base: '#3f4a5b',
          },
          btn: {
            lighten1: '#3c3c3b',
            base: '#3c3c3b',
            darken1: '#ddd8d9',
          },
          appBarLeft: {
            base: '#0d466b',
          },
          appBarRight: {
            base: '#2dbca3',
          },
          accent: '#3f4a5b',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
      },
    },
    lang: {
      locales,
      current: 'it',
    },
    icons: {
      iconfont: 'mdi',
    },
  })
  // Mangias Theme
  : new Vuetify({
    theme: {
      options: {
        customProperties: true,
      },
      light: true,
      themes: {
        light: {
          bgColor: {
            base: '#3f4a5b',
          },
          primary: {
            lighten1: '#e0e9f7',
            base: '#9EAEA4',
            darken1: '#1e7e84',
            darken2: '#96b2dd',
          },
          secondary: {
            lighten1: '#c7ced8',
            lighten2: '#2f435e',
            base: '#3f4a5b',
            darken1: '#514f4f',
            darken2: '#514f4f',
            darken3: '#514f4f',
            darken4: '#514f4f',
          },
          kpi: {
            lighten1: '#ddd8d9',
            base: '#e0e9f7',
          },
          kpitext: {
            base: '#3f4a5b',
          },
          btn: {
            lighten1: '#3c3c3b',
            base: '#3c3c3b',
            darken1: '#ddd8d9',
          },
          appBarLeft: {
            base: '#424f51',
          },
          appBarRight: {
            base: '#424f51',
          },
          accent: '#3f4a5b',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
        },
      },
    },
    lang: {
      locales,
      current: 'it',
    },
    icons: {
      iconfont: 'mdi',
    },
  });

<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-large
        icon
        color="white"
        v-bind="attrs"
        v-on="on"
        class="mx-2"
      >
        <v-icon>translate</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group
        v-model="currentLocale"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in availableLocales"
          :key="i"
          @click="changeLocale(item.locale)"
        >
          <v-list-item-title>{{ $vuetify.lang.t(item.displayName) }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LocaleSelectionMenu',
  created() {
    moment.locale(this.$vuetify.lang.current);
  },
  data() {
    const availableLocales = Object.keys(this.$vuetify.lang.locales)
      .map((locale) => ({
        locale,
        displayName: `$vuetify.locales.${locale}`,
      }));
    const currentLocale = availableLocales
      .findIndex((el) => el.locale === this.$vuetify.lang.current);
    return {
      availableLocales,
      currentLocale,
    };
  },
  mounted() {

  },
  methods: {
    changeLocale(locale) {
      moment.locale(locale);
      this.$vuetify.lang.current = locale;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-menu v-model="selectionOpen">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        color="white"
        v-bind="attrs"
        v-on="on"
        class="filter-text"
      >
        <span
          v-if="selection.length === 0"
          class="selection-label"
        >
          {{ $vuetify.lang.t('$vuetify.filterBar.channel') }}
        </span>
        <v-chip
          v-else
          color="secondary"
          label
          small
        >
          <span>{{ displayValue }}</span>
        </v-chip>
        <span
          v-if="selection.length > 1"
          class="grey--text caption ml-1"
        >
          (+{{ selection.length - 1 }})
        </span>
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="py-2 pl-0 pr-2">
        <v-treeview
          :items="items"
          v-model="selection"
          return-object
          dense
          selectable
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
const treeItems = [
  {
    id: 1,
    name: 'Trade',
    children: [
      { id: 101, name: 'VP', dbValue: 'VUOTO PIENO' },
      { id: 102, name: 'TO', dbValue: 'TOUR OPERATOR' },
      { id: 103, name: 'TA', dbValue: 'TRAVEL AGENCY' },
      { id: 104, name: 'TA PPP', dbValue: 'TRAVEL AGENCY PPP' },
    ],
  },
  {
    id: 2,
    name: 'Final',
    children: [
      {
        id: 201,
        name: 'FOTA',
        children: [
          { id: 2011, name: 'Booking.com', dbValue: 'BOOKING.COM' },
          { id: 2012, name: 'Expedia', dbValue: 'EXPEDIA' },
          { id: 2013, name: 'Agoda', dbValue: 'AGODA' },
          // { id: 2014, name: 'Tripadvisor', dbValue: 'TRIPADVISOR' },
          { id: 2015, name: 'Google Ads', dbValue: 'GOOGLE ADS' },
          { id: 2016, name: 'Lastminute.com', dbValue: 'LASTMINUTE.COM' },
          { id: 2016, name: 'Secret Escape', dbValue: 'SECRET ESCAPE' },
        ],
      },
      { id: 202, name: 'Web Site', dbValue: 'WEB SITE' },
      { id: 203, name: 'FDIR', dbValue: 'FDIR CRO' },
    ],
  },
  {
    id: 3,
    name: 'GRP',
    children: [
      { id: 301, name: 'Trade', dbValue: 'GRP_TRADE' },
      { id: 302, name: 'Final', dbValue: 'GRP_FINAL' },
    ],
  },
  { id: 4, name: 'Other', dbValue: 'OTHER' },
];

function DFS(node, v, idMap) {
  // eslint-disable-next-line no-param-reassign
  idMap[node.id] = node;
  if (!node.children) {
    if (node.dbValue) {
      v.push(node.dbValue);
    }
  } else {
    for (let i = 0; i < node.children.length; i += 1) {
      DFS(node.children[i], v, idMap);
    }
  }
}

const nodeById = {};
const treeValues = [];

for (let i = 0; i < treeItems.length; i += 1) {
  DFS(treeItems[i], treeValues, nodeById);
}

export default {
  name: 'ChannelPicker',
  options: {
    dbValues: null,
  },
  methods: {
    forwardSelection() {
      const value = [...new Set(this.selection.flatMap((item) => item.dbValue))];
      this.$emit('change', value);
    },
  },
  computed: {
    displayValue() {
      if (this.selection.length === 0) return '';
      return this.selection[0].name;
    },
  },
  watch: {
    selectionOpen(val) {
      if (!val) {
        this.forwardSelection();
      }
    },
    dbValues: {
      handler(val) {
        for (let i = 0; i < val.length; i += 1) {
          if (this.treeValues.indexOf(val[i]) === -1) {
            this.nodeById[4].push(val[i]);
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      selectionOpen: false,
      items: treeItems,
      selection: [],
      nodeById,
      treeValues,
    };
  },
};
</script>

<style scoped>

</style>

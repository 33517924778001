<!-- Rates/Tariffe Menu displaying 3 Metric Cards -->
<template>
  <v-row
    no-gutters
    class="fill-height"
  >
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.compset"
        :value="metrics.competitorAvg"
        :currency="true"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).competitorAvg"
        :consolidated-value="(consolidMetrics|| {}).competitorAvg"
        :enable-consolidated="vp"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.suggestedVariation"
        :value="metrics.suggestedVariation"
        :consolidated-value="(consolidMetrics|| {}).suggestedVariation"
        :loading="loading"
        :enable-consolidated="vp"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.bar"
        :value="metrics.avgBar"
        :currency="true"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).avgBar"
        :consolidated-value="(consolidMetrics|| {}).avgBar"
        :enable-consolidated="vp"
      />
    </v-col>
  </v-row>
</template>
<script>

import MetricCard from '@/components/Dashboard/metrics/MetricCard.vue';

export default {
  name: 'Rates',
  components: { MetricCard },
  props: {
    metrics: {
      type: Object,
      default: () => ({}),
    },
    historicalMetrics: {
      type: Object,
      default: () => null,
    },
    budgetMetrics: {
      type: Object,
      default: () => null,
    },
    consolidMetrics: {
      type: Object,
      default: () => null,
    },
    vp: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.filter = state.filter;
      }
    }));
  },
  data() {
    return {
      filter: null,
    };
  },
};
</script>

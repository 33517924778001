<!-- Numeric values and comparisons display -->
<template>
  <div>
    <i class="mdi mdi-update" />
    <span v-if="!value">
      <v-icon class="justify-center">mdi-minus</v-icon>
    </span>
    <span v-else>
      {{
        fixedDigits(value)
          .toLocaleString($vuetify.lang.current)
      }}
      <i
        v-if="currency"
        class="mdi mdi-currency-eur mr-0"
        style="margin-left: -5px"
      />
    </span>
    <br>
    <i :class="`mdi ${comparisonIcon}`" />
    <span v-if="!comparisonValue">
      <v-icon class="justify-center">mdi-minus</v-icon>
    </span>
    <span v-else>
      {{
        fixedDigits(comparisonValue)
          .toLocaleString($vuetify.lang.current)
      }}
      <i
        v-if="currency"
        class="mdi mdi-currency-eur mr-0"
        style="margin-left: -5px"
      />
    </span>
    <br>
    <span
      v-if="comparisonValue"
      :class="gap < 0 ? `error--text`: `success--text`"
    >
      <span v-if="gap === 0">
        <i class="mdi mdi-minus" />
      </span>
      <span v-else-if="consolid">
        {{ fixedDigits(gap, 2).toLocaleString($vuetify.lang.current) }}%
      </span>
      <span v-else>
        {{ gap > 0 ? '+' : '' }}
        {{ fixedDigits(gap).toLocaleString($vuetify.lang.current) }}%
        <i
          :class="`mdi ${trend}`"
        />
      </span>
    </span>
  </div>
</template>
<script>
import chartUtil from '@/components/lib/chartUtil';

export default {
  name: 'ComparisonValue',
  props: {
    consolid: {
      type: Boolean,
      default: false,
    },
    currency: {},
    roundDigits: {},
    value: {},
    comparisonValue: {},
    comparisonIcon: {},
    trendSign: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    gap() {
      if (!this.comparisonValue) return 0;
      if (this.consolid) {
        return 100 * (this.comparisonValue / this.value);
      }
      if (this.trendSign < 0) {
        return -100.0 * ((this.comparisonValue - this.value) / this.value);
      }
      return 100.0 * this.trendSign * ((this.value - this.comparisonValue) / this.comparisonValue);
    },
    trend() {
      if (this.gap === 0) return 'mdi-equal';
      if (this.gap > 0) return 'mdi-arrow-up';
      return 'mdi-arrow-down';
    },
  },
  methods: {
    fixedDigits: chartUtil.approximateNumber,
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.filter)?_c('v-container',{staticClass:"ma-3 pa-3",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"pb-4"},[_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"primary align-self-center",attrs:{"rounded":""},on:{"click":_vm.exportCsv}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.export'))+" ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.itemsCount,"items-per-page":_vm.options.itemsPerPage,"footer-props":{
          itemsPerPageOptions: [10, 20, 30]
        }},on:{"update:items":function($event){_vm.items=$event},"update:loading":function($event){_vm.loading=$event},"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.priceVsHurdleRate",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"justify-center",staticStyle:{"width":"100%"},attrs:{"color":_vm.getColor(item.priceVsHurdleRate),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.priceVsHurdleRate)+" "),_c('i',{staticClass:"mdi mdi-currency-eur ml-1"})])]}},{key:"item.key.idHotel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.hotelName)+" ")]}},{key:"item.occupancyDemand",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedDigits(item.occupancyDemand, 2).toLocaleString(_vm.$vuetify.lang.current))+"% ")]}},{key:"item.occupancyConsolidated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fixedDigits(item.occupancyConsolidated, 2).toLocaleString(_vm.$vuetify.lang.current))+"% ")]}},{key:"item.key.roomReferenceType",fn:function(ref){
        var item = ref.item;
return [(item.roomType)?_c('span',[_vm._v(" "+_vm._s(item.roomType.roomId)+" ")]):_c('span',[_vm._v(_vm._s(item.key.roomReferenceType))])]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [(item.price)?_c('span',[_vm._v(" "+_vm._s(item.price)),_c('i',{staticClass:"mdi mdi-currency-eur"})]):_c('span',[_c('i',{staticClass:"mdi mdi-minus"})])]}},{key:"item.closed",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{staticClass:" ma-0 pa-0 justify-center"},[_c('v-switch',{staticClass:"ma-0 red-off-state",attrs:{"inset":"","dense":"","ripple":false,"color":false ? 'red' : 'primary'},on:{"change":function($event){return _vm.updateClosed(item)}},model:{value:(item.closed),callback:function ($$v) {_vm.$set(item, "closed", $$v)},expression:"item.closed"}})],1)]}}],null,false,3453462249)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-large
        icon
        color="white"
        v-bind="attrs"
        v-on="on"
        class="mx-2"
      >
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-title>
            {{ $vuetify.lang.t('$vuetify.notificationMenu.none') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// TODO: notifications
export default {
  name: 'NotificationMenu',
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
  },
};
</script>

<style scoped>

</style>

<template>
  <v-chip
    v-bind="attrs||{}"
    v-on="on||{}"
    :color="color"
    small
    :dark="isDemo"
    :disabled="disabled && !!attrs"
    dense
    class="font-weight-bold text-caption pl-1 pr-2"
  >
    <i :class="`mdi ${icon} mr-1`" />
    <span v-if="value || value===0">
      {{ displayValue }}
    </span>
    <i
      v-else
      class="mdi mdi-minus "
    />
  </v-chip>
</template>

<script>
import chartUtil from '@/components/lib/chartUtil';

export default {
  name: 'HurdleRateSkittle',
  props: {
    value: null,
    disabled: null,
    on: null,
    attrs: null,
  },
  computed: {
    isDemo() {
      return process.env.NODE_ENV === 'demo';
    },
    displayValue() {
      if (!this.value) return '0';
      return chartUtil.approximateNumber(this.value, 0);
    },
    getColor() {
      return process.env.NODE_ENV === 'demo' ? 'teal' : '#f0efea';
    },
    color() {
      // if (this.value === undefined) return 'gray';
      // if (this.value < 10) return 'teal';
      // if (this.value < 15) return 'yellow darken-1';
      // if (this.value < 20) return 'orange';
      // return 'deep-orange';
      return process.env.NODE_ENV === 'demo' ? 'teal' : '#f0efea';
    },
    icon() {
      // if (this.value === undefined || this.value < 10) return 'mdi-gauge-empty';
      // if (this.value < 15) return 'mdi-gauge-low';
      // if (this.value < 20) return 'mdi-gauge';
      // return 'mdi-gauge-full';
      return 'mdi-gauge';
    },
  },
};
</script>

<style scoped>

</style>

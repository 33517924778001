<template>
  <v-dialog
    v-model="isOpen"
    width="950px"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-list-item
        v-bind="attrs"
        v-on="on"
        class="text-right"
      >
        <v-list-item-title>
          <span>
            {{ $vuetify.lang.t('$vuetify.pricing.globalBarChange') }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card
      color="grey lighten-4"
      min-width="350px"
      flat
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="row no-gutters ml-0 font-weight-bold">
          {{ $vuetify.lang.t('$vuetify.pricing.updateBar') }}
        </v-toolbar-title>
        <v-btn icon>
          <v-icon @click="isOpen = false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="!loading">
        <v-form
          ref="form"
          class="row my-4"
          v-model="valid"
        >
          <v-col md="6">
            <v-menu
              v-model="startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.pricing.from')"
                  :value="formattedStart"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v]"
                />
              </template>
              <v-date-picker
                v-model="start"
                :max="end"
                @input="startDatePicker = false"
              />
            </v-menu>
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEnd"
                  :label="$vuetify.lang.t('$vuetify.pricing.to')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v]"
                />
              </template>
              <v-date-picker
                v-model="end"
                :min="start"
                @input="endDatePicker = false"
              />
            </v-menu>
          </v-col>
          <v-col
            md="12"
            class="py-0"
          >
            <v-checkbox
              v-model="updateBar"
              :label="$vuetify.lang.t('$vuetify.pricing.updateBar')"
            />
          </v-col>
          <v-col
            md="6"
            class="py-0"
          >
            <v-select
              :items="bars"
              label="Bar"
              v-model="chosenBar"
              v-if="updateBar"
            />
          </v-col>
          <v-col
            md="6"
            v-if="!updatePrepaid && updateBar"
            class="py-0"
          >
            <v-text-field
              type="number"
              min="0"
              max="100"
              suffix="%"
              v-model="defaultPrepaid"
              label="Default Prepaid"
              :rules="[v => !updatePrepaid && updateBar && !!v || '']"
              required
              :hint="$vuetify.lang.t('$vuetify.pricing.defaultPrepaidHint')"
              persistent-hint
            />
          </v-col>
          <v-col
            md="6"
            v-if="!updatePrepaid && updateBar"
          />
          <v-col
            md="12"
            class="py-0"
          >
            <v-checkbox
              v-model="updatePrepaid"
              :label="$vuetify.lang.t('$vuetify.pricing.updatePrepaid')"
            />
          </v-col>
          <v-col
            md="6"
            class="py-0"
          >
            <v-text-field
              v-if="updatePrepaid"
              type="number"
              min="0"
              max="100"
              suffix="%"
              v-model="prepaid"
              label="Prepaid"
              :rules="[v => !!v && v>= 0 && v <= 100
                || $vuetify.lang.t('$vuetify.pricing.prepaidValidation') ]"
              required
            />
          </v-col>
          <v-col
            md="6"
            v-if="updatePrepaid && !updateBar"
            class="py-0"
          >
            <v-select
              :items="bars"
              label="Default Bar"
              v-model="defaultBar"
              :hint="$vuetify.lang.t('$vuetify.pricing.defaultBarHint')"
              :rules="[v =>
                ((updatePrepaid && !updateBar) && v !== null) ||
                !updatePrepaid || updateBar ||
                $vuetify.lang.t('$vuetify.pricing.prepaidValidation')]"
              persistent-hint
            />
          </v-col>
          <v-col
            md="12"
            class="text-center mt-4"
          >
            <ConfirmBarChange
              :disabled="!valid"
              :icon="false"
              @confirm="save"
            />
          </v-col>
        </v-form>
      </v-card-text>
      <v-progress-linear
        v-else
        indeterminate
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmBarChange from '@/components/Pricing/ConfirmBarChange.vue';
import moment from 'moment';
import api from '@/api';

const bars = [
  { value: 0, text: 'RACK' },
  { value: 1, text: 'BAR1' },
  { value: 2, text: 'BAR2' },
  { value: 3, text: 'BAR3' },
  { value: 4, text: 'BAR4' },
  { value: 5, text: 'BAR5' },
  { value: 6, text: 'BAR6' },
  { value: 7, text: 'BAR7' },
  { value: 8, text: 'BAR8' },
  { value: 9, text: 'BAR9' },
  { value: 10, text: 'BAR10' },
  { value: 20, text: 'RACK - 2023' },
  { value: 21, text: 'BAR1 - 2023' },
  { value: 22, text: 'BAR2 - 2023' },
  { value: 23, text: 'BAR3 - 2023' },
  { value: 24, text: 'BAR4 - 2023' },
  { value: 25, text: 'BAR5 - 2023' },
  { value: 26, text: 'BAR6 - 2023' },
  { value: 27, text: 'BAR7 - 2023' },
  { value: 28, text: 'BAR8 - 2023' },
  { value: 29, text: 'BAR9 - 2023' },
  { value: 30, text: 'BAR10 - 2023' },
  { value: 31, text: 'BAR11 - 2023' },
];
export default {
  name: 'GlobalBarChangeDialog',
  components: { ConfirmBarChange },
  props: {
    idHotel: null,
  },
  computed: {
    formattedStart() {
      if (!this.start) return null;
      return this.formatDate(this.start);
    },
    formattedEnd() {
      if (!this.end) return null;
      return this.formatDate(this.end);
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.fetchAvailableBars();
      }
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async fetchAvailableBars() {
      this.loading = true;
      // FIXME bars listing missing
      // const availableBars = await api.bars.availableBars(this.idHotel);
      const availableBars = await api.bars.availableBars(this.idHotel);
      const cloned = availableBars.map((e) => {
        if (e.idBar >= 20) {
          return {
            value: e.idBar,
            text: (e.idBar === 20 ? 'RACK - 2023' : `BAR${e.idBar - 20} - 2023`),
          };
        }
        return {
          value: e.idBar,
          text: (e.idBar === 0 ? 'RACK' : `BAR${e.idBar}`),
        };
      });

      this.bars = [];
      const values = [];
      cloned.forEach((x) => {
        if (!values.includes(x.value)) {
          values.push(x.value);
          this.bars.push(x);
        }
      });
      // this.bars = availableBars.map((e) => ({
      //   value: e.idBar,
      //   text: (e.idBar === 0 ? 'RACK' : `BAR${e.idBar}`),
      // }));
      this.loading = false;
    },
    async save() {
      if (!this.updateBar && !this.updatePrepaid) return;
      const data = {
        start: this.start,
        end: this.end,
        idHotel: this.idHotel,
      };
      if (this.updateBar) {
        data.barId = this.chosenBar;
        if (!this.updatePrepaid) {
          data.defaultPrepaid = this.defaultPrepaid;
        }
      }
      if (this.updatePrepaid) {
        data.prepaid = this.prepaid;
        if (!this.updateBar) {
          data.defaultBarId = this.defaultBar;
        }
      }
      await api.bars.updateGlobalBars(data);
      this.isOpen = false;
      this.$refs.form.reset();
      this.start = null;
      this.end = null;
      this.defaultBar = 0;
      this.defaultPrepaid = 0;
      this.prepaid = 0;
      this.chosenBar = 0;
      this.updateBar = false;
      this.updatePrepaid = false;
      this.$emit('change');
    },
  },
  data() {
    return {
      isOpen: false,
      valid: false,
      start: null,
      end: null,
      prepaid: 0,
      chosenBar: 0,
      updateBar: false,
      updatePrepaid: false,
      defaultBar: 0,
      defaultPrepaid: 0,
      bars: [],
      loading: false,
    };
  },
};
</script>

<style scoped>

</style>

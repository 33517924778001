<template>
  <v-select
    v-model="selection"
    :items="choices"
    hide-details
    class="filter-multi-select"
    multiple
    item-text="displayName"
    item-color="secondary"
    color="white"
    dense
    solo
    small-chips
    light
    @blur="forwardSelection()"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index < 1"
        color="secondary"
        label
        small
      >
        <span class="text-capitalize">{{ item.displayName }}</span>
      </v-chip>
      <span
        v-if="index === 1"
        class="grey--text caption"
      >
        (+{{ selection.length - 1 }})
      </span>
    </template>
    <template
      v-slot:label
      class="filter-text"
    >
      <span class="pl-1 filter-multi-select-label black--text">
        {{ $vuetify.lang.t('$vuetify.filterBar.rooms.types') }}
      </span>
    </template>
  </v-select>
</template>

<script>
import chartUtil from '@/components/lib/chartUtil';

export default {
  name: 'RoomTypePicker',
  props: {
    choices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    forwardSelection() {
      this.$emit('change', this.value);
    },
    toLocaleId: chartUtil.toLocaleId,
  },
  created() {
    this.$root.$on('asyncRoomChange', (event) => {
      this.selection = this.choices
        .filter((item) => item.value
          .filter((room) => room.roomReferenceType === event.roomReferenceType
            && room.idHotel === event.idHotel).length > 0)
        .map((item) => item.value);
      this.forwardSelection();
    });
  },
  mounted() {
  },
  computed: {
    displayValue() {
      if (this.selection.length === 0) return '';
      return this.selection[0].displayName;
    },
    value() {
      return this.selection.flatMap((item) => item);
    },
  },
  watch: {
    selectionOpen(val) {
      if (!val) {
        this.forwardSelection(this.value);
      }
    },
  },
  data() {
    return {
      selectionOpen: false,
      selection: [],
    };
  },
};
</script>

<style lang="scss">
.selection-label {
  text-transform: capitalize!important;
}
</style>

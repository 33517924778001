<template>
  <v-select
    v-model="value"
    :items="items"
    hide-details
    item-text="displayName"
    item-value="ratesList"
    class="filter-multi-select"
    multiple
    item-color="secondary"
    color="white"
    dense
    solo
    small-chips
    light
    @blur="forwardChange"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index < 1"
        color="secondary"
        label
        small
      >
        <span>{{ item.displayName }}</span>
      </v-chip>
      <span
        v-if="index === 1"
        class="grey--text caption"
      >
        (+{{ value.length - 1 }})
      </span>
    </template>
    <template
      v-slot:label
      class="filter-text"
    >
      <span class="pl-1 filter-multi-select-label black--text">
        {{ $vuetify.lang.t('$vuetify.filterBar.rate') }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'RatePicker',
  props: {
    items: {
      type: Array,
      required: true,
    },
    translate: {
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        if (state.filter.rates.length === 0) this.value = [];
      }
    }));
  },
  methods: {
    displayName(item) {
      if (this.translate) {
        return this.$vuetify.lang.t(item.text);
      }
      return item;
    },
    forwardChange() {
      this.$emit('change', this.value.flat().sort());
    },
  },
  data() {
    return {
      value: [],
    };
  },
};
</script>

<style lang="scss">
.v-list-item__action{
  margin-right: 16px !important;
}
.v-list-item__content {
  text-transform: capitalize!important;
}
.filter-multi-select {
  .v-input__control {
    min-height: 32px!important;
    .v-input__slot {
      padding-left: 7px!important;
    }
  }
  .v-label {
    line-height: 16px;
  }
  .filter-multi-select-label {
   font-size: 14px;
   line-height: 16px;
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    width="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="icon"
        icon
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        <v-icon>
          {{ displayedIcon }}
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="mr-2"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        {{ $vuetify.lang.t('$vuetify.pricing.saveGlobalChanges') }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="secondary white--text">
        {{ $vuetify.lang.t('$vuetify.pricing.confirmChanges') }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="confirmChanges"
        >
          {{ $vuetify.lang.t('$vuetify.pricing.confirm') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="dialog = false"
        >
          {{ $vuetify.lang.t('$vuetify.pricing.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmBarChange',
  emits: ['confirm'],
  props: {
    disabled: null,
    icon: {
      type: Boolean,
      default: () => true,
    },
    displayedIcon: {
      type: String,
      default: () => 'mdi-content-save',
    },
  },
  methods: {
    confirmChanges() {
      this.dialog = false;
      this.$emit('confirm');
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>

</style>

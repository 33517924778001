import moment from 'moment';
import Chart from 'vue-chartjs';
import colors from '@/plugins/colors';

const defaultTimeChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 80,
  legend: {
    position: 'bottom',
    display: true,
    labels: {
      usePointStyle: true,
      fontColor: '#3f4a5b',
    },
  },
  animation: {
    easing: 'easeOutExpo',
  },
  scales: {
    xAxes: [{
      offset: true,
      ticks: {
        display: true,
      },
      gridLines: {
        display: false,
        drawBorder: false,
      },
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          week: 'll',
          month: 'MMM YY',
          day: 'DD MMM \'YY',
        },
      },
    }],
    yAxes: [{
      offset: true,
      ticks: {
        display: true,
        beginAtZero: true,
      },
      gridLines: {
        display: false,
      },
    }],
  },
};

const buildTimeChartOptions = (filter, min, max) => {
  const chartOptions = JSON.parse(JSON.stringify(defaultTimeChartOptions));
  if (!filter) {
    return chartOptions;
  }
  chartOptions.scales.xAxes[0].time.unit = 'day';
  let minE;
  let maxE;
  if (filter.periodStart && min) {
    minE = moment.min(min, moment(filter.periodStart));
  } else if (filter.periodStart) {
    minE = moment(filter.periodStart);
  } else if (min) {
    minE = min;
  } else {
    minE = moment().startOf('month');
  }
  if (filter.periodEnd && max) {
    maxE = moment.max(max, moment(filter.periodEnd));
  } else if (filter.periodEnd) {
    maxE = moment(filter.periodEnd);
  } else if (max) {
    maxE = max;
  } else {
    maxE = moment().endOf('month');
  }

  chartOptions.scales.xAxes[0].ticks = {
    ...chartOptions.scales.xAxes[0].ticks,
    min: minE.format('YYYY-MM-DD'),
    max: maxE.format('YYYY-MM-DD'),
  };
  return chartOptions;
};

const toLocaleId = (s) => s.replaceAll(/[^a-z]/gi, '_').toLowerCase();

const approximateNumber = (number, digits) => (
  number && number.toFixed
    ? Number(number.toFixed(digits))
    : 0
);

const computeChartBoundaries = (stats) => stats.datasets
  .flatMap((ds) => ds.data.map((e) => e.x))
  .reduce((acc, item) => {
    const m = moment(item);
    if (!acc.min) {
      acc.min = m;
      acc.max = m;
      return acc;
    }
    if (m.isBefore(acc.min)) acc.min = m;
    if (m.isAfter(acc.max)) acc.max = m;
    return acc;
  }, {});

export default {
  buildTimeChartOptions,
  approximateNumber,
  toLocaleId,
  computeChartBoundaries,
};

<template>
  <v-container fluid>
    <PeriodDetails
      @selectedStructure="handleStructureSelection"
    />
    <!-- <PeriodDetails
      v-if=" !$store.state.structureSet"
      @selectedStructure="handleStructureSelection"
    />
    <StructureDetails
      v-else
      @selectedRoom="handleRoomSelection"
    /> -->
  </v-container>
</template>

<script>
import PeriodDetails from '@/components/Dashboard/PeriodDetails.vue';
import StructureDetails from '@/components/Dashboard/StructureDetails.vue';

export default {
  name: 'DataAnalysis',
  // eslint-disable-next-line vue/no-unused-components
  components: { PeriodDetails, StructureDetails },
  methods: {
    handleRoomSelection(roomType) {
      this.$root.$emit('asyncRoomChange', {
        idHotel: roomType.idHotel,
        roomReferenceType: roomType.roomReferenceType,
      });
      this.$router.push({
        name: 'Pricing',
        params: {
          idHotel: roomType.idHotel,
          roomReferenceType: roomType.roomReferenceType,
        },
      });
    },
    handleStructureSelection(structure) {
      this.$store.commit('filterChange', {
        ...this.$store.state.filter,
        structures: [structure.idHotel],
      });
      this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-menu
    v-model="active"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-x
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        :active="true"
        :color="selected? 'secondary lighten-1': 'white'"
        text-color="grey darken-4"
        class="period-selector px-4"
        light
        v-bind="attrs"
        v-on="on"
      >
        <span>
          {{ $vuetify.lang.t(mode.displayName) }}
        </span>
      </v-chip>
    </template>
    <v-date-picker
      v-if="mode.type === 'month'"
      v-model="date"
      type="month"
      no-title
      first-day-of-week="1"
      locale-first-day-of-week="1"
      @change="handleChange"
    />
    <v-date-picker
      v-else-if="mode.type === 'week'"
      :value="date"
      ref="vPicker"
      range
      no-title
      :show-current="false"
      first-day-of-week="1"
      locale-first-day-of-week="1"
      show-week
      show-adjacent-months
      @mouseenter:date="highlightWeek"
      @mouseleave:date="deHighlightWeek"
      @input="handleWeekSelection"
    />
    <v-date-picker
      v-else
      v-model="date"
      ref="vPicker"
      range
      no-title
      show-current
      first-day-of-week="1"
      locale-first-day-of-week="1"
      @change="handleCustomRangeSelection"
    />
  </v-menu>
</template>
<script>
import moment from 'moment';

export default {
  name: 'PeriodDatePicker',
  props: {
    mode: {
      type: Object,
      required: true,
    },
    globalIntervals: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleChange(value) {
      this.active = false;
      if (this.mode.type === 'month') {
        this.$emit('selection', {
          mode: this.mode.type,
          value: this.getRangeFromMonth(value),
        });
      } else {
        this.$emit('selection', {
          mode: this.mode.type,
          value,
        });
      }
    },
    getWeekRangeFromDay(date) {
      const start = moment(date)
        .startOf('isoWeek')
        .format('YYYY-MM-DD');
      const end = moment(date)
        .endOf('isoWeek')
        .format('YYYY-MM-DD');
      return [start, end];
    },
    getRangeFromMonth(month) {
      const start = moment(month)
        .startOf('month')
        .format('YYYY-MM-DD');
      const end = moment(month)
        .endOf('month')
        .format('YYYY-MM-DD');
      return [start, end];
    },
    handleWeekSelection(value, event) {
      const range = this.getWeekRangeFromDay(new Date(value));
      this.date = range;
      this.handleChange(range);
    },
    handleCustomRangeSelection(value) {
      const first = moment(value[0]);
      const last = moment(value[1]);
      const range = [
        moment.min([first, last]),
        moment.max([first, last]),
      ];
      this.handleChange(range);
    },
    deHighlightWeek(event, s) {
      s.target.parentElement.parentElement.classList.remove('highlighted');
    },
    highlightWeek(event, s) {
      s.target.parentElement.parentElement.classList.add('highlighted');
    },
  },
  watch: {
    selected(val) {
      if (!val) {
        this.date = null;
      }
    },
  },
  data() {
    return {
      active: false,
      date: null,
    };
  },
};
</script>

<style  lang="scss">

@import "src/scss/variables";
.v-date-picker-table--date.theme--light {
  tr.highlighted {
    button {
      background-color: $midnightblue-light;
    }
    button.v-btn--disabled {
      background-color: $midnightblue-light;
    }
  }
  .v-btn--disabled.accent{
    background-color: $midnightblue-light!important;
    color: white!important;
  }
}
</style>

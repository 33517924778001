<template>
  <v-chip
    v-bind="attrs||{}"
    v-on="on||{}"
    :color="getColor()"
    :dark="promo == 3"
    small
    dense
    :disabled="value===null || value===''"
    class="font-weight-bold text-caption px-2"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          v-if="value!==null && value!== ''"
        >
          {{ value[getPromoValue()] ? value[getPromoValue()] : '-' }}
        </span>
        <span
          v-bind="attrs"
          v-on="on"
          v-else
        >
          <i class="mdi mdi-minus" />
        </span>
      </template>
      <span v-if="room">{{ getPromoValue() + ' Net' }}</span>
      <span v-else>{{ 'Media di ' + getPromoValue() + ' Net' }}</span>
    </v-tooltip>
  </v-chip>
</template>

<script>

export default {
  name: 'PromoSkittle',
  props: {
    on: null,
    attrs: null,
    promo: {
      type: Number,
      default: () => 0,
    },
    value: {
      type: null,
      default: () => null,
    },
    room: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    getColor() {
      if (this.promo === 1) return '#ddd8d9';
      if (this.promo === 2) return '#e0e9f7';
      return '#3f4a5b';
    },
    getPromoValue() {
      if (this.promo === 1) return 'FIT 20';
      if (this.promo === 2) return 'FIT 23';
      return 'FIT 25';
    },
  },
};
</script>

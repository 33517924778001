<template>
  <v-card
    v-if="filter && filter.structures.length > 0"
    flat
    class="horizontal-box"
  >
    <v-card-title class="justify-center secondary--text pt-5">
      <v-row no-gutters>
        <v-col
          md="4"
          offset-md="4"
        >
          <v-menu
            origin="center center"
            auto
            :value="chartSelectionOpen"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                color="white"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                class="mt-2"
              >
                <span class="font-weight-bold title secondary--text">
                  {{ $vuetify.lang.t(currentChart.name) }}
                  <v-icon>mdi-menu-down</v-icon>
                </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="chart in charts"
                :key="chart.type"
                dense
                link
                @click="changeChart(chart)"
              >
                <v-list-item-title
                  class="text-center"
                >
                  <span class="justify-center">{{ $vuetify.lang.t(chart.name) }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-spacer />
        <v-progress-circular
          color="primary"
          size="24"
          width="3"
          indeterminate
          v-if="loading"
        />
      </v-row>
    </v-card-title>
    <v-card-text v-if="stats ">
      <RankChart
        class="chart-container "
        :chart-data="stats"
        :options="chartOptions"
      />
    </v-card-text>
  </v-card>
  <v-row v-else>
    <v-col
      md="12"
      class="text-center text-h5"
      style="height: 500px; padding-top: 100px"
    >
      {{ $vuetify.lang.t("$vuetify.competitors.noData") }}
    </v-col>
  </v-row>
</template>

<script>
import api from '@/api';
import util from '@/components/lib/chartUtil';
import colors from '@/plugins/colors';
import RankChart from '@/components/Competition/charts/RankChart.vue';

const colorsLength = colors.colorList.length;

const charts = [
  {
    type: 'rank',
    name: '$vuetify.competitors.rank',
  },
  {
    type: 'price',
    name: '$vuetify.competitors.compset',
  },
];

export default {
  name: 'CompetitorCharts',
  components: { RankChart },
  mounted() {
    this.fetchData();
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange'
        && JSON.stringify(state.filter) !== JSON.stringify(this.filter)) {
        this.filter = state.filter;
        this.fetchData();
      }
    }));
  },
  methods: {
    changeChart(chart) {
      this.currentChart = chart;
      this.fetchData();
    },
    async fetchData() {
      if (!this.filter || this.filter.structures.length === 0) return;
      this.loading = true;
      let stats;
      if (this.currentChart.type === 'rank') {
        stats = await api.competitors.ranks(this.filter);
      } else if (this.currentChart.type === 'price') {
        stats = await api.competitors.prices(this.filter);
      }
      stats.datasets.sort((a, b) => (a.highlighted ? -1 : 1));
      stats.datasets = stats.datasets.map((d, i) => ({
        ...d,
        label: `${d.label}${d.highlighted ? '(*)' : ''}`,
        fill: false,
        tension: 0,
        borderColor: colors.colorList[i % colorsLength],
        backgroundColor: colors.colorList[i % colorsLength],
        spanGaps: false,
        borderWidth: d.highlighted ? 4 : 2,
        order: i * (d.highlighted ? -1 : 1),
      }));
      const boundary = util.computeChartBoundaries(stats);

      this.chartOptions = {
        ...util.buildTimeChartOptions(this.filter, boundary.min, boundary.max),
      };
      this.chartOptions.scales.yAxes[0].gridLines.display = true;
      this.chartOptions.scales.yAxes[0].gridLines.drawBorder = false;
      if (this.currentChart.type === 'rank') {
        this.chartOptions.scales.yAxes[0].ticks.beginAtZero = true;
        this.chartOptions.scales.yAxes[0].ticks.callback = (v) => (v % 1 === 0 ? v : null);
      }
      this.stats = stats;
      this.loading = false;
    },
  },
  data() {
    return {
      loading: true,
      filter: this.$store.state.filter,
      stats: null,
      chartOptions: {},
      chartSelectionOpen: false,
      charts,
      currentChart: charts[0],
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <v-app>
    <TopAppBar />
    <LeftNavigationBar />
    <v-main>
      <v-container fluid>
        <v-row>
          <FilterBar />
        </v-row>
        <v-row>
          <router-view />
        </v-row>
        <AppFooter />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopAppBar from '@/components/main/TopAppBar.vue';
import LeftNavigationBar from '@/components/main/LeftNavigationBar.vue';
import FilterBar from '@/components/main/FilterBar.vue';
import AppFooter from '@/components/main/AppFooter.vue';

const DEFAULT_TITLE = process.env.VUE_APP_APP_NAME;

export default {
  name: 'App',
  components: {
    AppFooter, FilterBar, LeftNavigationBar, TopAppBar,
  },
  props: {
    keycloak: {
      type: Object,
      default: null,
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (!to.meta.displayName) {
          document.title = DEFAULT_TITLE;
        } else {
          const name = this.$vuetify.lang.t(to.meta.displayName);
          document.title = `${DEFAULT_TITLE} | ${name}`;
        }
      },
    },
  },
  data: () => ({

  }),
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "scss/mixins";
@import "scss/variables";
body, .v-main, .view-container {
  background-color: var(--v-bgColor-base);
}
</style>

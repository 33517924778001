<template>
  <v-dialog
    v-model="dialog"
    width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        class="text-right"
        :disabled="forwarding"
        v-bind="attrs"
        v-on="on"
      >
        <v-progress-circular
          v-if="forwarding"
          indeterminate
          color="primary"
          size="20"
          width="2"
          class="mr-3"
        />
        <v-spacer />
        <v-list-item-title>
          <span>
            {{ $vuetify.lang.t('$vuetify.pricing.sendBars') }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="secondary white--text">
        {{ $vuetify.lang.t('$vuetify.pricing.confirmPriceExport') }}
      </v-card-title>
      <v-card-actions v-if="!forwarding">
        <v-btn
          color="primary"
          text
          @click="confirmChanges"
        >
          {{ $vuetify.lang.t('$vuetify.pricing.confirm') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="dialog = false"
        >
          {{ $vuetify.lang.t('$vuetify.pricing.cancel') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions
        v-else
        class="justify-center"
      >
        <v-progress-circular
          indeterminate
          class="my-9"
          color="primary"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from '@/api';

export default {
  name: 'SendBARSConfirm',
  props: {
  },
  methods: {
    async confirmChanges() {
      this.forwarding = true;
      await api.bars.importPrices();
      this.forwarding = false;
      this.dialog = false;
    },
  },
  data() {
    return {
      dialog: false,
      forwarding: false,
    };
  },
};
</script>

<template>
  <v-container
    fluid
    class="pa-5"
  >
    <v-card
      elevation="0"
      class="px-4"
    >
      <v-row>
        <v-col
          md="2"
          xl="2"
          sm="2"
          class="text-center py-3 px-0"
        >
          <v-container fill-height>
            <v-row
              justify="center"
              align="center"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-0"
              >
                <v-btn-toggle
                  align="center"
                  justify="center"
                  class="justify-center align-self-center"
                  v-model="metricCard"
                  mandatory
                  dense
                  group
                  background-color="secondary darken-1"
                  color="white"
                  borderless
                  style="flex-direction: column; flex-grow: initial;"
                >
                  <v-btn
                    value="rates"
                    class="font-weight-bold kpi kpitext--text"
                    dark
                    outlined
                  >
                    {{ $vuetify.lang.t('$vuetify.dashboard.rates') }}
                  </v-btn>

                  <v-btn
                    value="kpi"
                    class="font-weight-bold kpi kpitext--text"
                    dark
                    outlined
                  >
                    Revenue
                  </v-btn>

                  <v-btn
                    value="occupancy"
                    class="font-weight-bold kpi kpitext--text"
                    dark
                    outlined
                  >
                    Occupancy
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col
          md="6"
          xl="6"
          sm="6"
          class="pa-0"
        >
          <RevenueKPI
            v-if="metricCard === 'kpi'"
            :metrics="metrics"
            :consolid-metrics="consolidatedMetrics"
            :historical-metrics="historicalMetrics"
            :budget-metrics="budgetMetrics"
            :vp="false"
            :loading="loading"
          />
          <Rates
            v-if="metricCard === 'rates'"
            :metrics="metrics"
            :consolid-metrics="consolidatedMetrics"
            :historical-metrics="historicalMetrics"
            :budget-metrics="budgetMetrics"
            :vp="filter ? filter.channels.includes('VUOTO PIENO') : false"
            :loading="loading"
          />
          <OccupancyKPI
            v-if="metricCard === 'occupancy'"
            :metrics="metrics"
            :historical-metrics="historicalMetrics"
            :consolid-metrics="consolidatedMetrics"
            :budget-metrics="budgetMetrics"
            :vp="filter ? filter.channels.includes('VUOTO PIENO') : false"
            :loading="loading"
          />
        </v-col>
        <v-col
          cols="4"
          class="pt-3 pb-1"
        >
          <DashboardPie />
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-5">
      <v-col
        md="12"
        lg="12"
        xl="12"
      >
        <v-card class="">
          <v-card-title class="pa-8 pr-7 pb-0 mb-1">
            <v-btn
              color="secondary"
              rounded
              @click="showDetails"
              class="mr-2"
            >
              {{ $vuetify.lang.t('$vuetify.expand') }}
            </v-btn>
          </v-card-title>
          <v-row>
            <ADRVsForecast />
          </v-row>
          <v-divider />
          <v-row>
            <RoomNight />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RevenueKPI from '@/components/Dashboard/metrics/RevenueKPI.vue';
import ADRVsForecast from '@/components/Dashboard/ADRVsForecast.vue';
import RoomNight from '@/components/Dashboard/RoomNight.vue';
import Rates from '@/components/Dashboard/metrics/Rates.vue';
import OccupancyKPI from '@/components/Dashboard/metrics/OccupancyKPI.vue';
import api from '@/api';
import moment from 'moment';
import DashboardPie from '@/components/Dashboard/DashboardPie.vue';

const historicalPeriod = 'year';

export default {
  name: 'Dashboard',
  components: {
    DashboardPie,
    Rates,
    RoomNight,
    ADRVsForecast,
    RevenueKPI,
    OccupancyKPI,
  },
  props: {},
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        if (state.filter && JSON.stringify(this.filter) !== JSON.stringify(state.filter)) {
          this.filter = state.filter;
          this.loadMetrics(state.filter);
        }
      }
    }));
  },
  mounted() {
    this.filter = this.$store.state.filter;
    this.loadMetrics(this.filter);
  },
  methods: {
    showDetails() {
      this.$router.push({ name: 'DataAnalysis' });
    },
    async loadMetrics(filter) {
      this.loading = true;
      const time = new Date().getTime();
      this.requestTime = time;
      if (!filter || Object.values(filter).filter((v) => !!v).length === 0) return;
      const metrics = await api.hotelHistory.fetchRates(filter);
      if (this.requestTime !== time) {
        return;
      }
      if (filter.periodStart && filter.periodEnd) {
        const historical = await api.hotelHistory.fetchRates({
          ...filter,
          periodStart: moment(filter.periodStart)
            .subtract(1, historicalPeriod)
            .format('YYYY-MM-DD'),
          periodEnd: moment(filter.periodEnd)
            .subtract(1, historicalPeriod)
            .format('YYYY-MM-DD'),
        });
        if (this.requestTime !== time) {
          return;
        }
        this.historicalMetrics = historical;
      } else {
        this.historicalMetrics = null;
      }
      if (this.requestTime !== time) {
        return;
      }
      // eslint-disable-next-line max-len
      if (filter.channels.includes('VUOTO PIENO') && filter.roomServices.includes('Rooms')) this.consolidatedMetrics = await api.hotelHistory.fetchConsolidRates(filter);
      this.metrics = metrics;
      this.loading = false;
      this.budgetMetrics = {
        roomNights:
          this.historicalMetrics.budgetRoomServiceNight,
        totalRevenue:
          this.historicalMetrics.budgetRoomRevenue,
        adrPlus:
          this.historicalMetrics.budgetRoomRevenue / this.historicalMetrics.budgetRoomNight,
        occupancy:
          (this.historicalMetrics.budgetRoomNight / this.historicalMetrics.budgetCapacity) * 100,
      };
    },
  },
  data() {
    return {
      details: false,
      metrics: {},
      consolidatedMetrics: {},
      filter: null,
      loading: false,
      historicalMetrics: null,
      budgetMetrics: {},
      metricCards: ['kpi', 'rates'],
      metricCard: 'kpi',
      requestTime: null,
    };
  },
};
</script>

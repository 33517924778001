<template>
  <v-select
    v-model="selection"
    :items="choices"
    hide-details
    class="filter-multi-select"
    :item-text="itemText"
    item-value="value"
    multiple
    item-color="secondary"
    color="white"
    dense
    solo
    small-chips
    light
    @blur="forwardSelection()"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        v-if="index < 1"
        color="secondary"
        label
        small
      >
        <span class="text-capitalize">
          {{ $vuetify.lang.t(item.displayName) }}
        </span>
      </v-chip>
      <span
        v-if="index === 1"
        class="grey--text caption"
      >
        (+{{ selection.length - 1 }})
      </span>
    </template>
    <template
      v-slot:label
      class="filter-text"
    >
      <span class="pl-1 filter-multi-select-label black--text">
        {{ $vuetify.lang.t('$vuetify.filterBar.status') }}
      </span>
    </template>
  </v-select>
</template>

<script>

const choices = [
  {
    value: 1,
    displayName: '$vuetify.filterBar.statuses.confirmed',
    statuses: ['O', 'M'],
  },
  {
    value: 2,
    displayName: '$vuetify.filterBar.statuses.demand',
    statuses: ['O', 'M', 'C', 'W', 'S'],
  },
  {
    value: 3,
    displayName: '$vuetify.filterBar.statuses.waiting',
    statuses: ['W'],
  },
  {
    value: 4,
    displayName: '$vuetify.filterBar.statuses.C',
    statuses: ['C'],
  },
  {
    value: 5,
    displayName: '$vuetify.filterBar.statuses.S',
    statuses: ['S'],
  },
];

export default {
  name: 'StatusPicker',
  props: {},
  methods: {
    forwardSelection() {
      this.$emit('change', [...new Set(this.value)]);
    },
    initSelection() {
      this.$emit('start', [...new Set(this.value)]);
    },
    itemText(item) {
      return this.$vuetify.lang.t(item.displayName);
    },
  },
  created() {
  },
  mounted() {
    this.initSelection();
  },
  computed: {
    value() {
      const res = this.choices.filter((s) => this.selection.indexOf(s.value) >= 0)
        .flatMap((item) => item.statuses);
      return [...res];
    },
  },
  watch: {
    selectionOpen(val) {
      if (!val) {
        this.forwardSelection(this.value);
      }
    },
  },
  data() {
    return {
      selectionOpen: false,
      selection: [1],
      choices,
    };
  },
};
</script>

<style lang="scss">
.selection-label {
  text-transform: capitalize!important;
}
</style>

import store from '@/store';
import Vue from 'vue';
import axios from 'axios';

const cancelToken = axios.CancelToken;

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

axios.defaults.headers.post['Content-Type'] = 'application/json';

const config = {
  timeout: 2 * 60 * 1000,
  baseURL: process.env.VUE_APP_AV_BACKEND_URL,
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  (requestConfig) => {
    const { session } = store.state;
    const updated = { ...requestConfig };
    updated.headers.Authorization = `Bearer ${session.accessToken}`;
    return updated;
  },
);

Plugin.install = (VueInstance, options) => {
  // eslint-disable-next-line no-param-reassign
  VueInstance.axios = axiosInstance;
  window.axios = axiosInstance;
  window.cancelToken = cancelToken;
  Object.defineProperties(VueInstance.prototype, {
    axios: {
      get() {
        return axiosInstance;
      },
    },
    $axios: {
      get() {
        return axiosInstance;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;

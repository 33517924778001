const barChartColor = process.env.NODE_ENV === 'demo' ? [
  '#35d1ad',
  '#062b5e',
] : [
  '#424f51',
  '#9EAEA4',
  '#566d82',
  '#96b2dd',
  '#CCC996',
  '#726161',
  '#c89f97',
  '#ddd9d3',
  '#0f85cb',
];

const colorList = process.env.NODE_ENV === 'demo' ? [
  '#35d1ad',
  '#062b5e',
  '#cd543c',
  '#ffc857',
  '#985f99',
  '#04724d',
  '#008BF8',
  '#540804',
  '#f49301',
  '#caa8f5',
  '#107793',
  '#4bb3fd',
  '#AE0A5F',
  '#FFDB70',
  '#516C7B',
  '#7CA6CB',
  '#C06E52',
  '#C5CC00',
] : [
  '#c89f97',
  '#cb6868',
  '#726161',
  '#96b2dd',
  '#566d82',
  '#9EAEA4',
  '#CCC996',
  '#3f4a5b',
  '#514f4f',
];

const eventsColor = process.env.NODE_ENV === 'demo' ? [
  '#042960', // 0
  '#9AA9BE', // 2
  '#23A199', // 1
  '#176B6F', // 3
  '#23A199', // 4
  '#687F9F', // 5
  '#32D9B3', // 6
  '#12607A', // 7
  '#0f85cb', // 8
  '#042960', // 9
  '#23A199', // 10
  '#9AA9BE', // 11
  '#176B6F', // 12
  '#23A199', // 13
  '#687F9F', // 14
  '#32D9B3', // 15
  '#12607A', // 16
  '#0f85cb', // 17
  '#042960', // 18
  '#23A199', // 19
  '#042960', // 20
  '#23A199', // 21
  '#9AA9BE', // 22
  '#176B6F', // 23
  '#23A199', // 24
  '#687F9F', // 25
  '#32D9B3', // 26
  '#12607A', // 27
  '#0f85cb', // 28
  '#042960', // 29
  '#23A199', // 30
  '#9AA9BE', // 31
] : [
  '#566d82', // 0
  '#96b2dd', // 1
  '#9EAEA4', // 2
  '#CCC996', // 3
  '#9EAEA4', // 4
  '#726161', // 5
  '#c89f97', // 6
  '#ddd9d3', // 7
  '#A89D4C', // 8
  '#566d82', // 9
  '#96b2dd', // 10
  '#9EAEA4', // 11
  '#CCC996', // 12
  '#9EAEA4', // 13
  '#726161', // 14
  '#c89f97', // 15
  '#ddd9d3', // 16
  '#A89D4C', // 17
  '#566d82', // 18
  '#96b2dd', // 19
  '#566d82', // 20
  '#96b2dd', // 21
  '#9EAEA4', // 22
  '#CCC996', // 23
  '#9EAEA4', // 24
  '#726161', // 25
  '#c89f97', // 26
  '#ddd9d3', // 27
  '#A89D4C', // 28
  '#566d82', // 29
  '#96b2dd', // 30
  '#9EAEA4', // 31
];

const colorNames = {
  'caribbean-green': '#35d1ad',
  'royal-blue-dark': '#062b5e',
  'cedar-chest': '#cd543c',
  'maximum-yellow-red': '#ffc857',
  'french-lilac': '#985f99',
  'blue-jeans': '#4bb3fd',
  'dark-spring-green': '#04724d',
  'dark-electric-blue': '#516C7B',
  'yellow-orange-color-wheel': '#f49301',
  'teal-blue': '#107793',
  'acid-green': '#C5CC00',
  rosewood: '#540804',
  mauve: '#caa8f5',
  'copper-red': '#C06E52',
  iceberg: '#7CA6CB',
  'jazzberry-jam': '#AE0A5F',
  'blue-de-france': '#008BF8',
  'yellow-red-crayola': '#FFDB70',
};

export default {
  barChartColor,
  colorNames,
  colorList,
  eventsColor,
};

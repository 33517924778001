<template>
  <td
    colspan="8"
    class="px-16"
  >
    <PriceTable
      v-if="!loading"
      :items="bars"
      :prepaid="item.currentBar.prepaid"
      :competitor-prices="competitorPrices"
      :show-competitors="true"
      :start-date="item.date"
      :end-date="item.date"
      :new-prepaid="item.currentBar.prepaid"
      :show-expand="false"
    />
    <v-progress-linear
      indeterminate
      v-else
    />
  </td>
</template>

<script>
import PriceTable from '@/components/Pricing/PriceTable.vue';
import api from '@/api';
import hrUtils from '@/components/lib/hurdleRateUtils';

export default {
  name: 'BarSuggestionExpansion',
  components: { PriceTable },
  props: {
    item: null,
    competitorPrices: null,
    headersLength: null,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    sameRow(a, b) {
      return a.roomReferenceType === b.roomReferenceType;
    },
    async loadData() {
      if (this.bars) return;
      this.loading = true;
      const preview = await api.bars.fetchBarDetails({
        structures: [this.item.hotelId],
        barId: this.item.suggestedBar.idBar,
      });
      const bars = await api.bars.fetchBarDetails({
        structures: [this.item.hotelId],
        barId: this.item.currentBar.idBar,
      });
      const apiFilter = {
        structures: [this.item.hotelId],
        periodStart: this.item.date,
        periodEnd: this.item.date,
      };
      this.hurdleRates = await api.hurdleRates.raws(apiFilter);
      const mergedPrices = hrUtils.mergeHurdleRates(bars, this.hurdleRates);
      for (let i = 0; i < mergedPrices.length; i += 1) {
        for (let j = 0; j < preview.length; j += 1) {
          const current = mergedPrices[i];
          const updated = preview[j];
          if (this.sameRow(current, updated)) {
            mergedPrices[i] = {
              ...current,
              newPrice: updated.barPrice,
            };
          }
        }
      }
      this.bars = mergedPrices;
      this.loading = false;
    },
  },
  data() {
    return {
      loading: false,
      bars: null,
    };
  },
};
</script>

<style scoped>

</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import DataAnalysis from '@/views/DataAnalysis.vue';
import PickUp from '@/views/PickUp.vue';
import Pricing from '@/views/Pricing.vue';
import FitRates from '@/views/FitRates.vue';
import Competition from '@/views/Competition.vue';
import HurdleRate from '@/views/HurdleRate.vue';
import PriceSuggestions from '@/views/PriceSuggestions.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    order: 0,
    meta: { displayName: '$vuetify.nav.dashboard' },
    icon: 'mdi-desktop-mac-dashboard',
    disabled: false,
    enabledCheck: () => true,
  },
  {
    path: '/data-analysis',
    name: 'DataAnalysis',
    component: DataAnalysis,
    meta: { displayName: '$vuetify.nav.dataAnalysis' },
    order: 1,
    icon: 'mdi-table-search',
    disabled: false,
    enabledCheck: () => true,
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: { displayName: '$vuetify.nav.pricing' },
    order: 5,
    icon: 'mdi-currency-usd',
    disabled: true,
    enabledCheck: () => true,
  },
  {
    path: '/pricing-fit',
    name: 'FIT Rates',
    component: FitRates,
    meta: { displayName: '$vuetify.nav.pricing-fit' },
    order: 5,
    icon: 'mdi-calendar-month',
    disabled: true,
    enabledCheck: () => true,
  },
  {
    path: '/pick-up',
    name: 'PickUp',
    component: PickUp,
    meta: { displayName: '$vuetify.nav.pickUp' },
    order: 4,
    icon: 'mdi-finance',
    disabled: false,
    enabledCheck: () => true,
  },
  {
    path: '/hurdle-rate',
    name: 'Hurdle Rate',
    component: HurdleRate,
    meta: { displayName: '$vuetify.nav.hurdleRate' },
    order: 5,
    icon: 'mdi-gauge',
    disabled: false,
    enabledCheck: () => true,
  },
  {
    path: '/competition',
    name: 'Competition',
    component: Competition,
    meta: { displayName: '$vuetify.nav.competition' },
    order: 6,
    icon: 'mdi-podium-gold',
    disabled: false,
    enabledCheck: () => true,
  },
  {
    path: '/bar-suggestions',
    name: 'Bar Suggestions',
    component: PriceSuggestions,
    meta: { displayName: '$vuetify.nav.barSuggestions' },
    order: 6,
    icon: 'mdi-swap-vertical',
    disabled: false,
    enabledCheck: () => true,
  },
  // {
  //   path: '/demand-curve',
  //   name: 'DemandCurve',
  //   component: DemandCurve,
  //   displayName: '$vuetify.nav.demandCurve',
  //   order: 7,
  //   icon: 'mdi-chart-multiline',
  //   disabled: false,
  //   enabledCheck: () => true,
  // },
  // {
  //   path: '/price-sensitivity',
  //   name: 'PriceSensitivity',
  //   component: PriceSensitivity,
  //   displayName: '$vuetify.nav.priceSensitivity',
  //   order: 8,
  //   disabled: false,
  //   enabledCheck: () => true,
  //   icon: 'mdi-tag',
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;

<template>
  <v-menu
    v-model="selectionOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        color="white"
        v-bind="attrs"
        v-on="on"
        class="filter-text"
      >
        <span
          v-if="displayedInterval.startsWith('$')"
          class="filter-text"
        >
          {{ $vuetify.lang.t(displayedInterval) }}
        </span>
        <span
          v-else
          class="filter-text"
        >
          {{ displayedInterval }}
        </span>
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="px-0 pt-0">
        <div
          v-for="item in periodsTypes"
          :key="item.type"
        >
          <!--          <v-chip-->
          <!--            label-->
          <!--            :active="true"-->
          <!--            class="period-selector px-4"-->
          <!--            :color="activeMode === item.type? 'secondary lighten-1': 'white'"-->
          <!--            text-color="grey darken-4"-->
          <!--            v-if="item.type === 'none'"-->
          <!--            @click="()=>handleSelection({mode: 'none'})"-->
          <!--          >-->
          <!--            {{ $vuetify.lang.t(item.displayName) }}-->
          <!--          </v-chip>-->
          <YearPicker
            v-if="item.type === 'none'"
            class="period-selector px-4"
            text-color="grey darken-4"
            :color="activeMode === item.type? 'secondary lighten-1': 'white'"
            :selected="activeMode === item.type"
            :global-intervals="globalIntervals"
            @selection="handleSelection"
          />
          <PeriodDatePicker
            @selection="handleSelection"
            v-else
            :mode="item"
            :selected="activeMode === item.type"
            :global-intervals="globalIntervals"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment';
import PeriodDatePicker from '@/components/main/filter_bar_components/PeriodDatePicker.vue';
import YearPicker from '@/components/main/filter_bar_components/YearPicker.vue';

const periodsTypes = [
  {
    type: 'none',
    displayName: '$vuetify.filterBar.noPeriod',
  },
  {
    type: 'month',
    displayName: '$vuetify.filterBar.month',
    ref: 'month_menu',
  },
  {
    type: 'week',
    displayName: '$vuetify.filterBar.week',
    ref: 'week_menu',
  },
  {
    type: 'custom',
    displayName: '$vuetify.filterBar.custom',
    ref: 'custom_menu',
  },
];

export default {
  name: 'PeriodPicker',
  components: { YearPicker, PeriodDatePicker },
  props: {
    globalIntervals: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.handleSelection({
      value: [moment().startOf('month'), moment().endOf('month')],
      mode: 'month',
    });
    this.initSelection();
  },
  methods: {
    computeDisplayInterval() {
      if (this.activeMode === 'none' || !this.selectedPeriod) {
        return `${moment(this.selectedPeriod[0])
          .locale(this.lang.current).year()}`;
      }
      let res = '';
      const startMoment = moment(this.selectedPeriod[0])
        .locale(this.lang.current);
      const endMoment = moment(this.selectedPeriod[1])
        .locale(this.lang.current);
      switch (this.activeMode) {
        case 'month':
          res = startMoment.format('MMMM [\']YY');
          break;
        default:
          if (startMoment.get('month') === endMoment.get('month')) {
            res = `${startMoment.format('DD')} - ${endMoment.format('DD MMM [\']YY')}`;
          } else if (startMoment.get('year') === endMoment.get('year')) {
            res = `
            ${startMoment.format('DD MMM')} -
            ${endMoment.format('DD MMM [\']YY')}`;
          } else {
            res = `
            ${startMoment.format('DD MMM [\']YY')} -
            ${endMoment.format('DD MMM [\']YY')}
          `;
          }
          break;
      }
      return res;
    },
    forwardSelection() {
      this.$emit('change', {
        period: this.selectedPeriod,
      });
    },
    initSelection() {
      this.$emit('start', {
        period: this.selectedPeriod,
      });
    },
    handleSelection(event) {
      this.activeMode = event.mode;
      this.selectedPeriod = event.value;
      this.displayedInterval = this.computeDisplayInterval();
      this.selectionOpen = false;
    },
  },
  watch: {
    selectionOpen(val) {
      if (!val) {
        this.forwardSelection();
      }
    },
    lang: {
      handler() {
        this.displayedInterval = this.computeDisplayInterval();
      },
      deep: true,
    },
  },
  data() {
    return {
      periodsTypes,
      activeMode: periodsTypes[0].type,
      selectionOpen: false,
      displayedInterval: '$vuetify.filterBar.noPeriod',
      lang: this.$vuetify.lang,
    };
  },
};
</script>

<style lang="scss">
@import "src/scss/mixins";
.period-selector {
  &.v-chip--label {
    @include border-radius(0);
    width: 100%;
    font-size: medium!important;
  }
}
.date-mode-label {
  font-size: medium;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}
</style>

<template>
  <v-simple-table
    dense
    class="my-2"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $vuetify.lang.t('$vuetify.hurdleRate.date') }}
          </th>
          <th class="text-center">
            {{ $vuetify.lang.t('$vuetify.hurdleRate.hurdleRate') }}
          </th>
          <th
            v-if="showCompetitors"
            class="text-center"
          >
            {{ $vuetify.lang.t('$vuetify.dashboard.compset') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in hurdleRates"
          :key="item.key.date"
        >
          <td>{{ formatDate(item.key.date) }}</td>
          <td class="text-center">
            <HurdleRate
              :item="item"
              :date="formatDate(item.key.date)"
            />
          </td>
          <td
            v-if="showCompetitors"
            class="text-center"
          >
            <AvgCompetitorPriceSkittle
              :value="competitorPrices[item.key.date]"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import moment from 'moment';
import HurdleRate from '@/components/Pricing/HurdleRateDialog.vue';
import AvgCompetitorPriceSkittle from '@/components/Pricing/skittles/AvgCompetitorPriceSkittle.vue';

export default {
  name: 'RoomHurdleRates',
  components: { AvgCompetitorPriceSkittle, HurdleRate },
  props: {
    hurdleRates: null,
    competitorPrices: null,
    showCompetitors: null,
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style scoped>

</style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    session: null,
    filter: null,
    intervalSet: () => this.filter
        && this.filter.startPeriod !== null,
    structureSet: () => this.filter
        && this.filter.structures
        && this.filter.structures.length > 0,
    roomTypeSet: () => this.state.filter
        && this.filter.roomTypes
        && this.filter.roomTypes.length > 0,
  },
  mutations: {
    auth(state, auth) {
      state.session = auth;
    },
    filterChange(state, val) {
      state.filter = val;
      state.intervalSet = val && val.periodStart !== null;
      state.structureSet = val && (val.structures || []).length > 0;
      state.roomTypeSet = val && (val.roomTypes || []).length > 0;
    },
  },
  actions: {
  },
  modules: {
  },
});

<template>
  <v-menu
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-large
        icon
        color="white"
        v-bind="attrs"
        v-on="on"
        class="mx-2"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item>
          <v-list-item-title @click="logout">
            {{ $vuetify.lang.t('$vuetify.accountMenu.logout') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AccountMenu',
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {
    logout() {
      this.$keycloak.logout();
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-chip
    v-bind="attrs||{}"
    v-on="on||{}"
    :color="getColor()"
    small
    dark
    dense
    :disabled="value===null || value===0"
    class="font-weight-bold text-caption px-2"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          v-if="value!==null && value!==0"
        >
          {{ displayValue }}<i class="mdi mdi-currency-eur ml-0" />
        </span>
        <span
          v-bind="attrs"
          v-on="on"
          v-else
        >
          <i class="mdi mdi-minus" />
        </span>
      </template>
      <span>{{ $vuetify.lang.t('$vuetify.pricing.competitorPriceTooltip') }}</span>
    </v-tooltip>
  </v-chip>
</template>

<script>
import chartUtil from '@/components/lib/chartUtil';

export default {
  name: 'AvgCompetitorPriceSkittle',
  props: {
    on: null,
    attrs: null,
    value: {
      type: null,
      default: () => null,
    },
  },
  computed: {
    displayValue() {
      return chartUtil.approximateNumber(this.value, 0);
    },
  },
  methods: {
    getColor() {
      return process.env.NODE_ENV === 'demo' ? 'secondary' : '#3f4a5b';
    },
  },
};
</script>

<style scoped>

</style>

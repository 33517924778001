import FileDownload from 'js-file-download';
import moment from 'moment';

const getHotel = async (id) => {
  const response = await window.axios.get(`/hotels/${id}`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const getHotelRooms = async (id) => {
  const response = await window.axios.get(`/hotels/${id}/rooms`);
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const historyStats = async () => {
  const response = await window.axios.get('/filters/history_stats');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listAllRates = async () => {
  const response = await window.axios.get('/filters/rates');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listAllHotels = async () => {
  const response = await window.axios.get('/hotels');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const listPickupFiles = (page) => {
  const source = window.cancelToken.source();
  const request = window.axios
    .post(
      '/pickup',
      page,
      {
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(response);
    })
    .catch((reason) => Promise.reject(reason));
  return {
    request,
    cancel: source,
  };
};

const downloadPickupFile = async (id, fileName) => {
  const response = await window.axios.get(`/pickup/${id}/download`, { responseType: 'blob' });
  if (response.status === 200) {
    return FileDownload(response.data, fileName || `pickup_export_${id}.pdf`);
  }
  return 'error';
};

const listRoomTypes = async () => {
  const response = await window.axios.get('/room_types');
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchADRStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/adr_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchRevByChannel = async (filter) => {
  const response = await window.axios.post(
    '/charts/rev_by_channel',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchADRPlusStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/adr_plus_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchTotalRevStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/total_rev_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchPaxStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/pax_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchReservationStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/reservation_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};
const fetchOccupancyStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/occupancy_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchRevParStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/rev_par_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchRevParPlusStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/rev_par_plus_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchRoomNightStats = async (filter) => {
  const response = await window.axios.post(
    '/charts/room_night_stats',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchDetailsByStructure = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/details_by_structure',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchStagingChosenBar = async (filter) => {
  const response = await window.axios.post(
    '/staging_chosen_bar/',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};
const updateStagingChosenBar = async (body) => {
  const response = await window.axios.post(
    '/staging_chosen_bar/update',
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchPagedStagingBars = async (params) => {
  const response = await window.axios.get(
    '/staging_chosen_bar/paged_search',
    {
      params,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchDetailsByRoom = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/details_by_room',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const downloadDetailsByStructure = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/details_by_structure/csv_export',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `data_analysis_export_${date}.csv`);
  }
  return 'error';
};

const downloadDetailsByStructureMonthly = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/details_by_structure_monthly/csv_export',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `data_analysis_export_${date}.csv`);
  }
  return 'error';
};

const downloadDetailsByRoom = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/details_by_room/csv_export',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `hotel_data_analysis_export_${date}.csv`);
  }
  return 'error';
};

const fetchRates = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/rates',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchConsolidRates = async (filter) => {
  const response = await window.axios.post(
    '/hotel_history/consolid_rates',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchBars = async (filter) => {
  const response = await window.axios.post(
    '/bars/filter',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchFit = async (filter) => {
  const response = await window.axios.post(
    '/fit/filter',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchPromos = async (filter) => {
  const response = await window.axios.post(
    '/fit/daily-promos',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchOpen = async (filter) => {
  const response = await window.axios.post(
    '/fit/daily-closed',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchFitDetails = async (params) => {
  const response = await window.axios.post(
    '/fit/details',
    params,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const barsByHotel = async (hotelId) => {
  const response = await window.axios.get(
    `/bars/list/${hotelId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const importPrices = async () => {
  const response = await window.axios.get(
    '/bars/import_prices',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchHurdleRates = async (filter) => {
  const response = await window.axios.post(
    '/hurdle_rates',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchRawHurdleRates = async (filter) => {
  const response = await window.axios.post(
    '/hurdle_rates_fit/raw',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const exportHurdleRateToCSV = async (filter) => {
  const response = await window.axios.post(
    '/hurdle_rates/csv_export',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `hurdle_rates_export_${date}.csv`);
  }
  return 'error';
};

const fetchHurdleRatesFit = async (filter) => {
  const response = await window.axios.post(
    '/hurdle_rates_fit',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchRawHurdleRatesFit = async (filter) => {
  const response = await window.axios.post(
    '/hurdle_rates_fit/raw',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const exportHurdleRateFitToCSV = async (filter) => {
  const response = await window.axios.post(
    '/hurdle_rates_fit/csv_export',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `hurdle_rates_export_${date}.csv`);
  }
  return 'error';
};

const updateHurdleRateFitClosed = async (param) => {
  const response = await window.axios.put(
    '/hurdle_rates_fit/update-closed',
    param,
    {
      params: param,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 202 || response.status === 200) {
    return response.data;
  }
  throw response;
};

const exportHotelBars = async () => {
  const response = await window.axios.get(
    '/bars/hotel_bar/csv_export',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `live_bars_export_${date}.csv`);
  }
  return 'error';
};

const exportRollingForecast = async () => {
  const response = await window.axios.get(
    '/bars/rolling_forecast/csv_export',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `rolling_forecast_export_${date}.csv`);
  }
  return 'error';
};

const exportChosenBars = async () => {
  const response = await window.axios.get(
    '/bars/chosen_bar/csv_export',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `chosen_bars_export_${date}.csv`);
  }
  return 'error';
};

const exportHotelRates = async () => {
  const response = await window.axios.get(
    '/fit/rates/csv_export',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `chosen_rates_export_${date}.csv`);
  }
  return 'error';
};

const exportChosenRates = async () => {
  const response = await window.axios.get(
    '/fit/promos/csv_export',
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `chosen_rates_export_${date}.csv`);
  }
  return 'error';
};

const fetchBarDetails = async (filter) => {
  const response = await window.axios.post(
    '/bars/details',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateFitClosed = async (param) => {
  const response = await window.axios.put(
    '/fit/update-closed',
    param,
    {
      params: param,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 202 || response.status === 200) {
    return response.data;
  }
  throw response;
};

const updateFits = async (data) => {
  const response = await window.axios.put(
    '/fit/update-promo',
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 202 || response.status === 200) {
    return response.data;
  }
  throw response;
};

const fetchCompetitorPrices = async (filter) => {
  const response = await window.axios.post(
    '/bars/competitor_prices',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const updateBars = async (data) => {
  const response = await window.axios.put(
    `/bars/${data.idHotel}`,
    null,
    {
      params: {
        from: data.from,
        to: data.to,
        bar_id: data.barId,
        prepaid: data.prepaid,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 202 || response.status === 200) {
    return response.data;
  }
  throw response;
};

const updateGlobalBars = async (data) => {
  const response = await window.axios.put(
    `/bars/${data.idHotel}/global_change`,
    null,
    {
      params: {
        hotel_id: data.idHotel,
        from: data.start,
        to: data.end,
        bar_id: data.barId,
        prepaid: data.prepaid,
        default_bar_id: data.defaultBarId,
        default_prepaid: data.defaultPrepaid,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 202 || response.status === 200) {
    return response.data;
  }
  throw response;
};

const updateGlobalFits = async (data) => {
  const response = await window.axios.put(
    '/fit/global-change',
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 202 || response.status === 200) {
    return response.data;
  }
  throw response;
};

const fetchCompetitorRanks = async (filter) => {
  const response = await window.axios.post(
    '/competitors/ranks',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchPriceByCompetitor = async (filter) => {
  const response = await window.axios.post(
    '/competitors/prices',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchDailyPrices = async (filter) => {
  const response = await window.axios.post(
    '/competitors/daily_prices',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const fetchCompetitorList = async (filter) => {
  const response = await window.axios.post(
    '/competitors/list',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  return 'error';
};

const exportCompetitorPrices = async (filter) => {
  const response = await window.axios.post(
    '/competitors/csv_export',
    filter,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    const date = moment().format('DD-MM-YYYY-HH:mm');
    return FileDownload(response.data, `compset_export_${date}.csv`);
  }
  return 'error';
};

export default {
  hotels: {
    getHotel,
    listAll: listAllHotels,
    listAllRooms: getHotelRooms,
  },
  rooms: {
    listAllTypes: listRoomTypes,
  },
  filters: {
    historyStats,
    listAllRates,
  },
  hotelHistory: {
    fetchADRStats,
    fetchADRPlusStats,
    fetchRoomNightStats,
    fetchDetailsByStructure,
    fetchDetailsByRoom,
    fetchRates,
    fetchConsolidRates,
    fetchTotalRevStats,
    fetchRevParStats,
    fetchRevParPlusStats,
    fetchPaxStats,
    fetchReservationStats,
    fetchOccupancyStats,
    downloadDetailsByStructure,
    downloadDetailsByStructureMonthly,
    downloadDetailsByRoom,
    revByChannel: fetchRevByChannel,
  },
  bars: {
    fetch: fetchBars,
    fetchBarDetails,
    updateBars,
    exportHotelBars,
    exportChosenBars,
    exportRollingForecast,
    updateGlobalBars,
    fetchCompetitorPrices,
    importPrices,
    availableBars: barsByHotel,
  },
  fit: {
    fetchFit,
    fetchPromos,
    fetchOpen,
    fetchFitDetails,
    exportHotelRates,
    exportChosenRates,
    updateClosed: updateFitClosed,
    updateFits,
    updateGlobalFits,
  },
  hurdleRates: {
    get: fetchHurdleRates,
    raws: fetchRawHurdleRates,
    exportCSV: exportHurdleRateToCSV,
  },
  hurdleRatesFit: {
    updateClosed: updateHurdleRateFitClosed,
    get: fetchHurdleRatesFit,
    raws: fetchRawHurdleRatesFit,
    exportCSV: exportHurdleRateFitToCSV,
  },
  pickup: {
    list: listPickupFiles,
    downloadPdf: downloadPickupFile,
  },
  competitors: {
    ranks: fetchCompetitorRanks,
    prices: fetchPriceByCompetitor,
    list: fetchCompetitorList,
    dailyPrices: fetchDailyPrices,
    exportCsv: exportCompetitorPrices,
  },
  stagingChosenBar: {
    getByFilter: fetchStagingChosenBar,
    search: fetchPagedStagingBars,
    update: updateStagingChosenBar,
  },
};

<template>
  <v-card
    flat
    v-if="filter && filter.structures.length > 0"
  >
    <!-- Date Change Top Bar -->
    <v-card-title
      class="d-flex"
    >
      <v-btn
        :disabled="loading"
        icon
        class="ma-2"
        @click="previous"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-menu
        v-model="datePicker"
        transition="scroll-y-transition"
        offset-y
        :close-on-content-click="false"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="text-h5 text-capitalize justify-center"
            label
            color="white"
            v-bind="attrs"
            v-on="on"
            style="width: 290px"
          >
            {{ $vuetify.lang.t('$vuetify.competitors.pricing') }}
          </v-chip>
        </template>
        <v-date-picker
          no-title
          v-model="start"
          :disabled="loading"
          @change="pickDate"
        />
      </v-menu>
      <v-spacer />
      <v-btn
        :disabled="loading"
        icon
        class="ma-2"
        @click="next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <!--Table Data -->
    <v-row no-gutters>
      <v-col md="12">
        <v-data-table
          :headers="competitorHeaders"
          :items="competitors"
          item-key="idHotel"
          class="elevation-0"
          dense
          disable-pagination
          disable-sort
          :loading="loading"
          :group-by="['hotelName','room', 'mealpan']"
          hide-default-footer
          hide-default-header
        >
          <!-- Table Header Labels -->
          <template v-slot:header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  role="columnheader"
                  style="border-bottom: 1px solid rgba(0,0,0,0.12)"
                  :class="`${i === 0? 'text-left': 'text-center'} pa-3`"
                  v-for="(header, i) in headers"
                  :key="`pth-${i}`"
                  :width="header.width||''"
                >
                  <span v-if="i <=4 "> {{ $vuetify.lang.t(header.text) }}</span>
                  <span v-else>
                    {{ $vuetify.lang.t(header.text) }}
                  </span>
                </th>
              </tr>
            </thead>
          </template>
          <!-- Table Expanded Rooms Rows -->
          <template v-slot:item="{ item, idx }">
            <tr :class="item.competitorName === item.hotelName ? 'font-weight-bold': ''">
              <td v-if="item.competitorName === item.hotelName">
                <a @click="toPricing(item)" style="color: #96b2dd"> {{ item.competitorName }} </a>
              </td>
              <td v-else>
                {{ item.competitorName }}
              </td>
              <td />
              <td />
              <td
                v-for="i in competitorHeaders.length - 4"
                :key="`v_${idx}_${i}`"
                class="text-center"
              >
                {{ item[competitorHeaders[i+3].value] }}
                <i
                  class="mdi-currency-eur mdi"
                  v-if="item[competitorHeaders[i+3].value]"
                />
                <i
                  class="mdi-minus mdi"
                  v-else
                />
              </td>
            </tr>
          </template>
          <!-- Table Expandable Data Row -->
          <template v-slot:group.header="{ group, items, toggle, isOpen }">
            <td
              class="primary lighten-1"
            >
              <v-btn
                icon
                @click="toggle"
                small
              >
                <v-icon v-if="isOpen">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
              {{ group }}
            </td>
            <td
              class="primary lighten-1 text-center"
            >
              {{ items[0].room }}
            </td>
            <td
              class="primary lighten-1 text-center"
            >
              BB
            </td>
            <td
              class="primary lighten-1 text-right"
              :colspan="range"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Table Footer -->
    <v-row no-gutters>
      <v-spacer />
      <v-btn
        color="primary"
        class="align-self-center mr-3"
        rounded
        @click="exportCsv"
      >
        {{ $vuetify.lang.t('$vuetify.export') }}
      </v-btn>
    </v-row>
  </v-card>
  <div v-else />
</template>

<script>
import api from '@/api';
import moment from 'moment';
import chartUtil from '@/components/lib/chartUtil';

const competitorHeaders = [
  {
    text: 'Hotel',
    value: 'hotelName',
  },
  {
    text: '$vuetify.competitors.compset',
    value: 'competitorName',
    align: 'left',
  },
  {
    text: '$vuetify.competitors.room',
    value: '',
    align: 'center',
  },
  {
    text: '$vuetify.competitors.mealPlan',
    value: '',
    align: 'center',
  },
];

export default {
  name: 'CompetitorTable',
  mounted() {
    this.fetchData();
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange'
        && JSON.stringify(state.filter) !== JSON.stringify(this.filter)) {
        this.filter = state.filter;
        this.fetchData();
      }
    }));
  },
  methods: {
    toPricing(comp) {
      this.$router.push({
        name: 'Pricing',
        params: {
          month: moment(this.start),
          idHotel: comp.hotelId,
          roomReferenceType: null,
        },
      });
    },
    pickDate() {
      this.datePicker = false;
      this.end = moment(this.start).add(this.range, 'days').format('yyyy-MM-DD');
      this.fetchData();
    },
    next() {
      this.start = moment(this.start).add(this.range, 'days').format('yyyy-MM-DD');
      this.end = moment(this.start).add(this.range, 'days').format('yyyy-MM-DD');
      this.fetchData();
    },
    previous() {
      this.start = moment(this.start).subtract(this.range, 'days').format('yyyy-MM-DD');
      this.end = moment(this.start).add(this.range, 'days').format('yyyy-MM-DD');
      this.fetchData();
    },
    async exportCsv() {
      let { structures, roomServices } = this.filter;
      if (structures.length === 0) {
        structures = [...new Set(this.competitors.map((e) => e.hotelId))];
      }
      if (roomServices.length === 0) {
        roomServices = [...new Set(this.competitors.map((e) => e.mealPlan))];
      }
      const reqFilter = {
        structures,
        periodStart: this.start,
        periodEnd: this.end,
        roomServices,
        roomTypes: this.filter.roomTypes,
      };
      await api.competitors.exportCsv(reqFilter);
    },
    async fetchData() {
      if (!this.filter || this.filter.structures.length === 0) return;
      this.loading = true;
      const headers = competitorHeaders.slice(0, 4);
      for (let i = 0; i < this.range; i += 1) {
        const day = moment(this.start).add(i, 'days');
        headers.push({
          value: day.format('yyyy-MM-DD'),
          text: day.format('DD-MM-YYYY'),
          date: day,
          align: 'center',
        });
      }
      this.competitorHeaders = headers;
      const competitors = (await api.competitors.list(this.filter));
      let { structures, roomServices } = this.filter;
      if (structures.length === 0) {
        structures = [...new Set(competitors.map((e) => e.hotelId))];
      }
      if (roomServices.length === 0) {
        roomServices = [...new Set(competitors.map((e) => e.mealPlan))];
      }
      const reqFilter = {
        structures,
        periodStart: this.start,
        periodEnd: this.end,
        roomServices,
        roomTypes: this.filter.roomTypes,
      };
      const prices = await api.competitors.dailyPrices(reqFilter);
      prices.forEach((p) => {
        for (let i = 0; i < competitors.length; i += 1) {
          const c = competitors[i];
          if (c.mealPlan === p.mealPlan
            && c.competitorId === p.competitorId
            && c.room === p.room
            && c.hotelName === p.hotelName
          ) {
            c[p.day] = `${chartUtil.approximateNumber(p.price)}`;
            break;
          }
        }
      });
      this.competitorHeaders = headers;
      this.competitors = competitors;
      this.loading = false;
    },
  },
  data() {
    const range = 10;
    return {
      competitors: [],
      competitorHeaders,
      loading: false,
      filter: this.$store.state.filter,
      start: moment().format('yyyy-MM-DD'),
      end: moment().add(range, 'day').format('yyyy-MM-DD'),
      range,
      datePicker: false,
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="isOpen"
    width="750px"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        rounded
        @click="isOpen = true"
        :disabled="selection.length === 0"
      >
        Applica Selezione
      </v-btn>
    </template>
    <v-card
      min-width="350px"
      flat
    >
      <v-toolbar
        color="secondary"
        dark
        class="font-weight-bold"
      >
        Applica Suggerimenti Selezionati
      </v-toolbar>
      <v-card-text>
        <v-simple-table class="pt-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Data</th>
                <th>Hotel</th>
                <th>Bar</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, idx) in selection"
                :key="idx"
              >
                <td>{{ formatDate(item.date) }}</td>
                <td>{{ item.hotelName }}</td>
                <td>
                  {{ barName(item.currentBar.idBar) }}
                  <i class="mdi mdi-arrow-right" />
                  {{ barName(item.suggestedBar.idBar) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="pt-0 pb-3">
        <v-btn
          color="error"
          text
          @click="isOpen = false"
        >
          Annulla
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="submitChanges"
        >
          Salva
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import api from '@/api';

export default {
  name: 'ApplySelectionDialog',
  props: {
    selection: null,
  },
  methods: {
    barName(bar) {
      if (bar === undefined) return '';
      if (bar === 0) return 'RACK';
      return `BAR${bar}`;
    },
    async submitChanges() {
      const body = this.selection.map((s) => ({
        ...s.suggestedBar,
        prepaid: s.currentBar.prepaid,
      }));
      await api.stagingChosenBar.update(body);
      this.isOpen = false;
      this.$emit('saved');
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>

</style>

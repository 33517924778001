<!--Revenue Menu displaying 3 Metric Cards -->
<template>
  <v-row
    no-gutters
    class="fill-height"
  >
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.adr_plus"
        :value="metrics.adrPlus"
        :currency="true"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).adrPlus"
        :budget-value="(budgetMetrics|| {}).adrPlus"
        :forecast-value="metrics.predictedADR"
        :consolidated-value="(consolidMetrics|| {}).adrPlus"
        :enable-forecast="true"
        :enable-budget="true"
        :enable-consolidated="vp"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.revParPlus"
        :value="metrics.revParPlus"
        :currency="true"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).revParPlus"
        :consolidated-value="(consolidMetrics|| {}).revParPlus"
        :enable-consolidated="vp"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        :title="revTitle"
        :value="metrics.totalRevenue"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).totalRevenue"
        :forecast-value="metrics.predictedRevenue"
        :budget-value="(budgetMetrics|| {}).totalRevenue"
        :consolidated-value="(consolidMetrics|| {}).totalRevenue"
        :enable-forecast="true"
        :enable-budget="true"
        :enable-consolidated="vp"
      />
    </v-col>
  </v-row>
</template>
<script>
import MetricCard from '@/components/Dashboard/metrics/MetricCard.vue';

export default {
  name: 'RevenueKPI',
  components: { MetricCard },
  props: {
    metrics: {
      type: Object,
      required: true,
    },
    historicalMetrics: {
      type: Object,
      default: () => null,
    },
    budgetMetrics: {
      type: Object,
      default: () => null,
    },
    consolidMetrics: {
      type: Object,
      default: () => null,
    },
    vp: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.filter = state.filter;
        this.mutateName(state.filter.roomServices);
      }
    }));
  },
  methods: {
    mutateName(serv) {
      if (serv.length === 0 || (serv.includes('Rooms') && serv.length > 1)) this.revTitle = '$vuetify.dashboard.totalRevenueDuo';
      if (serv.includes('Rooms') && serv.length === 1) this.revTitle = '$vuetify.dashboard.totalRevenueRoom';
      if (!serv.includes('Rooms') && serv.length > 0) this.revTitle = '$vuetify.dashboard.totalRevenueService';
    },
    loadName(serv) {
      if (serv.length === 0 || (serv.includes('Rooms') && serv.length > 1)) return '$vuetify.dashboard.totalRevenueDuo';
      if (serv.includes('Rooms') && serv.length === 1) return '$vuetify.dashboard.totalRevenueRoom';
      if (!serv.includes('Rooms') && serv.length > 0) return '$vuetify.dashboard.totalRevenueService';
      return '$vuetify.dashboard.totalRevenueDuo';
    },
  },
  data() {
    return {
      revTitle: this.loadName(this.$store.state.filter.roomServices),
      filter: null,
    };
  },
};
</script>
<style lang="scss">
</style>

<template>
  <v-container
    fluid
    class="ma-3 pa-3 bar-suggestions"
    v-if="$store.state.filter"
  >
    <v-card
      class="pa-10"
      flat
    >
      <div class="d-flex flex-row-reverse mb-3">
        <ApplySelectionDialog
          :selection="selectedKeys"
          @saved="loadData"
        />
      </div>
      <v-data-table
        :headers="headers"
        :items="rows || []"
        item-key="key"
        :loading="loading"
        class="elevation-0"
        :disable-sort="true"
        show-expand
        show-select
        selectable-key="key"
        @toggle-select-all="selectAll"
        @item-selected="handleRowSelection"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <BarSuggestionExpansion
            :headers="headers.length"
            :item="item"
            :competitor-prices="competitorPrices"
          />
        </template>
        <template v-slot:item.date="{ item }">
          <div class="">
            {{ formatDate(item.date) }}
          </div>
        </template>
        <template v-slot:item.currentBar="{ item }">
          <div class="text-center">
            {{ barName(item.currentBar.idBar) }}
          </div>
        </template>
        <template v-slot:item.suggestedBar="{ item }">
          <div class="text-center">
            {{ barName(item.suggestedBar.idBar) }}
            <v-icon
              small
              color="success"
              v-if="item.suggestedBar.idBar < item.currentBar.idBar"
            >
              mdi-arrow-up
            </v-icon>
            <v-icon
              small
              color="error"
              v-else
            >
              mdi-arrow-down
            </v-icon>
          </div>
        </template>
        <template v-slot:item.occupancy="{ item }">
          <div class="text-center">
            {{ fixedDigits(item.occupancy, 2).toLocaleString($vuetify.lang.current) }}%
          </div>
        </template>
        <template v-slot:item.adr="{ item }">
          <div class="text-center">
            {{ fixedDigits(item.adr, 2).toLocaleString($vuetify.lang.current) }}
            <i class="mdi mdi-currency-eur no-space" />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import chartUtil from '@/components/lib/chartUtil';
import moment from 'moment';
import hrUtils from '@/components/lib/hurdleRateUtils';
import BarSuggestionExpansion from '@/components/PricingSuggestion/BarSuggestionExpansion.vue';
import ApplySelectionDialog from '@/components/PricingSuggestion/ApplySelectionDialog.vue';

const tableHeaders = [
  {
    text: 'Data',
    value: 'date',
  },
  {
    text: 'Hotel',
    value: 'hotelName',
  },
  {
    text: 'Current Bar',
    value: 'currentBar',
    align: 'center',
  },
  {
    text: 'Suggetsted Bar',
    value: 'suggestedBar',
    align: 'center',
  },
  {
    text: 'Occupancy %',
    value: 'occupancy',
    align: 'center',
  },
  {
    text: 'ADR',
    value: 'adr',
    align: 'center',
  },
  {
    text: '',
    value: 'data-table-expand',
  },
];
export default {
  name: 'PriceSuggestions',
  components: { ApplySelectionDialog, BarSuggestionExpansion },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange'
        && JSON.stringify(this.filter) !== JSON.stringify(state.filter)) {
        this.filter = state.filter;
        this.loadData();
      }
    }));
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted() {
    this.filter = this.$store.state.filter;
    this.loadData();
  },
  methods: {
    selectAll(data) {
      const { items, value } = data;
      if (value) {
        this.selectedKeys = items;
      } else {
        this.selectedKeys = [];
      }
    },
    barName(bar) {
      if (bar === undefined) return '';
      if (bar === 0) return 'RACK';
      return `BAR${bar}`;
    },
    keysEquals(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
    handleRowSelection(data) {
      const { item, value } = data;
      const itemKey = item.suggestedBar.key;
      if (value) {
        this.selectedKeys.push(item);
      } else {
        let matched = -1;
        for (let i = 0; i < this.selectedKeys.length; i += 1) {
          if (this.keysEquals(itemKey, this.selectedKeys[i].suggestedBar.key)) {
            matched = i;
            break;
          }
        }
        if (matched >= 0) {
          // eslint-disable-next-line no-param-reassign
          item.selected = false;
          this.selectedKeys.splice(matched, 1);
        }
      }
    },
    buildApiFilter() {
      if (!this.rows || this.rows.length === 0) return;
      const structures = this.rows.map((b) => (b.currentBar.idHotel));
      let periodStart;
      let periodEnd;
      this.rows.forEach((b) => {
        const date = moment(b.date);
        if (!periodStart || date.isBefore(periodStart)) {
          periodStart = date;
        }
        if (!periodEnd || date.isAfter(periodEnd)) {
          periodEnd = date;
        }
      });
      // eslint-disable-next-line consistent-return
      return {
        structures,
        periodStart: periodStart.format('YYYY-MM-DD'),
        periodEnd: periodEnd.format('YYYY-MM-DD'),
      };
    },
    async loadCompetitorPrices() {
      if (!this.rows || this.rows.length === 0) return;
      const body = this.buildApiFilter();
      this.competitorPrices = await api.bars.fetchCompetitorPrices(body);
      this.hurdleRates = await api.hurdleRates.raws(body);
    },
    fixedDigits: chartUtil.approximateNumber,
    async loadData() {
      if (!this.filter) return;
      this.loading = true;
      this.selectedKeys = [];
      const params = {
        page: this.currentPage,
        size: this.pageSize,
        start: this.filter.periodStart,
        end: this.filter.periodEnd,
      };
      if (this.filter.structures.length > 0) {
        params.hotels = this.filter.structures.join(',');
      }
      this.suggestions = await api.stagingChosenBar.search(params);
      this.rows = this.suggestions.content
        .filter((i) => i.suggestedBar !== i.currentBar)
        .map((item) => ({ ...item, key: JSON.stringify(item.suggestedBar.key) }))
        .filter((i) => i.currentBar != null);
      await this.loadCompetitorPrices();
      this.loading = false;
    },
    async handleRowExpansion(e) {
      const { item } = e;
      const preview = await api.bars.fetchBarDetails({
        structures: [item.hotelId],
        barId: item.suggestedBar.idBar,
      });
      const bars = await api.bars.fetchBarDetails({
        structures: [item.hotelId],
        barId: item.currentBar.idBar,
      });
      const apiFilter = {
        structures: [item.hotelId],
        periodStart: item.date,
        periodEnd: item.date,
      };
      this.hurdleRates = await api.hurdleRates.raws(apiFilter);
      const mergedPrices = hrUtils.mergeHurdleRates(bars, this.hurdleRates);
      for (let i = 0; i < mergedPrices.length; i += 1) {
        for (let j = 0; j < preview.length; j += 1) {
          const current = mergedPrices[i];
          const updated = preview[j];
          if (this.sameRow(current, updated)) {
            mergedPrices[i] = {
              ...current,
              newPrice: updated.barPrice,
            };
          }
        }
      }
      this.itemBars[item.key] = mergedPrices;
      item.bars = mergedPrices;
      this.loading = false;
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
  data() {
    return {
      loading: false,
      filter: null,
      rows: null,
      suggestions: null,
      currentPage: 0,
      pageSize: 10,
      headers: tableHeaders,
      selectedKeys: [],
      competitorPrices: null,
      hurdleRates: null,
      itemBars: {},
    };
  },
};
</script>

<style lang="scss">
 .bar-suggestions {
   .v-data-table__expanded.v-data-table__expanded__content {
     box-shadow: none !important;
   }
 }
</style>

<template>
  <v-card class="ma-3 pa-5">
    <v-card-text>
      <v-row class="pb-2">
        <v-col
          md="4"
          class="pl-3"
        >
          <v-text-field
            :placeholder="$vuetify.lang.t('$vuetify.search')"
            v-model="search"
            dense
            append-icon="mdi-magnify"
            hide-details
            single-line
            color="secondary"
            class="px-1 py-0 text-end search-table"
          />
        </v-col>
        <v-spacer />
        <v-btn
          class="primary align-self-center mr-3"
          rounded
          @click="exportCsv"
        >
          {{ $vuetify.lang.t('$vuetify.export') }}
        </v-btn>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="rows"
        item-key="roomId"
        :search="search"
        class="elevation-0"
        :loading="loading"
        :footer-props="{
          hideItemsPerPage: true,
          showFirstLastPage: true,
        }"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
            :data-id="item.idHotel"
            :class="isExpanded ? 'primary lighten-1' : ''"
          >
            <td class="text-start">
              {{ item.destination }}
            </td>
            <td class="text-start">
              {{ item.name }}
            </td>
            <td>
              <v-btn
                icon
                @click="expand(!isExpanded); loadMonthDetails(isExpanded, item)"
              >
                <v-icon v-if="isExpanded" class="mdi mdi-chevron-up" />
                <v-icon v-else class="mdi mdi-chevron-down" />
              </v-btn>
            </td>
            <td class="text-start">
              {{ item.roomId }}
            </td>
            <td class="text-center">
              <span>
                {{ fixedDigits(item.totalRevenue, 2).toLocaleString($vuetify.lang.current) }}
                <i class="mdi mdi-currency-eur no-space " />
              </span>
            </td>
            <td class="text-center">
              {{ fixedDigits(item.adrPlus, 2).toLocaleString($vuetify.lang.current) }}
              <i class="mdi mdi-currency-eur no-space " />
            </td>
            <td class="text-center">
              {{ item.roomNights.toLocaleString($vuetify.lang.current) }}
            </td>
            <td class="text-center">
              {{ item.reservations }}
            </td>
            <td class="text-center">
              {{ item.occupancy }}%
            </td>
            <td class="text-center">
              {{ fixedDigits(item.revParPlus, 2).toLocaleString($vuetify.lang.current) }}
            </td>
            <td class="text-center">
              <span v-if="item.competitorAvg !== 0">
                {{ fixedDigits(item.competitorAvg, 2).toLocaleString($vuetify.lang.current) }}
                <i class="mdi mdi-currency-eur no-space" />
              </span>
              <span v-else><v-icon>mdi-minus</v-icon></span>
            </td>
            <td class="text-center">
              <span v-if="item.suggestedVariation !== 0">
                {{ fixedDigits(item.suggestedVariation, 2).toLocaleString($vuetify.lang.current) }}
                <i class="mdi mdi-currency-eur no-space" />
              </span>
              <span v-else><v-icon>mdi-minus</v-icon></span>
            </td>
            <td class="text-center">
              <v-btn
                color="secondary"
                rounded
                small
                @click="selectStructure(item)"
              >
                {{ $vuetify.lang.t('$vuetify.dataAnalysis.details') }}
              </v-btn>
            </td>
          </tr>
        </template>
        <!-- EXPANDED ITEM ===================================================== -->
        <template v-slot:expanded-item="{item}">
          <td
            v-if="!monthsLoading[item.roomId]"
            colspan="3"
          />
          <td
            :colspan="monthsLoading[item.roomId]
              ? headers.length : headers.length-2"
            class="text-center"
          >
            <v-progress-linear
              stream
              v-model="progress"
              :buffer-value="total == 0 ? 0 : 100"
              color="primary darken-1"
              v-if="monthsLoading[item.roomId]"
            />
            <v-data-table
              :headers="monthHeaders"
              hide-default-footer
              hide-default-header
              :items="monthRows[item.roomId]"
              item-key="idHotelMonth"
              :search="search"
              class="elevation-0"
              multi-sort
              v-else
            >
              <!-- eslint-disable-next-line vue/no-template-shadow -->
              <template v-slot:item="{item}">
                <tr>
                  <td class="text-center" width="200">
                    {{ item.month[$vuetify.lang.current] }}
                  </td>
                  <td class="text-center" width="140">
                    {{ fixedDigits(item.totalRevenue,2).toLocaleString($vuetify.lang.current) }}
                    <i class="mdi mdi-currency-eur" />
                  </td>
                  <td class="text-center" width="110">
                    {{ fixedDigits(item.adrPlus, 2).toLocaleString($vuetify.lang.current) }}
                    <i class="mdi mdi-currency-eur" />
                  </td>
                  <td class="text-center" width="120">
                    {{ item.roomNights.toLocaleString($vuetify.lang.current) }}
                  </td>
                  <td class="text-center" width="125">
                    {{ item.reservations }}
                  </td>
                  <td class="text-center" width="120">
                    {{ item.occupancy }}%
                  </td>
                  <td class="text-center" width="95">
                    {{ fixedDigits(item.revParPlus, 2).toLocaleString($vuetify.lang.current) }}
                  </td>
                  <td class="text-center" width="120">
                    <span v-if="item.competitorAvg !== 0">
                      {{ fixedDigits(item.competitorAvg, 2).
                        toLocaleString($vuetify.lang.current) }}
                      <i class="mdi mdi-currency-eur" />
                    </span>
                    <span v-else><v-icon>mdi-minus</v-icon></span>
                  </td>
                  <td class="text-center" width="120">
                    <span v-if="item.suggestedVariation !== 0">
                      {{ fixedDigits(item.suggestedVariation, 2).
                        toLocaleString($vuetify.lang.current) }}
                      <i class="mdi mdi-currency-eur" />
                    </span>
                    <span v-else><v-icon>mdi-minus</v-icon></span>
                  </td>
                  <td/>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/api';
import chartUtil from '@/components/lib/chartUtil';
import { DateTime } from 'luxon';

export default {
  name: 'StructureDetails',
  mounted() {
    this.filter = this.$store.state.filter;
    this.loadDetails();
    this.monthHeaders = JSON.parse(JSON.stringify(this.headers));
    // eslint-disable-next-line no-restricted-syntax
    for (const i of '1234') this.monthHeaders.shift();
    this.monthHeaders.unshift({
      text: 'Month',
      align: 'center',
      value: 'month',
      width: 103,
    });
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        if (JSON.stringify(this.filter) !== JSON.stringify(state.filter)) {
          this.filter = state.filter;
          this.loadDetails();
        }
      }
    }));
  },
  watch: {
    '$vuetify.lang.current': {
      handler() {
        this.setHeadersLang();
      },
    },
  },
  methods: {
    fixedDigits: chartUtil.approximateNumber,
    async loadDetails() {
      this.loading = true;
      this.monthRows = {};
      this.rows = await api.hotelHistory.fetchDetailsByRoom(this.$store.state.filter);
      this.loading = false;
    },
    async loadMonthDetails(show, item) {
      if (show) return;
      if (this.monthRows[item.roomId]) return;
      this.monthsLoading[item.roomId] = true;
      if (!this.hotelData) this.hotelData = await api.hotels.listAll();
      // Deep copy of filters
      const modFilter = JSON.parse(JSON.stringify(this.$store.state.filter));
      let fieldId = 0;
      this.hotelData.some((hotel) => {
        if (hotel.name === item.name) {
          fieldId = hotel.id;
          return true;
        }
        return false;
      });
      // Search for specific hotel, room and service
      modFilter.structures = [fieldId];
      modFilter.roomTypes = [{
        idHotel: item.idHotel,
        roomReferenceType: item.roomReferenceType,
      }];
      // Build date Array
      const dates = this.genDates(modFilter.periodStart, modFilter.periodEnd);
      this.monthRows[item.roomId] = [];
      dates.forEach(() => { this.monthRows[item.roomId].push(null); });
      // Manage a wait while loading this request
      let pos = 0;
      let prog = 0;
      this.total = dates.length;
      await Promise.all(dates.map(async (date) => {
        // eslint-disable-next-line prefer-destructuring
        modFilter.periodStart = date[0];
        // eslint-disable-next-line prefer-destructuring
        modFilter.periodEnd = date[1];
        const x = pos;
        pos += 1;
        const [row] = await api.hotelHistory.fetchDetailsByRoom(modFilter);
        // eslint-disable-next-line prefer-destructuring
        if (row) row.month = date[2];
        this.monthRows[item.roomId][x] = row;
        prog += 1;
        this.progress = (prog / this.total) * 100;
      }));
      this.progress = 0;
      const temp = [];
      this.monthRows[item.roomId].forEach((i) => {
        if (i != null) temp.push(i);
      });
      this.monthRows[item.roomId] = temp;
      this.monthsLoading[item.roomId] = false;
    },
    genDates(start, end) {
      const startDate = DateTime.fromISO(start).setLocale(this.$vuetify.lang.current);
      const endDate = DateTime.fromISO(end).setLocale(this.$vuetify.lang.current);
      // Starts and ends on same month
      if (startDate.month === endDate.month) {
        const monthString = {};
        monthString.it = startDate.setLocale('it-IT').toFormat('LLLL');
        monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
        monthString.en = startDate.setLocale('en-US').toFormat('LLLL');
        return [[start, end, monthString]];
      }
      // Ends on different months
      let monthString = {};
      monthString.it = startDate.setLocale('it-IT').toFormat('LLLL');
      monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
      monthString.en = startDate.setLocale('en-US').toFormat('LLLL');
      const dates = [[start, startDate.endOf('month').toFormat("yyyy'-'LL'-'dd"), monthString]];
      let som = startDate.plus({ month: 1 }).startOf('month');
      let eom = startDate.plus({ month: 1 }).endOf('month');
      while (eom.month !== endDate.month || eom.year !== endDate.year) {
        monthString = {};
        monthString.it = som.setLocale('it-IT').toFormat('LLLL');
        monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
        monthString.en = som.setLocale('en-US').toFormat('LLLL');
        dates.push([som.toFormat("yyyy'-'LL'-'dd"), eom.toFormat("yyyy'-'LL'-'dd"), monthString]);
        som = som.plus({ month: 1 }).startOf('month');
        eom = eom.plus({ month: 1 }).endOf('month');
      }
      monthString = {};
      monthString.it = endDate.setLocale('it-IT').toFormat('LLLL');
      monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
      monthString.en = endDate.setLocale('en-US').toFormat('LLLL');
      dates.push([endDate.startOf('month').toFormat("yyyy'-'LL'-'dd"), end, monthString]);
      return dates;
    },
    async exportCsv() {
      await api.hotelHistory.downloadDetailsByRoom(this.$store.state.filter);
    },
    selectStructure(item) {
      this.$emit('selectedRoom', item);
    },
    setHeadersLang() {
      this.headers[0].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.destination');
      this.headers[1].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.name');
      this.headers[2].text = '';
      this.headers[3].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.roomType');
      this.headers[4].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.revenue');
      this.headers[5].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.adrPlus');
      this.headers[6].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.roomNights');
      this.headers[7].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.reservations');
      this.headers[8].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.occupancy');
      this.headers[9].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.revParPlus');
      this.headers[10].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.competitorMed');
      this.headers[11].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.medCompSet');
    },
  },
  data() {
    return {
      rows: [],
      search: '',
      loading: false,
      filter: null,
      progress: 0,
      total: 0,
      monthRows: {},
      monthsLoading: {},
      headers: [
        {
          text: 'Destination',
          align: 'start',
          value: 'destination',
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: '',
          align: 'start',
          value: 'expand',
          sortable: false,
          filterable: false,
          width: 40,
        },
        {
          text: 'Descrizione Camera',
          align: 'start',
          value: 'roomId',
          width: 200,
        },
        {
          text: 'Revenue',
          align: 'center',
          value: 'totalRevenue',
          width: 140,
        },
        {
          text: 'ADR',
          align: 'center',
          value: 'adrPlus',
          width: 110,
        },
        {
          text: 'Room Nights',
          align: 'center',
          value: 'roomNights',
          width: 120,
        },
        {
          text: 'Reservations',
          align: 'center',
          value: 'reservations',
          width: 125,
        },
        {
          text: 'Occupancy',
          align: 'center',
          value: 'occupancy',
          width: 120,
        },
        {
          text: 'RevPar',
          align: 'center',
          value: 'revParPlus',
          width: 95,
        },
        {
          text: 'Competitor Median Price',
          align: 'center',
          value: 'competitorAvg',
          width: 120,
        },
        {
          text: 'Media CompSet',
          align: 'center',
          value: 'suggestedVariation',
          sortable: false,
          filterable: false,
          width: 120,
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          filterable: false,
          width: 150,
        },
      ],
    };
  },
};
</script>

<style>
 .mdi-currency-eur.no-space {
   margin-left: -3px;
 }
</style>

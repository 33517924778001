<template>
  <v-footer
    app
    color="secondary darken-1"
    padless
  >
    <v-spacer />
    <div class="footer-text pr-3 py-2 text-subtitle-2">
      Powered by Premoneo
    </div>
  </v-footer>
</template>
<script>
export default {
  name: 'AppFooter',
};
</script>
<style scoped lang="scss">
.footer-text{
    color: white!important;
    font-weight: bold;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"horizontal-box",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"justify-center secondary--text pt-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4","offset-md":"4"}},[_c('v-menu',{attrs:{"origin":"center center","auto":"","value":_vm.chartSelectionOpen},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"small":"","color":"white","elevation":"0"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"font-weight-bold title secondary--text"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(_vm.currentChart.name))+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)])]}}])},[_c('v-list',_vm._l((_vm.charts),function(chart){return _c('v-list-item',{key:chart.type,attrs:{"dense":"","link":""}},[_c('v-list-item-title',{staticClass:"text-center",on:{"click":function($event){return _vm.changeChart(chart)}}},[_c('span',{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.$vuetify.lang.t(chart.name)))])])],1)}),1)],1)],1),_c('v-spacer'),(_vm.loading)?_c('v-progress-circular',{attrs:{"color":"primary","size":"24","width":"3","indeterminate":""}}):_vm._e()],1)],1),(_vm.stats)?_c('v-card-text',[(!_vm.noData)?_c('ADRBarChart',{staticClass:"chart-container",attrs:{"chart-data":_vm.stats,"options":_vm.chartOptions}}):(!_vm.loading)?_c('div',{staticClass:"chart-container no-data row"},[_c('div',{staticClass:"col align-self-center text-center"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.dashboard.noData'))+" ")])]):_vm._e(),_c('div',{staticClass:"pt-4 pl-2 d-flex"},[_c('div',{staticClass:"d-inline-flex mr-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.dashboard.groupBy'))+" ")]),_c('div',{staticClass:"d-inline-flex mr-10"},[_c('DateTypePicker',{staticClass:"d-inline",attrs:{"show-icon":true,"items":_vm.dateTypes,"text":"$vuetify.dashboard.groupBy"},on:{"change":_vm.changeGroupDate}})],1),_c('div',{staticClass:"d-inline-flex"},[_c('span',{staticClass:"align-self-center mr-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.dashboard.comparison'))+" ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"filter-text",attrs:{"label":"","color":"white"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(_vm.currentComparison.displayName))+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,644926019)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.comparisonTypes.filter(
                  function (it) {
                    return !_vm.isComparisonDisabled(it)
                  })),function(item,index){return _c('v-list-item',{key:index,staticClass:"text-center",attrs:{"disabled":_vm.isComparisonDisabled(item)},on:{"click":function($event){return _vm.selectComparison(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t(item.displayName)))])],1)}),1)],1)],1),_c('div',{staticClass:"d-inline-flex ml-auto"},[_c('v-btn',{staticClass:"text-left",attrs:{"loading":_vm.exporting,"color":"primary","fab":"","x-small":"","dark":"","elevation":"0","disabled":_vm.loading || _vm.exporting},on:{"click":_vm.exportData}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)],1)])],1):_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mt-15",attrs:{"color":"primary","indeterminate":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        color="white"
        v-bind="attrs"
        v-on="on"
        class="filter-text"
      >
        {{ $vuetify.lang.t(currentSelection.name) }}
        <v-icon v-if="showIcon">
          mdi-menu-down
        </v-icon>
      </v-chip>
    </template>
    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.type"
        dense
        link
        @click="changeSelection(item)"
      >
        <v-list-item-title>
          <span>{{ $vuetify.lang.t(item.name) }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const dateTypes = [
  {
    type: 'night',
    name: '$vuetify.dashboard.stayDate',
  },
  {
    type: 'bookingDate',
    name: '$vuetify.dashboard.bookingDate',
  },
  {
    type: 'dateUpdate',
    name: '$vuetify.dashboard.lastUpdate',
  },
];
export default {
  name: 'DateTypePicker',
  props: {
    items: {
      type: Array,
      default: () => dateTypes,
    },
    text: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeSelection(item) {
      if (item.type !== this.currentSelection.type) {
        this.currentSelection = item;
        this.$emit('change', item);
      }
    },
  },
  data() {
    return {
      currentSelection: this.items[0],
    };
  },
};
</script>

<style scoped>

</style>

<script>
import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newVal) {
        setTimeout(() => this.renderChart(this.chartData, newVal), 10);
      },
    },
    lang: {
      deep: true,
      handler(val) {
        if (val.current !== this.current) {
          this.current = val.current;
          this.renderChart(this.chartData, this.options);
        }
      },
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  data() {
    return {
      lang: this.$vuetify.lang,
      current: 'it',
    };
  },
};
</script>

<style>
</style>

<template>
  <v-container
    fluid
    class="ma-3 pa-3"
    v-if="$store.state.filter"
  >
    <v-card
      class="pa-10"
      flat
    >
      <v-row class="pb-2">
        <v-col
          md="4"
          class="pr-3"
        >
          <v-text-field
            :placeholder="$vuetify.lang.t('$vuetify.search')"
            v-model="searchText"
            dense
            append-icon="mdi-magnify"
            hide-details
            single-line
            color="secondary"
            class="px-1 py-0 text-end"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items.sync="items"
        :loading.sync="loading"
        :page.sync="page"
        :options.sync="options"
        :server-items-length="itemsCount"
        :items-per-page="options.itemsPerPage"
        :footer-props="{
          itemsPerPageOptions: [15]
        }"
      >
        <template v-slot:item.actions="{item}">
          <v-btn
            class="mx-2"
            elevation="2"
            fab
            dark
            color="primary"
            x-small
            :title="$vuetify.lang.t('$vuetify.pickup.download')"
            @click="() => downloadFile(item)"
          >
            <v-icon
              color="btn"
              small
            >
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';

const headers = [
  {
    text: 'date',
    value: 'dateCreate',
    width: '12.5%',
  },
  {
    text: 'fileName',
    value: 'fileName',
    width: '62.5%',
  },
  {
    value: 'actions',
    width: '25%',
    align: 'center',
    sortable: false,
  },
];

export default {
  name: 'PickUp',
  mounted() {
    this.fetchData();
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.options.page = 1;
        this.filter = state.filter;
      }
    }));
  },
  methods: {
    async fetchData() {
      if (this.loading && this.currentRequest) {
        this.currentRequest.cancel();
      }
      const { request, cancelToken } = api.pickup.list(this.apiParams);
      this.currentRequest = cancelToken;
      this.loading = true;
      const data = await request;
      this.updateTable(data);
      this.loading = false;
      this.currentRequest = null;
    },
    updateTable(data) {
      this.items = data.content;
      this.pageCount = data.totalPages;
      this.itemsCount = data.totalElements;
    },
    downloadFile(item) {
      const targetFileName = item.fileName.replace(/.*\//gi, '');
      api.pickup.downloadPdf(item.id, targetFileName);
    },
  },
  computed: {
    apiParams() {
      return {
        pageSize: this.options.itemsPerPage,
        pageNumber: this.options.page - 1,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0],
        searchText: this.queryString,
        from: (this.filter || {}).periodStart,
        to: (this.filter || {}).periodEnd,
      };
    },
  },
  watch: {
    searchText() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.options.page = 1;
        this.queryString = this.searchText;
      }, 500);
    },
    apiParams: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: true,
      timeout: null,
      currentRequest: null,
      items: [],
      page: 1,
      pageCount: 2,
      filter: this.$store.state.filter,
      itemsPerPage: 10,
      itemsCount: 10,
      searchText: '',
      queryString: '',
      options: {
        sortBy: ['dateCreate'],
        sortDesc: [true],
        itemsPerPage: 10,
        page: 1,
      },
      headers: headers.map((item) => (
        {
          ...item,
          text: item.text ? this.$vuetify.lang.t(`$vuetify.pickup.${item.text}`) : '',
        })),
      sortBy: 'dateCreate',
      sortDesc: false,
      sortDirection: 'ASC',
    };
  },
};
</script>

<style scoped>

</style>

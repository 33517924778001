<template>
  <v-dialog
    v-model="isOpen"
    width="950px"
  >
    <template #activator="{ on: dialog }">
      <v-tooltip
        bottom
        content-class="bar-suggestion-tooltip"
      >
        <template #activator="{ on: tooltip }">
          <v-btn
            small
            :color="suggestedChange > 0 ? 'green' : 'red'"
            elevation="0"
            v-on="{ ...tooltip, ...dialog }"
            class="mt-2"
            icon
            :disabled="suggestedChange === 0"
          >
            <v-icon
              class="text--white font-weight-bold"
            >
              {{
                suggestedChange > 0 ?
                  'mdi-arrow-up':
                  ( suggestedChange === 0 ? 'mdi-minus' : 'mdi-arrow-down')
              }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          Variazione suggerita
          <em>
            {{ currentBarName }}
            <i class="mdi mdi-arrow-right" />
            {{ suggestedBarName }}
          </em>
        </span>
      </v-tooltip>
    </template>
    <v-card style="min-height: 100px">
      <v-toolbar
        dark
        color="primary"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <ConfirmBarChange
                v-on="on"
                :disabled="false"
                @confirm="submitChanges"
                displayed-icon="mdi-swap-vertical"
              />
            </div>
          </template>
          <span>
            Applica suggerimento
          </span>
        </v-tooltip>
        <v-toolbar-title class="font-weight-bold pl-2">
          <em>
            {{ stagingChosenBar.date }}
            Variazione suggerita
            {{ currentBarName }}
            <i class="mdi mdi-arrow-right" />
            {{ suggestedBarName }}
          </em>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="isOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
        <PriceTable
          v-else
          :items="bars"
          :prepaid="currentBar.prepaid"
          :show-competitors="true"
          :new-prepaid="currentBar.prepaid"
          :start-date="stagingChosenBar.date"
          :end-date="stagingChosenBar.date"
          :competitor-prices="competitorPrices"
          :show-expand="false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmBarChange from '@/components/Pricing/ConfirmBarChange.vue';
import api from '@/api';
import hrUtils from '@/components/lib/hurdleRateUtils';
import PriceTable from '@/components/Pricing/PriceTable.vue';

export default {
  name: 'DaySuggestionDialog',
  components: { PriceTable, ConfirmBarChange },
  props: {
    stagingChosenBar: null,
    currentBar: null,
    competitorPrices: null,
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.fetchHotelBars();
      }
    },
  },
  methods: {
    barName(bar) {
      if (bar === undefined) return '';
      if (bar.idBar === 0) return 'RACK';
      return `BAR${bar.idBar}`;
    },
    sameRow(a, b) {
      return a.roomReferenceType === b.roomReferenceType;
    },
    async fetchHotelBars() {
      this.loading = true;
      const preview = await api.bars.fetchBarDetails({
        structures: [this.currentBar.idHotel],
        barId: this.stagingChosenBar.idBar,
      });
      const bars = await api.bars.fetchBarDetails({
        structures: [this.currentBar.idHotel],
        barId: this.currentBar.idBar,
      });
      await this.fetchHurdleRates();
      const mergedPrices = hrUtils.mergeHurdleRates(bars, this.hurdleRates);
      for (let i = 0; i < mergedPrices.length; i += 1) {
        for (let j = 0; j < preview.length; j += 1) {
          const current = mergedPrices[i];
          const updated = preview[j];
          if (this.sameRow(current, updated)) {
            mergedPrices[i] = {
              ...current,
              newPrice: updated.barPrice,
            };
          }
        }
      }
      this.bars = mergedPrices;
      this.loading = false;
    },
    async fetchHurdleRates() {
      this.hurdleRates = await api.hurdleRates.raws({
        structures: [this.currentBar.idHotel],
        periodStart: this.stagingChosenBar.date,
        periodEnd: this.stagingChosenBar.date,
      });
    },
    async submitChanges() {
      const body = [{
        ...this.stagingChosenBar,
        prepaid: this.currentBar.prepaid,
      }];
      await api.stagingChosenBar.update(body);
      this.isOpen = false;
      this.$emit('saved');
    },
  },
  computed: {
    suggestedChange() {
      if (!this.currentBar || !this.stagingChosenBar) return 0;
      if (this.currentBar.idBar > this.stagingChosenBar.idBar) return 1;
      if (this.currentBar.idBar < this.stagingChosenBar.idBar) return -1;
      return 0;
    },
    suggestedBarName() {
      return this.barName(this.stagingChosenBar);
    },
    currentBarName() {
      return this.barName(this.currentBar);
    },
  },
  data() {
    return {
      bars: [],
      isOpen: false,
      loading: false,
      hurdleRates: {},
    };
  },
};
</script>

<style lang="scss">
.bar-suggestion-tooltip {
  opacity: var(--v-tooltip-opacity, 1) !important;
  background: var(--v-tooltip-bg, rgba(100, 100, 100, 1)) !important;
}
</style>

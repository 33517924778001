<!-- First PopUp on FIT Rates -->
<template>
  <v-dialog
    v-model="isOpen"
    width="950px"
    :activator="selectedElement"
    @close="forwardClose()"
  >
    <v-card
      color="grey lighten-4"
      min-width="350px"
      flat
      v-if="isOpen && interval"
    >
      <v-toolbar
        :color="interval.color"
        dark
      >
        <EditRateDialog
          v-if="interval && modifiable"
          :interval="interval"
          :current-prices="fits"
          :competitor-prices="competitorPrices"
          :show-competitors="showCompetitors"
          :start-date="interval.start"
          :end-date="interval.end"
          @change="handleBarChange()"
        />
        <v-tooltip
          bottom
          v-else
          color="orange"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-3"
              style="color: rgba(255, 255, 255, 0.5)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span class="font-weight-bold">
            <i class="mdi mdi-alert" />
            {{ $vuetify.lang.t('$vuetify.pricing.roleCantModify') }}
          </span>
        </v-tooltip>
        <v-toolbar-title
          class="row no-gutters ml-0"
          :key="interval.r"
        >
          <v-col
            md="8"
            class="font-weight-bold"
          >
            <span class="font-italic">
              {{ formatDate(interval.start) }}
              <i class="mdi mdi-arrow-right" />
              {{ formatDate(interval.end) }}
            </span>
          </v-col>
          <v-spacer />
          <v-col
            md="4"
            class="text-end font-weight-bold"
          >
            <!-- <span class="mr-2">{{ interval.description }}</span> -->
            {{ $vuetify.lang.t('$vuetify.pricing.fitprepaid') }} {{ prepaid }}%
          </v-col>
        </v-toolbar-title>
        <v-btn icon>
          <v-icon @click="forwardClose()">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
        <div class="title my-4">
          {{ $vuetify.lang.t('$vuetify.pricing.rates') }}
        </div>
        <RateTable
          v-if="fits"
          :items="fits"
          :prepaid="interval.prepaid"
          :hurdle-rate="hurdles"
          :competitor-prices="competitorPrices"
          :show-competitors="showCompetitors"
          :start-date="interval.start"
          :end-date="interval.end"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import api from '@/api';
import EditRateDialog from '@/components/FitRates/EditRateDialog.vue';
import RateTable from '@/components/FitRates/RateTable.vue';
import moment from 'moment';
import hrUtils from '@/components/lib/hurdleRateUtils';

export default {
  name: 'RateDialog',
  components: { RateTable, EditRateDialog },
  props: {
    prepaid: null,
    interval: null,
    selectedElement: null,
    selectedOpen: null,
    competitorPrices: null,
    showCompetitors: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    selectedOpen(newVal) {
      this.isOpen = newVal;
      if (newVal) {
        this.fetchHotelFit();
        this.showPrices = this.competitorPrices;
      }
    },
  },
  methods: {
    forwardClose() {
      this.isOpen = false;
      this.$emit('close');
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    handleBarChange() {
      this.isOpen = false;
      this.$emit('change');
    },
    async fetchHotelFit() {
      this.loading = true;
      const fits = await api.fit.fetchFitDetails({
        start: this.interval.start,
        end: this.interval.end,
        structure: this.interval.idHotel,
      });
      await this.fetchHurdleRates();
      this.fits = hrUtils.mergeHurdleFitRates(fits, this.hurdleRates);
      this.loading = false;
    },
    async fetchHurdleRates() {
      this.hurdleRates = await api.hurdleRatesFit.raws({
        structures: [this.interval.idHotel],
        periodStart: this.interval.start,
        periodEnd: moment(this.interval.end).add(1, 'days'),
      });
      const sum = this.hurdleRates.map((h) => h.hurdleRate).reduce((a, b) => a + b, 0);
      this.hurdles = (sum / this.hurdleRates.length) || 0;
    },
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      fits: null,
      hurdles: 0,
      modifiable: this.$root.$keycloak.realmAccess.roles.indexOf('modifier') !== -1,
    };
  },
};
</script>

<style scoped>
  .v-tooltip__content.menuable__content__active {
    opacity: 1!important;
  }
</style>

<template>
  <v-dialog
    v-model="isOpen"
    width="950px"
  >
    <template
      v-slot:activator="{ on, attrs }"
      v-if="interval"
    >
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="isOpen = true"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card
      color="grey lighten-4"
      min-width="350px"
      flat
      v-if="start"
      :key="interval.r"
    >
      <v-toolbar
        :color="interval.color"
        dark
      >
        <v-toolbar-title class="row no-gutters ml-0 font-weight-bold">
          {{ $vuetify.lang.t('$vuetify.pricing.updateBar') }}
          <span class="font-italic ml-4">
            {{ interval.hotelName }}&nbsp;
            {{ formatDate(interval.start) }}
            <i class="mdi mdi-arrow-right" />
            {{ formatDate(interval.end) }}
          </span>
        </v-toolbar-title>
        <ConfirmBarChange
          :disabled="!valid"
          @confirm="updateBars()"
        />
        <v-btn icon>
          <v-icon @click="isOpen = false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form
          class="row my-4"
          v-model="valid"
        >
          <v-col md="6">
            <v-select
              :items="bars"
              label="Bar"
              v-model="bar"
              @change="fetchHotelBars"
            />
          </v-col>
          <v-col md="6">
            <v-text-field
              type="number"
              min="0"
              max="100"
              suffix="%"
              v-model="prepaid"
              label="Prepaid"
              :rules="[v => !!v && v>= 0 && v <= 100
                || $vuetify.lang.t('$vuetify.pricing.prepaidValidation') ]"
              required
              @change="computePreviewIntervals"
            />
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedStart"
                  label="from"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="start"
                :min="interval.start"
                :max="end"
                @input="startDatePicker = false"
                @change="computePreviewIntervals"
              />
            </v-menu>
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEnd"
                  label="to"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="end"
                :min="start"
                :max="interval.end"
                @input="endDatePicker = false"
                @change="computePreviewIntervals"
              />
            </v-menu>
          </v-col>
        </v-form>
        <div class="title">
          {{ $vuetify.lang.t('$vuetify.pricing.preview') }}
        </div>
        <div
          v-for="item in preview"
          :key="`price-preview-${item.id}`"
        >
          <div class="subtitle-2 mb-0 mt-4 row">
            <v-col
              md="6"
            >
              <span class="font-italic">
                {{ formatDate(item.start) }}
                <i class="mdi mdi-arrow-right" />
                {{ formatDate(item.end) }}
              </span>
            </v-col>
            <v-spacer />
            <v-col
              md="6"
              class="text-end"
            >
              <span
                class="mr-5"
                v-if="interval.idBar === item.idBar"
              >
                {{ bars.find((x) => x.value === item.idBar).text }}
              </span>
              <span
                v-else
                class="font-weight-bold mr-5"
              >
                {{ bars.find((x) => x.value === item.idBar).text }}
                <i class="mdi mdi-arrow-right" />
                {{ bars.find((x) => x.value === item.idBar).text }}
              </span>
              <span
                class="mr-2"
                v-if="interval.prepaid === item.prepaid"
              >
                {{ $vuetify.lang.t('$vuetify.pricing.prepaid') }} {{ item.prepaid }}%
              </span>
              <span
                v-else
                class="font-weight-bold"
              >
                {{ $vuetify.lang.t('$vuetify.pricing.prepaid') }} {{ interval.prepaid }}%
                <i class="mdi mdi-arrow-right" />
                {{ item.prepaid }}%
              </span>
            </v-col>
          </div>
          <PriceTable
            v-if="item.prices"
            :items="item.prices"
            :new-prepaid="item.prepaid"
            :prepaid="interval.prepaid"
            :competitor-prices="competitorPrices"
            :show-competitors="showCompetitors"
            :start-date="item.start"
            :end-date="item.end"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import PriceTable from '@/components/Pricing/PriceTable.vue';
import api from '@/api';
import moment from 'moment';
import ConfirmBarChange from '@/components/Pricing/ConfirmBarChange.vue';
import hrUtils from '@/components/lib/hurdleRateUtils';

const bars = [
  { value: 0, text: 'RACK' },
  { value: 1, text: 'BAR1' },
  { value: 2, text: 'BAR2' },
  { value: 3, text: 'BAR3' },
  { value: 4, text: 'BAR4' },
  { value: 5, text: 'BAR5' },
  { value: 6, text: 'BAR6' },
  { value: 7, text: 'BAR7' },
  { value: 8, text: 'BAR8' },
  { value: 9, text: 'BAR9' },
  { value: 10, text: 'BAR10' },
  { value: 20, text: 'RACK - 2023' },
  { value: 21, text: 'BAR1 - 2023' },
  { value: 22, text: 'BAR2 - 2023' },
  { value: 23, text: 'BAR3 - 2023' },
  { value: 24, text: 'BAR4 - 2023' },
  { value: 25, text: 'BAR5 - 2023' },
  { value: 26, text: 'BAR6 - 2023' },
  { value: 27, text: 'BAR7 - 2023' },
  { value: 28, text: 'BAR8 - 2023' },
  { value: 29, text: 'BAR9 - 2023' },
  { value: 30, text: 'BAR10 - 2023' },
  { value: 31, text: 'BAR11 - 2023' },
];
export default {
  name: 'EditBarDialog',
  components: { ConfirmBarChange, PriceTable },
  props: {
    interval: null,
    currentPrices: null,
    competitorPrices: null,
    startDate: null,
    endDate: null,
    showCompetitors: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    window.moment = moment;
    this.bars = [...bars];
    this.start = this.interval.start;
    this.end = this.interval.end;
    this.hotelName = this.interval.hotelName;
    this.bar = this.interval.idBar;
    this.prepaid = this.interval.prepaid;
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.start = this.interval.start;
        this.end = this.interval.end;
        this.hotelName = this.interval.hotelName;
        this.bar = this.interval.idBar;
        this.prepaid = this.interval.prepaid;
        this.fetchHotelBars();
      }
    },
    currentPrices: {
      handler(val) {
        this.updatedPrices = val.map((i) => ({ ...i }));
        this.computePreviewIntervals();
      },
      deep: true,
    },
  },
  computed: {
    formattedStart() {
      return this.formatDate(this.start);
    },
    formattedEnd() {
      return this.formatDate(this.end);
    },
    rates() {
      return (this.currentPrices || []).flatMap((bar) => bar.hurdleRates || []);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async updateBars() {
      this.isOpen = false;
      try {
        await api.bars.updateBars({
          idHotel: this.interval.idHotel,
          from: this.start,
          to: this.end,
          barId: this.bar,
          prepaid: this.prepaid,
        });
        this.$emit('change');
      } catch (e) {
        this.$log.info(e);
      }
    },
    sameRow(a, b) {
      return a.roomId === b.roomId
      && a.roomFeature === b.roomFeature
      && a.roomReferenceService === b.roomReferenceService
      && a.cancellation === b.cancellation;
    },
    async fetchAvailableBars() {
      const availableBars = await api.bars.availableBars(this.interval.idHotel);
      const cloned = availableBars.map((e) => {
        if (e.idBar >= 20) {
          return {
            value: e.idBar,
            text: (e.idBar === 20 ? 'RACK - 2023' : `BAR${e.idBar - 20} - 2023`),
          };
        }
        return {
          value: e.idBar,
          text: (e.idBar === 0 ? 'RACK' : `BAR${e.idBar}`),
        };
      });
      this.bars = [];
      const values = [];
      cloned.forEach((x) => {
        if (!values.includes(x.value)) {
          values.push(x.value);
          this.bars.push(x);
        }
      });
    },
    async fetchHotelBars() {
      this.loading = true;
      await this.fetchAvailableBars();
      const preview = await api.bars.fetchBarDetails({
        structures: [this.interval.idHotel],
        barId: this.bar,
      });
      const mergedPrices = hrUtils.mergeHurdleRates(this.currentPrices, this.rates);
      for (let i = 0; i < mergedPrices.length; i += 1) {
        for (let j = 0; j < preview.length; j += 1) {
          const current = mergedPrices[i];
          const updated = preview[j];
          if (this.sameRow(current, updated)) {
            mergedPrices[i] = { ...current, newPrice: updated.barPrice };
          }
        }
      }
      this.updatedPrices = mergedPrices;
      this.computePreviewIntervals();
      this.loading = false;
    },
    computePreviewIntervals() {
      if (!this.interval || (!this.bar && this.bar !== 0)) return;
      const data = [];
      if (this.interval.start !== this.start && this.interval.end === this.end) {
        data.push({
          ...this.interval,
          end: moment(this.start).subtract(1, 'days').format('YYYY-MM-DD'),
          prices: hrUtils.mergeHurdleRates(
            this.currentPrices,
            this.rates,
            this.interval.start,
            moment(this.start).subtract(1, 'days'),
          ),
          id: Math.random(),
        });
        data.push({
          ...this.interval,
          start: this.start,
          idBar: this.bar,
          prepaid: this.prepaid,
          prices: hrUtils.mergeHurdleRates(
            this.updatedPrices,
            this.rates,
            this.start,
            this.interval.end,
          ),
          id: Math.random(),
        });
      } else if (this.interval.end !== this.end && this.interval.start === this.start) {
        data.push({
          ...this.interval,
          end: this.end,
          idBar: this.bar,
          prepaid: this.prepaid,
          prices: hrUtils.mergeHurdleRates(
            this.updatedPrices,
            this.rates,
            this.interval.start,
            this.end,
          ),
          id: Math.random(),
        });
        data.push({
          ...this.interval,
          start: moment(this.end).add(1, 'days').format('YYYY-MM-DD'),
          prices: hrUtils.mergeHurdleRates(
            this.currentPrices,
            this.rates,
            moment(this.end).add(1, 'days'),
            this.interval.end,
          ),
          id: Math.random(),
        });
      } else if (this.interval.end !== this.end && this.interval.start !== this.start) {
        data.push({
          ...this.interval,
          end: moment(this.start).subtract(1, 'days').format('YYYY-MM-DD'),
          prices: hrUtils.mergeHurdleRates(
            this.currentPrices,
            this.rates,
            this.interval.start,
            moment(this.start).subtract(1, 'days'),
          ),
          id: Math.random(),
        });
        data.push({
          idBar: this.bar,
          prepaid: this.prepaid,
          prices: hrUtils.mergeHurdleRates(
            this.updatedPrices,
            this.rates,
            this.start,
            this.end,
          ),
          start: this.start,
          end: this.end,
          id: Math.random(),
        });
        data.push({
          ...this.interval,
          start: moment(this.end).add(1, 'days').format('YYYY-MM-DD'),
          prices: hrUtils.mergeHurdleRates(
            this.currentPrices,
            this.rates,
            moment(this.end).add(1, 'days'),
            this.interval.end,
          ),
          id: Math.random(),
        });
      } else {
        data.push({
          ...this.interval,
          idBar: this.bar,
          prepaid: this.prepaid,
          prices: (this.bar === this.interval.bar && this.prepaid === this.bar.interval)
            ? this.currentPrices
            : this.updatedPrices,
          id: Math.random(),
        });
      }
      this.preview = data;
    },
  },
  data() {
    return {
      preview: [],
      updatedPrices: [],
      start: null,
      end: null,
      hotelName: null,
      bar: null,
      prepaid: null,
      bars,
      loading: false,
      isOpen: false,
      startDatePicker: false,
      endDatePicker: false,
      valid: true,
    };
  },
};
</script>

<style scoped>

</style>

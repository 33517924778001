var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"950px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"text-right"},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pricing.globalFitChange'))+" ")])])],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"row no-gutters ml-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pricing.updateFit'))+" ")]),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" mdi-close ")])],1)],1),(!_vm.loading)?_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"row my-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$vuetify.lang.t('$vuetify.pricing.from'),"value":_vm.formattedStart,"prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v; }]}},'v-text-field',attrs,false),on))]}}],null,false,1958390000),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"max":_vm.end},on:{"input":function($event){_vm.startDatePicker = false}},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedEnd,"label":_vm.$vuetify.lang.t('$vuetify.pricing.to'),"prepend-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v; }]}},'v-text-field',attrs,false),on))]}}],null,false,716734450),model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}},[_c('v-date-picker',{attrs:{"min":_vm.start},on:{"input":function($event){_vm.endDatePicker = false}},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"6"}},[_c('v-select',{attrs:{"items":_vm.hotels,"item-text":"name","item-value":"id","multiple":"","label":"Hotels"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.name)+" ")])]}}],null,false,724518950),model:{value:(_vm.chosenHotels),callback:function ($$v) {_vm.chosenHotels=$$v},expression:"chosenHotels"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}}),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('v-select',{attrs:{"items":_vm.fits,"multiple":"","label":"Fit"},model:{value:(_vm.chosenFits),callback:function ($$v) {_vm.chosenFits=$$v},expression:"chosenFits"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","max":"100","suffix":"%","label":_vm.$vuetify.lang.t('$vuetify.pricing.updatePromo'),"required":"","persistent-hint":""},model:{value:(_vm.newRate),callback:function ($$v) {_vm.newRate=$$v},expression:"newRate"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"my-0 py-0 mr-2",attrs:{"dense":"","hide-details":"auto","label":_vm.$vuetify.lang.t('$vuetify.pricing.fitinvariable')},model:{value:(_vm.disableClosed),callback:function ($$v) {_vm.disableClosed=$$v},expression:"disableClosed"}}),_c('v-switch',{staticClass:"ma-0 red-off-state",attrs:{"inset":"","dense":"","disabled":_vm.disableClosed,"ripple":false,"color":false ? 'red' : 'primary'},model:{value:(_vm.closed),callback:function ($$v) {_vm.closed=$$v},expression:"closed"}}),(!_vm.closed)?_c('span',{staticClass:"my-2 v-label"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pricing.closeRate'))+" ")]):_c('span',{staticClass:"my-2 v-label"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.pricing.openRate'))+" ")])],1),_c('v-col',{staticClass:"text-center mt-4",attrs:{"md":"12"}},[_c('ConfirmBarChange',{attrs:{"disabled":!_vm.valid,"icon":false},on:{"confirm":_vm.save}})],1)],1)],1):_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- Column with data type, ComparisonValue and comparison selector -->
<template>
  <v-card
    color="white"
    flat
    class="px-0 metric-card pt-3 pb-1 col col-12 px-0 container--fluid fill-height"
  >
    <v-container
      fill-height
      class="pa-0"
      style="position: relative;"
    >
      <v-row
        justify="center"
        align="center"
        class="pa-0"
      >
        <v-col
          cols="12"
          class="pa-0"
        >
          <div
            class="text-body-1 metric-name text-center justify-center
      font-weight-bold  kpitext--text pt-0"
          >
            {{ $vuetify.lang.t(title) }}
          </div>
          <div
            v-if="!loading"
            class="pa-0 text-center metric-text
            text-md-subtitle-2 font-weight-bold  kpitext--text justify-center"
          >
            <div
              v-if="currentComparison.type === 'actual'"
            >
              <span v-if="!value">
                <v-icon class="justify-center">mdi-minus</v-icon>
              </span>
              <span v-else>
                {{
                  fixedDigits(value, currency || !roundDigits ? 2: 0)
                    .toLocaleString($vuetify.lang.current)
                }}
                <i
                  v-if="currency"
                  class="mdi mdi-currency-eur mr-0"
                  style="margin-left: -5px"
                />
              </span>
            </div>
            <ComparisonValue
              v-else
              :currency="currency"
              :comparison-icon="currentComparison.icon"
              :comparison-value="
                currentComparison.type === 'historical' ? historicalValue :
                currentComparison.type === 'consolidato' ? consolidatedValue :
                currentComparison.type === 'budget' ? budgetValue :
                forecastValue"
              :trend-sign="currentComparison.type === 'historical' ? 1 : -1"
              :round-digits="roundDigits"
              :value="value"
              :consolid="currentComparison.type == 'consolidato'"
            />
          </div>
          <div
            v-else
            class="text-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions
      class="pa-0 metric-card-actions text-center"
      v-if="!loading"
    >
      <v-menu
        offset-y
        class="text-center"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            label
            color="white"
            v-bind="attrs"
            v-on="on"
          >
            <i :class="`mdi ${currentComparison.icon} mr-1`" />
            {{ $vuetify.lang.t(currentComparison.displayName) }}
            <v-icon>mdi-menu-down</v-icon>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item
            class="text-center"
            v-for="(item, index) in comparisonTypes"
            :key="index"
            :disabled="isDisabled(item)"
            @click="currentComparison = item"
          >
            <v-list-item-title>
              <i :class="`mdi ${item.icon} mr-1`" />
              {{ $vuetify.lang.t(item.displayName) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
  </v-card>
</template>

<script>
import chartUtil from '@/components/lib/chartUtil';
import ComparisonValue from '@/components/Dashboard/metrics/ComparisonValue.vue';

const comparisonTypes = [
  {
    type: 'actual',
    displayName: '$vuetify.dashboard.actual',
    disabled: false,
    icon: 'mdi-update',
  },
  {
    type: 'historical',
    displayName: '$vuetify.dashboard.historical',
    disabled: false,
    icon: 'mdi-calendar-multiple',
  },
  {
    type: 'forecast',
    displayName: '$vuetify.dashboard.forecast',
    disabled: false,
    icon: 'mdi-chart-areaspline',
  },
  {
    type: 'budget',
    displayName: '$vuetify.dashboard.budget',
    disabled: false,
    icon: 'mdi-currency-usd',
  },
  {
    type: 'consolidato',
    displayName: '$vuetify.dashboard.consolidated',
    disabled: false,
    icon: 'mdi-filter',
  },
];

export default {
  name: 'MetricCard',
  components: { ComparisonValue },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: null,
    historicalValue: null,
    forecastValue: null,
    consolidatedValue: null,
    budgetValue: null,
    enableForecast: {
      type: Boolean,
      default: false,
    },
    enableConsolidated: {
      type: Boolean,
      default: false,
    },
    enableBudget: {
      type: Boolean,
      default: false,
    },
    roundDigits: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    fixedDigits: chartUtil.approximateNumber,
    isDisabled(item) {
      if (item.type === 'historical') {
        return !this.historicalValue === undefined;
      }
      if (item.type === 'forecast') {
        return !this.enableForecast;
      }
      if (item.type === 'budget') {
        return !this.enableBudget;
      }
      if (item.type === 'consolidato') {
        return !this.enableConsolidated;
      }
      return item.disabled;
    },
  },
  data() {
    return {
      comparisonTypes,
      currentComparison: comparisonTypes[0],
    };
  },
};
</script>

<style lang="scss">
.metric-card {
  position: relative;
  height: 100%;
  min-height: 156px;
}
.metric-card-actions {
  position: absolute;
  bottom: 12px;
  width: 100%;
  &>span {
    margin-left: auto;
    margin-right: auto;
  }
}

.metric-name {
  word-break: break-word;
  text-align: center;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
</style>

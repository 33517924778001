<template>
  <v-data-table
    class="mb-4"
    :headers="headers"
    :items="items"
    item-key="roomId"
    hide-default-header
    :show-expand="showExpand"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <RoomHurdleRates
          :hurdle-rates="item.hurdleRates"
          :competitor-prices="competitorPrices &&
            competitorPrices[item.roomId]? competitorPrices[item.roomId] : []"
          :show-competitors="showCompetitors"
        />
      </td>
    </template>
    <template v-slot:item.avgHr="{ item }">
      <HurdleRateSkittle
        :value="fixedDigits( item.avgHr, 2)"
        :disabled="true"
      />
    </template>
    <template v-slot:item.barPrice="{ item }">
      <span v-if="!item.newPrice">
        {{ item.barPrice }} <i class="mdi mdi-currency-eur" />
      </span>
      <span
        v-else
        class="font-weight-bold"
      >
        {{ item.barPrice }} <i class="mdi mdi-currency-eur" />
        <i class="mdi mdi-arrow-right" />
        {{ item.newPrice }} <i class="mdi mdi-currency-eur" />
      </span>
    </template>
    <template v-slot:item.prepaid="{ item }">
      <span v-if="!item.newPrice && (!newPrepaid || newPrepaid === prepaid)">
        {{ fixedDigits((prepaid/100.0) * item.barPrice, 2) }}
        <i class="mdi mdi-currency-eur" />
      </span>
      <span
        v-else
        class="font-weight-bold"
      >
        {{ fixedDigits((prepaid/100.0) * item.barPrice, 2) }}
        <i class="mdi mdi-currency-eur" />
        <i class="mdi mdi-arrow-right" />
        {{ fixedDigits((newPrepaid/100.0) * item.newPrice, 2) }}
        <i class="mdi mdi-currency-eur" />
      </span>
    </template>
    <template v-slot:item.rate="{ item }">
      <span v-if="!item.newPrice && (!newPrepaid || newPrepaid === prepaid)">
        {{ fixedDigits((1 - prepaid/100.0) * item.barPrice, 2) }}
        <i class="mdi mdi-currency-eur" />
      </span>
      <span
        v-else
        class="font-weight-bold"
      >
        {{ fixedDigits((1- prepaid/100.0) * item.barPrice, 2) }}
        <i class="mdi mdi-currency-eur" />
        <i class="mdi mdi-arrow-right" />
        {{ fixedDigits((1 - newPrepaid/100.0) * item.newPrice, 2) }}
        <i class="mdi mdi-currency-eur" />
      </span>
    </template>
    <template v-slot:item.compset="{ item }">
      <AvgCompetitorPriceSkittle
        v-if="avgByRoom[item.roomId]"
        :value="avgByRoom[item.roomId].avg"
      />
      <AvgCompetitorPriceSkittle v-else />
    </template>
    <template v-slot:header="{ props: { headers } }">
      <tr>
        <th
          style="border-bottom: 1px solid rgba(0,0,0,0.12)"
          :class="`${i === 0? 'text-left': 'text-center'} pa-3`"
          v-for="(header, i) in headers"
          :key="`pth-${i}`"
          :width="header.width||''"
        >
          {{ $vuetify.lang.t(header.text) }}
        </th>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import chartUtil from '@/components/lib/chartUtil';
import RoomHurdleRates from '@/components/Pricing/RoomHurdleRates.vue';
import HurdleRateSkittle from '@/components/Pricing/skittles/HurdleRateSkittle.vue';
import AvgCompetitorPriceSkittle from '@/components/Pricing/skittles/AvgCompetitorPriceSkittle.vue';
import moment from 'moment';

const tableHeaders = [
  {
    text: '$vuetify.hurdleRate.roomType',
    value: 'roomId',
    width: '19%',
  },
  {
    text: '$vuetify.hurdleRate.avgHurdleRate',
    value: 'avgHr',
    align: 'center',
    width: '19%',
  },
  {
    text: '$vuetify.dashboard.compset',
    value: 'compset',
    align: 'center',
    width: '19%',
  },
  {
    text: '$vuetify.pricing.price',
    value: 'barPrice',
    align: 'center',
    width: '19%',
  },
  {
    text: '$vuetify.pricing.rate',
    value: 'rate',
    align: 'center',
    width: '19%',
  },
  {
    text: '',
    value: 'data-table-expand',
  },
];

export default {
  name: 'PriceTable',
  components: { AvgCompetitorPriceSkittle, HurdleRateSkittle, RoomHurdleRates },
  props: {
    items: null,
    prepaid: null,
    newPrepaid: null,
    competitorPrices: null,
    startDate: null,
    endDate: null,
    showExpand: {
      type: Boolean,
      default: () => true,
    },
    showCompetitors: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    items: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
    showCompetitors(val) {
      if (val) {
        this.headers = [...tableHeaders];
      } else {
        this.headers = [...tableHeaders];
        this.headers.splice(3, 1);
      }
    },
  },
  mounted() {
    const avgByRoom = {};
    if (!this.showCompetitors) return;
    const range = [
      moment(this.startDate).subtract(1, 'day'),
      moment(this.endDate).add(1, 'day'),
    ];
    Object.entries(this.competitorPrices).forEach(([key, s]) => {
      if (!avgByRoom[key]) avgByRoom[key] = { sum: 0, count: 0 };
      Object.entries(s).forEach(([day, price]) => {
        const inrange = moment(day).isBetween(range[0], range[1]);
        if (inrange) {
          avgByRoom[key].sum += price;
          avgByRoom[key].count += 1;
        }
      });
      avgByRoom[key].avg = this.fixedDigits(avgByRoom[key].sum / avgByRoom[key].count, 2);
    });
    this.avgByRoom = avgByRoom;
  },
  methods: {
    fixedDigits: chartUtil.approximateNumber,
  },
  data() {
    const headers = [...tableHeaders];
    if (!this.showCompetitors) {
      headers.splice(3, 1);
    }
    return {
      headers,
      avgByRoom: [],
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <v-dialog
    v-model="isOpen"
    width="950px"
  >
    <template
      v-slot:activator="{ on, attrs }"
    >
      <v-list-item
        v-bind="attrs"
        v-on="on"
        class="text-right"
      >
        <v-list-item-title>
          <span>
            {{ $vuetify.lang.t('$vuetify.pricing.globalFitChange') }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card
      color="grey lighten-4"
      min-width="350px"
      flat
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="row no-gutters ml-0 font-weight-bold">
          {{ $vuetify.lang.t('$vuetify.pricing.updateFit') }}
        </v-toolbar-title>
        <v-btn icon>
          <v-icon @click="isOpen = false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="!loading">
        <v-form
          ref="form"
          class="row my-4"
          v-model="valid"
        >
          <v-col md="6">
            <v-menu
              v-model="startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.pricing.from')"
                  :value="formattedStart"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v]"
                />
              </template>
              <v-date-picker
                v-model="start"
                :max="end"
                @input="startDatePicker = false"
              />
            </v-menu>
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEnd"
                  :label="$vuetify.lang.t('$vuetify.pricing.to')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v]"
                />
              </template>
              <v-date-picker
                v-model="end"
                :min="start"
                @input="endDatePicker = false"
              />
            </v-menu>
          </v-col>
          <v-col
            md="6"
            class="pt-0"
          >
            <v-select
              :items="hotels"
              item-text="name"
              item-value="id"
              multiple
              label="Hotels"
              v-model="chosenHotels"
            >
              <template v-slot:selection="{ item }">
                <v-chip>{{ item.name }} </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col
            md="6"
            class="py-0"
          />
          <v-col
            md="6"
            class="py-0"
          >
            <v-select
              :items="fits"
              multiple
              label="Fit"
              v-model="chosenFits"
            />
          </v-col>
          <v-col
            md="6"
            class="py-0"
          >
            <v-text-field
              type="number"
              min="0"
              max="100"
              suffix="%"
              v-model="newRate"
              :label="$vuetify.lang.t('$vuetify.pricing.updatePromo')"
              required
              persistent-hint
            />
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-checkbox
              dense
              hide-details="auto"
              class="my-0 py-0 mr-2"
              :label="$vuetify.lang.t('$vuetify.pricing.fitinvariable')"
              v-model="disableClosed"
            />
            <v-switch
              v-model="closed"
              inset
              dense
              class="ma-0 red-off-state"
              :disabled="disableClosed"
              :ripple="false"
              :color="false ? 'red' : 'primary'"
            />
            <span class="my-2 v-label" v-if="!closed">
              {{ $vuetify.lang.t('$vuetify.pricing.closeRate') }}
            </span>
            <span class="my-2 v-label" v-else>
              {{ $vuetify.lang.t('$vuetify.pricing.openRate') }}
            </span>
          </v-col>
          <v-col
            md="12"
            class="text-center mt-4"
          >
            <ConfirmBarChange
              :disabled="!valid"
              :icon="false"
              @confirm="save"
            />
          </v-col>
        </v-form>
      </v-card-text>
      <v-progress-linear
        v-else
        indeterminate
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmBarChange from '@/components/Pricing/ConfirmBarChange.vue';
import moment from 'moment';
import api from '@/api';

const fits = [
  { value: 0, text: 'FIT 20' },
  { value: 1, text: 'FIT 23' },
  { value: 2, text: 'FIT 25' },
];
export default {
  name: 'GlobalFitChangeDialog',
  components: { ConfirmBarChange },
  props: {
    idHotel: {
      type: Number,
      required: true,
    },
    hotels: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedStart() {
      if (!this.start) return null;
      return this.formatDate(this.start);
    },
    formattedEnd() {
      if (!this.end) return null;
      return this.formatDate(this.end);
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.fetchAvailableBars();
      }
    },
  },
  mounted() {
    this.chosenHotels = [this.idHotel];
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async fetchAvailableBars() {
      this.loading = true;
      // FIXME bars listing missing
      // const availableBars = await api.bars.availableBars(this.idHotel);
      this.fits = fits;
      this.loading = false;
    },
    async save() {
      // eslint-disable-next-line no-nested-ternary
      const closedValue = this.disableClosed ? 2 : (this.closed ? 0 : 1);
      const data = {
        from: this.start,
        to: this.end,
        rates: this.fits.map((f) => f.text),
        hotelIds: this.chosenHotels,
        rate: this.newRate,
        closed: closedValue,
      };
      await api.fit.updateGlobalFits(data);
      this.isOpen = false;
      this.$refs.form.reset();
      this.start = null;
      this.end = null;
      this.newRate = null;
      this.closed = false;
      this.chosenHotels = [this.idHotel];
      this.chosenFits = [0, 1, 2];
      this.fits = [];
      this.updateBar = false;
      this.updatePrepaid = false;
      this.$emit('change');
    },
  },
  data() {
    return {
      isOpen: false,
      valid: false,
      start: null,
      closed: true,
      disableClosed: true,
      end: null,
      chosenHotels: [],
      chosenFits: [0, 1, 2],
      updateBar: false,
      newRate: null,
      fits: [],
      loading: false,
      startDatePicker: false,
      endDatePicker: false,
    };
  },
};
</script>

<style scoped>

</style>

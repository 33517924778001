<template>
  <v-toolbar
    color="secondary darken-1"
    class="align-center"
    v-show="$route.name !== 'Pricing' && $route.name !== 'FIT Rates' "
  >
    <v-row v-if="globalIntervals && roomTypes && hotels">
      <v-col class="cols-8 pl-0 pr-3">
        <DateTypePicker
          text="$vuetify.dashboard.filterBy"
          @change="updateDateFilter"
        />
      </v-col>
      <v-col class="cols-8 pl-0 pr-3">
        <PeriodPicker
          :global-intervals="globalIntervals"
          @change="updatePeriodFilter"
          @start="initPeriodFilter"
        />
      </v-col>
      <v-col class="cols-8 pl-0 pr-3">
        <StructurePicker
          :choices="hotels"
          @change="updateStructureFilter"
        />
      </v-col>
      <v-col class="cols-8 pl-0 pr-3">
        <RoomTypePicker
          ref="roomTypePicker"
          :choices="roomTypes"
          @change="updateRoomTypesFilter"
        />
      </v-col>
      <v-col class="cols-8 pl-0 pr-3">
        <GenericMultiValuePicker
          :items="availableRoomServices"
          label="$vuetify.filterBar.roomService"
          field="roomServices"
          @change="updateRoomServicesFilter"
        />
      </v-col>
      <v-col class="cols-8 pl-0 pr-3">
        <RatePicker
          ref="ratePicker"
          :items="availableRates"
          @change="updateRatesFilter"
        />
      </v-col>
      <!--    <v-col class="cols-7 pl-0 pr-3">-->
      <!--      <GenericMultiValuePicker-->
      <!--        :items="availableCancellations"-->
      <!--        label="$vuetify.filterBar.cancellation"-->
      <!--        @change="updateCancellationFilter"-->
      <!--      />-->
      <!--    </v-col>-->
      <v-col class="cols-8 pl-0 pr-3">
        <ChannelPicker
          :db-values="availableChannels"
          @change="updateChannelFilter"
        />
      </v-col>
      <v-col class="cols-8 pl-0 pr-3">
        <StatusPicker
          @change="updateStatusFilter"
          @start="initStatusFilter"
        />
      </v-col>
    </v-row>
  </v-toolbar>
</template>
<script>
import api from '@/api';
import PeriodPicker from '@/components/main/filter_bar_components/PeriodPicker.vue';
import StructurePicker from '@/components/main/filter_bar_components/StructurePicker.vue';
import RoomTypePicker from '@/components/main/filter_bar_components/RoomTypePicker.vue';
import RatePicker from '@/components/main/filter_bar_components/RatePicker.vue';
import StatusPicker from '@/components/main/filter_bar_components/StatusPicker.vue';
import GenericMultiValuePicker
  from '@/components/main/filter_bar_components/GenericMultiValuePicker.vue';
import DateTypePicker from '@/components/main/filter_bar_components/DateTypePicker.vue';
import moment from 'moment';
import ChannelPicker from '@/components/main/filter_bar_components/ChannelPicker.vue';

const groupBy = (list, keyGetter, childrenBuilder, unique = false) => {
  const map = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  let id = 0;
  return Object.entries(map)
    .map((s) => {
      const name = s[0];
      const group = s[1];
      const children = group
        .map((item) => ({ ...childrenBuilder(item) }))
        .reduce((acc, current) => {
          const x = acc.find((item) => item[unique] === current[unique]);
          if (!x) {
            return acc.concat([current]);
          }
          return acc;
        }, [])
        // eslint-disable-next-line no-plusplus
        .map((o) => ({ id: ++id, ...o }));
      return {
        // eslint-disable-next-line no-plusplus
        id: ++id,
        children,
        name,
      };
    });
};
export default {
  name: 'FilterBar',
  components: {
    ChannelPicker,
    DateTypePicker,
    GenericMultiValuePicker,
    RatePicker,
    RoomTypePicker,
    StructurePicker,
    PeriodPicker,
    StatusPicker,
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.filter = state.filter;
      }
    }));
  },
  mounted() {
    this.loadGlobalStats();
    this.loadRates();
    this.loadHotels();
  },
  methods: {
    async loadGlobalStats() {
      const data = await api.filters.historyStats();
      this.globalIntervals = data.globalIntervals;
      this.availableChannels = data.channels;
      this.availableRoomServices = data.roomServices;
      this.availableCancellations = data.cancellations;
      this.availableStatuses = data.statuses.map((value) => ({
        value,
        text: `$vuetify.filterBar.statuses.${value}`,
      }));
      this.availableRoomTypes = Object.entries(data.rooms)
        .map((x) => ({ displayName: x[0], value: x[1], leaf: true }))
        .sort((a, b) => (a.displayName || '').localeCompare(b.displayName));
      this.roomTypes = [...this.availableRoomTypes];
    },
    async loadHotels() {
      const data = await api.hotels.listAll();
      this.hotels = groupBy(
        data,
        (hotel) => hotel.region,
        (hotel) => ({ id_hotel: hotel.id, name: hotel.name }),
        'id_hotel',
      );
    },
    async loadRates() {
      const data = await api.filters.listAllRates();
      const keys = Object.keys(data).sort().filter((k) => !['Static', 'Altro'].includes(k));
      keys.push('Altro');
      keys.push('Static');
      this.availableRates = keys.map((key) => ({
        displayName: key,
        ratesList: data[key],
      }));
    },
    initPeriodFilter(data) {
      const periodStart = data.period && data.period[0]
        ? moment(data.period[0]).format('YYYY-MM-DD')
        : null;
      const periodEnd = data.period && data.period[1]
        ? moment(data.period[1]).format('YYYY-MM-DD')
        : null;
      this.filter = {
        ...this.filter,
        periodStart,
        periodEnd,
      };
      this.filtersToStart -= 1;
      if (this.filtersToStart <= 0) this.$store.commit('filterChange', this.filter);
    },
    initStatusFilter(data) {
      this.filter = {
        ...this.filter,
        statuses: data,
      };
      this.filtersToStart -= 1;
      if (this.filtersToStart <= 0) this.$store.commit('filterChange', this.filter);
    },
    updatePeriodFilter(data) {
      const periodStart = data.period && data.period[0]
        ? moment(data.period[0]).format('YYYY-MM-DD')
        : null;
      const periodEnd = data.period && data.period[1]
        ? moment(data.period[1]).format('YYYY-MM-DD')
        : null;
      this.filter = {
        ...this.filter,
        periodStart,
        periodEnd,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateDateFilter(data) {
      this.filter = {
        ...this.filter,
        filterDate: data.type,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateStructureFilter(data) {
      this.filter = {
        ...this.filter,
        roomTypes: [],
        structures: data.map((item) => item.id_hotel),
      };
      this.$store.commit('filterChange', this.filter);
      this.$root.$emit('asyncRoomChange', []);
      if (this.filter.structures.length > 0) {
        this.roomTypes = this.availableRoomTypes.filter((item) => {
          let found = false;
          for (let i = 0; i < item.value.length; i += 1) {
            if (this.filter.structures.indexOf(item.value[i].idHotel) >= 0) {
              found = true;
              break;
            }
          }
          return found;
        });
      } else {
        this.roomTypes = [...this.availableRoomTypes];
      }
    },
    updateRoomTypesFilter(data) {
      this.filter = {
        ...this.filter,
        roomTypes: data,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateRatesFilter(data) {
      if (data.length > 0 && !(this.filter.roomServices || []).includes('Rooms')) this.filter.roomServices.push('Rooms');
      this.filter = {
        ...this.filter,
        rates: data,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateRoomServicesFilter(data) {
      if (data.length === 0) this.filter.rates = [];
      this.filter = {
        ...this.filter,
        roomServices: data,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateCancellationFilter(data) {
      this.filter = {
        ...this.filter,
        cancellations: data,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateChannelFilter(data) {
      this.filter = {
        ...this.filter,
        channels: data,
      };
      this.$store.commit('filterChange', this.filter);
    },
    updateStatusFilter(data) {
      this.filter = {
        ...this.filter,
        statuses: data,
      };
      this.$store.commit('filterChange', this.filter);
    },
  },
  data() {
    return {
      hotels: null,
      rawRoomTypes: null,
      roomTypes: null,
      availableRoomTypes: null,
      globalIntervals: null,
      availableChannels: null,
      availableRoomServices: null,
      availableStatuses: null,
      availableRates: null,
      availableCancellations: null,
      filtersToStart: 2,
      filter: {
        dateMode: null,
        periodStart: null,
        periodEnd: null,
        structures: [],
        roomTypes: [],
        roomServices: [],
        rates: [],
        cancellations: [],
        channels: [],
        statuses: [],
      },
    };
  },
};
</script>

<style lang="scss">
.cols-7{
  width: 14.285%;
  min-width: 14.285%;
}
.filter-text {
  text-transform: capitalize!important;
  width: 100%!important;
}
</style>

<!-- Pie chart with channels % share data -->
<template>
  <v-card
    color="white"
    flat
    class="pt-3 pb-3"
  >
    <v-card-title
      class=" text-body-1 justify-center
        metric-name
        font-weight-bold kpitext--text py-0 px-2 mb-2"
    >
      Revenue By Channel
    </v-card-title>
    <div
      class="pa-5 text-center"
      ref="chart"
      style="max-height: 140px;"
    >
      <v-progress-circular
        v-if="!chartData "
        indeterminate
        class="mt-9"
        color="primary"
      />
      <PieChart
        style="max-height: 90%;"
        v-else
        :chart-data="chartData"
        :options="options"
      />
    </div>
  </v-card>
</template>

<script>
import PieChart from '@/components/Dashboard/charts/PieChart.vue';
import api from '@/api';
import colors from '@/plugins/colors';
import chartUtil from '@/components/lib/chartUtil';

export default {
  name: 'DashboardPie',
  components: { PieChart },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange'
          && JSON.stringify(this.filter) !== JSON.stringify(state.filter)) {
        this.filter = state.filter;
        this.loadData();
      }
    }));
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted() {
    this.filter = this.$store.state.filter;
    this.loadData();
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    fixedDigits: chartUtil.approximateNumber,
    resizeHandler() {
      if (window.innerWidth < 1360) {
        this.$refs.chart.style.maxHeight = '200px';
        setTimeout(() => { this.options.legend.position = 'bottom'; }, 5);
      } else {
        this.$refs.chart.style.maxHeight = '140px';
        setTimeout(() => { this.options.legend.position = 'right'; }, 5);
      }
    },
    async loadData() {
      if (!this.filter) return;
      this.loading = true;
      const data = await api.hotelHistory.revByChannel(this.filter);
      this.chartData = {
        labels: data.map((item) => item.x),
        datasets: [
          {
            label: 'Revenue',
            data: data.map((item) => item.y),
            backgroundColor: data.map((d, idx) => colors.colorList[idx % colors.colorList.length]),
            borderColor: '#fff',
          },
        ],
      };
      this.loading = false;
    },
  },
  data() {
    return {
      filter: this.$store.state.filter,
      chartData: null,
      loading: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const total = data.datasets[tooltipItem.datasetIndex].data
                .reduce((acc, item) => acc + item, 0);
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const label = data.labels[tooltipItem.index];
              const percentage = ((value / total) * 100.0).toFixed(2);
              const displayValue = chartUtil.approximateNumber(value, 0)
                .toLocaleString('it');
              return `  ${label}: ${percentage}%  ${displayValue}\u20AC `;
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Revenue By Channel',
            align: 'left',
          },
        },
        datalabes: {
          display: false,
        },
        legend: {
          position: 'right',
          display: true,
          labels: {
            usePointStyle: true,
            fontColor: '#3f4a5b',
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>

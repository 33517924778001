var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.filter)?_c('v-container',{staticClass:"ma-3 pa-3 bar-suggestions",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-10",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex flex-row-reverse mb-3"},[_c('ApplySelectionDialog',{attrs:{"selection":_vm.selectedKeys},on:{"saved":_vm.loadData}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.rows || [],"item-key":"key","loading":_vm.loading,"disable-sort":true,"show-expand":"","show-select":"","selectable-key":"key"},on:{"toggle-select-all":_vm.selectAll,"item-selected":_vm.handleRowSelection},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('BarSuggestionExpansion',{attrs:{"headers":headers.length,"item":item,"competitor-prices":_vm.competitorPrices}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])]}},{key:"item.currentBar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.barName(item.currentBar.idBar))+" ")])]}},{key:"item.suggestedBar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.barName(item.suggestedBar.idBar))+" "),(item.suggestedBar.idBar < item.currentBar.idBar)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v(" mdi-arrow-up ")]):_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-arrow-down ")])],1)]}},{key:"item.occupancy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.fixedDigits(item.occupancy, 2).toLocaleString(_vm.$vuetify.lang.current))+"% ")])]}},{key:"item.adr",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.fixedDigits(item.adr, 2).toLocaleString(_vm.$vuetify.lang.current))+" "),_c('i',{staticClass:"mdi mdi-currency-eur no-space"})])]}}],null,false,3488582550)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
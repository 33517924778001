<template>
  <v-navigation-drawer
    permanent
    app
    fixed
    color="secondary darken-1"
    clipped
    class="left-navigation-drawer pt-14"
    expand-on-hover
  >
    <v-list>
      <v-list-item-group
        :value="$router.currentRoute"
        active-class="primary--text text--accent-4"
        class="white--text"
        dark
      >
        <v-list-item
          v-for="route in $router.options.routes"
          :key="route.path"
          :to="route"
          :disabled="disabledRoutes[route.name]"
        >
          <v-list-item-icon>
            <v-icon :class="disabledRoutes[route.name]? 'v-icon--disabled': ''">
              {{ route.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $vuetify.lang.t(route.meta.displayName) }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import store from '@/store';

export default {
  name: 'LeftNavigationBar',
  created() {
    this.setDisabledRoutes();
  },
  mounted() {
    store.subscribe((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.setDisabledRoutes();
      }
    });
  },
  methods: {
    setDisabledRoutes() {
      const disabledRoutes = this.$router.options.routes.reduce((res, route) => {
        // eslint-disable-next-line no-param-reassign
        res[route.name] = !route.enabledCheck(this.$store.state);
        return res;
      }, {});
      this.disabledRoutes = { ...disabledRoutes };
      if (this.disabledRoutes[this.$router.currentRoute.name]) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
  data() {
    return {
      disabledRoutes: {},
    };
  },
};
</script>
<style lang="scss">
@import "src/scss/mixins";
@import "src/scss/variables";

.v-navigation-drawer__border{
    display: none!important;
}
</style>

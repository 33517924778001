<template>
  <v-container
    fluid
    class="ma-3 pa-3"
    v-if="$store.state.filter"
  >
    <v-card class="pa-10">
      <PricingFilters
        ref="pricingFilter"
        :hotels="hotels"
        :rooms="rooms"
        pricing-type="fit"
        @change="applyFilter"
        @global="handleBarChange()"
      />
      <v-sheet
        tile
        height="54"
        class="d-flex"
      >
        <v-btn
          icon
          class="ma-2"
          @click="back"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer />
        <v-menu
          v-model="monthSelection"
          transition="scroll-y-transition"
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="text-h5 text-capitalize justify-center"
              label
              color="white"
              v-bind="attrs"
              v-on="on"
              style="width: 290px"
            >
              {{ displayedMonth.locale($vuetify.lang.current).format('MMMM YYYY') }}
            </v-chip>
          </template>
          <v-date-picker
            type="month"
            no-title
            v-model="month"
            @input="monthSelection = false"
            @change="pickMonth"
          />
        </v-menu>
        <v-spacer />
        <v-btn
          icon
          class="ma-2"
          @click="forward"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-sheet>
        <v-progress-linear
          v-if="loading || loadingHurdleRates"
          indeterminate
        />
        <div
          style="height: 4px"
          v-else
        />
        <v-calendar
          ref="calendar"
          type="month"
          v-model="calendarValue"
          :event-color="getEventColor"
          :events="bars"
          :event-height="30"
          :show-month-on-first="false"
          @click:event="showEvent"
        >
          <template v-slot:day-label="day">
            <div class="custom-day-label">
              <div
                class="day-occupancy-wrapper"
              >
                <OccupancySkittle
                  :value="occupancies[day.date]"
                />
              </div>
              <span class="day">
                {{ day.day }}
              </span>
              <div
                class="day-open-wrapper"
              >
                <v-switch
                  v-if="filter && closed[day.date] != undefined"
                  v-model="closed[day.date]"
                  inset
                  dense
                  class="ma-0 red-off-state"
                  :ripple="false"
                  :color="false ? 'red' : 'primary'"
                  @change="updateClosed(day.date)"
                />
              </div>
            </div>
          </template>
          <template v-slot:day="date">
            <div class="hurdle-rate-box pb-1 px-2 d-flex">
              <HurdleRate
                v-if="filter && !loadingHurdleRates"
                :item="hurdleRates[date.date]"
                :date="date.date"
              />
              <HurdleRate
                v-else-if="filter"
                :date="date.date"
              />
              <v-spacer/>
              <PromoSkittle
                v-if="filter"
                :room="filter.roomReferenceType != null"
                :promo="1"
                :value="promos[date.date]"
              />
              <v-spacer/>
              <PromoSkittle
                v-if="filter"
                :room="filter.roomReferenceType != null"
                :promo="2"
                :value="promos[date.date]"
              />
              <v-spacer />
              <PromoSkittle
                v-if="filter"
                :room="filter.roomReferenceType != null"
                :promo="3"
                :value="promos[date.date]"
              />
              <!-- OccupancySkittle v-else-if="filter" /-->
            </div>
          </template>
          <template v-slot:event="{event}">
            <div class="d-flex pt-1 text-md-body-1 text-sm-body-2 font-weight-bold">
              <!-- Left Side of the event label -->
              <v-spacer />
              <span class="pr-2">
                {{ event.description }}
              </span>
            </div>
          </template>
        </v-calendar>
        <RateDialog
          :key="(selectedEvent || {}).r"
          :selected-element="selectedElement"
          :selected-open="selectedOpen"
          :interval="selectedEvent"
          :show-competitors="true"
          :competitor-prices="competitorPrices"
          :prepaid="(selectedEvent || {}).prepaid"
          @change="handleBarChange()"
          @close="closeDialog()"
        />
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api';
import RateDialog from '@/components/FitRates/RateDialog.vue';
import PricingFilters from '@/components/Pricing/PricingFilters.vue';
import HurdleRate from '@/components/Pricing/HurdleRateDialog.vue';
import PromoSkittle from '@/components/Pricing/skittles/PromoSkittle.vue';
import OccupancySkittle from '@/components/Pricing/skittles/OccupancySkittle.vue';
import colors from '@/plugins/colors';

const { eventsColor } = colors;
console.log(eventsColor[0]);

export default {
  name: 'FitRates',
  components: {
    PromoSkittle,
    OccupancySkittle,
    HurdleRate,
    PricingFilters,
    RateDialog,
  },
  computed: {
    start() {
      return moment(this.calendarValue || moment.now()).startOf('month');
    },
    end() {
      return moment(this.calendarValue || moment.now()).endOf('month');
    },
    startApi() {
      return moment(this.calendarValue || moment.now())
        .startOf('month')
        .startOf('week')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    },
    endApi() {
      return moment(this.calendarValue || moment.now())
        .endOf('month')
        .endOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD');
    },
    displayedMonth() {
      return moment(this.month);
    },
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        if (this.filter && this.filter != null) this.loadBars();
      }
    }));
    this.loadHotels();
    this.loadRoomTypes();
  },
  mounted() {
    const { idHotel, roomReferenceType, month } = this.$route.params;
    if (!idHotel) return;
    if (month) {
      this.calendarValue = month.format('YYYY-MM-DD');
      this.month = month.startOf('month').format('YYYY-MM');
    }
    this.$refs.pricingFilter.setSelectedHotel(idHotel);
    this.$refs.pricingFilter.setSelectedRoom(roomReferenceType);
    this.applyFilter({
      hotelId: idHotel,
      roomReferenceType,
    });
  },
  methods: {
    async loadHotels() {
      this.hotels = await api.hotels.listAll();
    },
    async loadRoomTypes() {
      if (!this.filter || !this.filter.hotelId) {
        return;
      }
      this.rooms = await api.hotels.listAllRooms(this.filter.hotelId);
    },
    pickMonth() {
      this.calendarValue = `${this.month}-01`;
      this.loadBars();
      this.loadHurdleRates();
    },
    getEventColor(event) {
      return event.color;
    },
    closeDialog() {
      this.$refs.calendar.$el.click();
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.selectedElement = null;
    },
    handleBarChange() {
      this.closeDialog();
      this.loadBars();
      this.loadHurdleRates();
      this.loadPromos();
      this.loadClosed();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 50);
      };
      this.selectedEvent = { ...event, r: Math.random() };
      this.selectedElement = nativeEvent.target;
      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.$refs.calendar.$el.click();
        setTimeout(open, 50);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    applyFilter(filter) {
      const hotelChanged = !this.filter
        || this.filter.hotelId !== filter.hotelId
        || !filter.hotelId;
      const roomChanged = !this.filter
        || this.filter.roomReferenceType !== filter.roomReferenceType;
      this.filter = filter;

      if (hotelChanged && this.filter.hotelId) {
        this.hurdleRates = {};
        this.bars = [];
        this.loadBars();
        this.loadRoomTypes();
        this.loadHurdleRates();
        this.loadPromos();
        this.loadClosed();
      } else if (!this.filter.hotelId) {
        this.bars = [];
      }
      if (roomChanged && this.filter.roomReferenceType) {
        this.hurdleRates = {};
        this.roomName = filter.roomId;
        this.filter.roomTypes = [{
          idHotel: this.filter.hotelId,
          roomReferenceType: this.filter.roomReferenceType,
        }];
        this.loadBars();
        this.loadHurdleRates();
        this.loadPromos();
        this.loadClosed();
      }
    },
    async loadHurdleRates() {
      if (!this.filter) {
        return;
      }
      this.filter.statuses = this.$store.state.filter.statuses;
      this.filter.channels = this.$store.state.filter.channels;
      const body = {
        structures: [this.filter.hotelId],
        periodStart: this.startApi,
        periodEnd: this.endApi,
        statuses: this.filter.statuses,
        channels: this.filter.channels,
      };
      this.loadingHurdleRates = true;
      const response = await api.hurdleRatesFit.raws(body);
      const data = response.map((i) => ({
        ...i,
        ...i.key,
      }));
      this.hurdleRates = data.reduce((acc, item) => {
        const { date } = item.key;
        const res = { ...acc };
        res[date] = item;
        return res;
      }, {});
      this.loadingHurdleRates = false;
    },
    async loadOccupancy() {
      if (!this.filter.hotelId) return;
      const apiFilter = {
        periodStart: this.start.format('YYYY-MM-DD'),
        periodEnd: this.end.format('YYYY-MM-DD'),
        structures: [this.filter.hotelId],
        statuses: this.filter.statuses,
        channels: this.filter.channels,
      };
      // if (this.filter.roomReferenceType) {
      //   apiFilter.roomTypes = [{
      //     idHotel: this.filter.hotelId,
      //     roomReferenceType: this.filter.roomReferenceType,
      //   }];
      // }
      apiFilter.comparison = 'forecast';
      const res = await api.hotelHistory.fetchOccupancyStats(apiFilter);
      this.occupancies = res.datasets[1].data.reduce((map, obj) => {
        // eslint-disable-next-line no-param-reassign
        map[moment(obj.x).format('YYYY-MM-DD')] = `${obj.y}%`;
        return map;
      }, {});
    },
    // TODO Load Bars == input here the rates change
    async loadBars() {
      if (!this.filter || !this.filter.hotelId) {
        return;
      }
      this.filter.statuses = this.$store.state.filter.statuses;
      this.filter.channels = this.$store.state.filter.channels;
      this.stagingChosenBar = {};
      this.competitorPrices = [];
      this.occupancies = [];
      const body = {
        structures: [this.filter.hotelId],
        roomReferenceTypes: [this.filter.roomReferenceType],
        periodStart: this.startApi,
        periodEnd: this.endApi,
        statuses: this.filter.statuses,
        channels: this.filter.channels,
        roomTypes: this.filter.roomTypes,
      };
      this.loading = true;
      const data = await api.fit.fetchFit(body);
      this.roomPrices = data.prices;
      const dataRange = [[], []];
      const rateIds = {};
      // TODO Make averages function
      const eventRange = [];
      let flag = true;
      // FIXME make this better
      data.events = data.events.sort((a, b) => {
        if (a.night < b.night) {
          return -1;
        }
        if (a.night > b.night) {
          return 1;
        }
        if (a.night === b.night) {
          if (a.ratename < b.ratename) {
            return -1;
          }
          if (a.ratename > b.ratename) {
            return 1;
          }
        }
        return 0;
      });
      // Builds the bar of events
      data.events.forEach((e) => {
        if (e.night.substring(0, 4) === '2023') {
        // else {
          if (eventRange.length > 0
            && e.promo === eventRange[eventRange.length - 1].promo25
            && e.avgPricePromo === eventRange[eventRange.length - 1].avgPricePromo25) {
            eventRange[eventRange.length - 1].end = e.night;
          } else {
            eventRange.push({
              avgPricePromo25: e.avgPricePromo,
              idHotel: e.idHotel,
              start: e.night,
              end: e.night,
              promo25: e.promo,
              rateId25: e.rateId,
              roomReferenceType: e.roomReferenceType,
              rateName25: e.rateName,
              prepaid: e.promo,
              description: `${e.promo} %`,
              name: `Avg${e.avgPricePromo} Promo: ${e.promo}%`,
              color: eventsColor[2],
              title: true,
              r: Math.random(),
            });
          }
        } else if (e.night.substring(0, 4) !== '2023') {
          // if (i % 3 === 0) {
          if (e.rateName === 'FIT 20') {
            if (eventRange.length > 0
              && e.promo === eventRange[eventRange.length - 1].promo20
              && e.avgPricePromo === eventRange[eventRange.length - 1].avgPricePromo20) {
              eventRange[eventRange.length - 1].end = e.night;
            } else {
              eventRange.push({
                avgPricePromo20: e.avgPricePromo,
                idHotel: e.idHotel,
                start: e.night,
                end: e.night,
                promo20: e.promo,
                rateId20: e.rateId,
                roomReferenceType: e.roomReferenceType,
                rateName20: e.rateName,
                prepaid: e.promo,
                description: `${e.promo} %`,
                name: `Avg${e.avgPricePromo} Promo: ${e.promo}%`,
                color: eventsColor[2],
                title: true,
                r: Math.random(),
              });
              flag = true;
            }
          // } else if (i % 3 === 1 && flag) {
          } else if (e.rateName === 'FIT 23' && flag) {
            eventRange[eventRange.length - 1].avgPricePromo23 = e.avgPricePromo;
            eventRange[eventRange.length - 1].promo23 = e.promo;
            eventRange[eventRange.length - 1].rateId23 = e.rateId;
            eventRange[eventRange.length - 1].rateName23 = e.rateName;
          // } else if (i % 3 === 2 && flag) {
          } else if (e.rateName === 'FIT 25' && flag) {
            eventRange[eventRange.length - 1].avgPricePromo25 = e.avgPricePromo;
            eventRange[eventRange.length - 1].promo25 = e.promo;
            eventRange[eventRange.length - 1].rateId25 = e.rateId;
            eventRange[eventRange.length - 1].rateName25 = e.rateName;
            flag = false;
          }
        }
      });
      this.bars = eventRange.map((ev) => {
        if (ev.start.substring(0, 4) === '2023') {
          return {
            ...ev,
            description: `${ev.promo25} %`,
          };
        }
        if (ev.promo20 !== ev.promo23 || ev.promo20 !== ev.promo25 || ev.promo23 !== ev.promo25) {
          return {
            ...ev,
            description: `${ev.promo20} % - ${ev.promo23} % - ${ev.promo25} %`,
          };
        }
        return {
          ...ev,
          description: `${ev.promo20} %`,
        };
      });
      const bbd = {};
      this.bars.forEach((e) => {
        for (let m = moment(e.start); m.isSameOrBefore(e.end); m.add(1, 'days')) {
          bbd[m.format('YYYY-MM-DD')] = e;
        }
      });
      // this.barsByDay = bbd;
      await this.loadOccupancy();
      this.loading = false;
    },
    async loadPromos() {
      const body = {
        structures: [this.filter.hotelId],
        roomReferenceTypes: [this.filter.roomReferenceType],
        periodStart: this.startApi,
        periodEnd: this.endApi,
        statuses: this.filter.statuses,
        channels: this.filter.channels,
        roomTypes: this.filter.roomTypes,
      };
      const data = await api.fit.fetchPromos(body);
      this.promos = {};
      data.forEach((promo) => {
        if (!this.promos[promo.night]) this.promos[promo.night] = {};
        this.promos[promo.night][promo.rateName] = promo.pricePromo;
      });
    },
    async loadClosed() {
      const body = {
        structures: [this.filter.hotelId],
        roomReferenceTypes: [this.filter.roomReferenceType],
        periodStart: this.startApi,
        periodEnd: this.endApi,
        statuses: this.filter.statuses,
        channels: this.filter.channels,
        roomTypes: this.filter.roomTypes,
      };
      const data = await api.fit.fetchOpen(body);
      this.closed = {};
      data.forEach((val) => {
        this.closed[val.night] = val.closed === 0;
      });
    },
    async loadStagingChosenBar() {
      const data = await api.stagingChosenBar.getByFilter({
        structures: [this.filter.hotelId],
        periodStart: this.startApi,
        periodEnd: this.endApi,
        statuses: this.filter.statuses,
        channels: this.filter.channels,
      });
      data.forEach((cb) => {
        this.stagingChosenBar[cb.date] = cb;
      });
    },
    async updateClosed(dayString) {
      const res = await api.fit.updateClosed({
        roomId: this.filter.roomReferenceType || null,
        hotelId: this.filter.hotelId,
        date: dayString,
        closed: this.closed[dayString] ? 0 : 1,
      });
    },
    back() {
      this.$refs.calendar.prev();
      this.month = moment(this.calendarValue).startOf('month').format('YYYY-MM');
      this.loadBars();
      this.loadHurdleRates();
      this.loadPromos();
      this.loadClosed();
    },
    forward() {
      this.$refs.calendar.next();
      this.month = moment(this.calendarValue).startOf('month').format('YYYY-MM');
      this.loadBars();
      this.loadHurdleRates();
      this.loadPromos();
      this.loadClosed();
    },
  },
  data() {
    const currentMonth = moment().startOf('month');
    return {
      switchy: true,
      calendarValue: currentMonth.format('YYYY-MM-DD'),
      month: currentMonth.format('YYYY-MM'),
      events: [],
      bars: [],
      loading: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      monthSelection: false,
      filter: null,
      hurdleRates: {},
      loadingHurdleRates: false,
      hotels: [],
      rooms: [],
      competitorPrices: [],
      occupancies: [],
      promos: {},
      closed: {},
      roomName: null,
      roomPrices: null,
      stagingChosenBar: {},
      barsByDay: {},
    };
  },
};
</script>

<style lang="scss">
.v-calendar {
  .v-event {
    margin-left: 2.5% !important;
    margin-top: 24px!important;
  }
}
.hurdle-rate-box {
  position: absolute;
  top: 40px;
  width: 100%;
}
.v-calendar-weekly__week {
  min-height: 115px!important;
}
.v-calendar-weekly__day-label {
  margin-top: 0!important;
  .v-btn{
    font-weight: bold!important;
    font-size: 16px!important;
  }
}
.custom-day-label{
  position: relative;
  min-height: 50px;
  text-align: center;
  .day {
    font-weight: bold !important;
    font-size: 16px !important;
  }
  .day-open-wrapper{
    position: absolute;
    top: -2px;
    right: 0px;
  }
  .day-occupancy-wrapper{
    position: absolute;
    top: 2px;
    left: 5px;
    font-weight: bold !important;
    font-size: 14px !important;
  }
}

.red-off-state .v-input__control .v-input__slot .v-input--selection-controls__input{
  .v-input--switch__track {
    color: #cb6868;
  }
  .v-input--switch__thumb {
    color: #cb6868;
  }
}
</style>

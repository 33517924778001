<!-- Occupancy Menu displaying 3 Metric Cards -->
<template>
  <v-row
    no-gutters
    class="fill-height"
  >
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        :title="countTitle"
        :value="metrics.roomNights"
        :currency="false"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).roomNights"
        :budget-value="(budgetMetrics|| {}).roomNights"
        :forecast-value="metrics.predictedRoomNights"
        :consolidated-value="(consolidMetrics|| {}).roomNights"
        :enable-budget="true"
        :enable-forecast="true"
        :enable-consolidated="vp"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.occupancy"
        :value="metrics.occupancy"
        :currency="false"
        :round-digits="false"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).occupancy"
        :budget-value="(budgetMetrics|| {}).occupancy"
        :consolidated-value="(consolidMetrics|| {}).occupancy"
        :enable-forecast="true"
        :enable-budget="true"
        :forecast-value="metrics.predictedOccupancy"
        :enable-consolidated="vp"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-0"
    >
      <MetricCard
        title="$vuetify.dashboard.reservations"
        :value="metrics.reservations"
        :currency="false"
        :loading="loading"
        :historical-value="(historicalMetrics|| {}).reservations"
        :consolidated-value="(consolidMetrics|| {}).reservations"
        :enable-consolidated="vp"
      />
    </v-col>
  </v-row>
</template>
<script>
import MetricCard from '@/components/Dashboard/metrics/MetricCard.vue';

export default {
  name: 'OccupancyKPI',
  components: { MetricCard },
  props: {
    metrics: {
      type: Object,
      required: true,
    },
    historicalMetrics: {
      type: Object,
      default: () => null,
    },
    budgetMetrics: {
      type: Object,
      default: () => null,
    },
    consolidMetrics: {
      type: Object,
      default: () => null,
    },
    vp: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.filter = state.filter;
        this.mutateName(state.filter.roomServices);
      }
    }));
  },
  methods: {
    mutateName(serv) {
      if (serv.length === 0 || (serv.includes('Rooms') && serv.length > 1)) this.countTitle = '$vuetify.dashboard.totalCountDuo';
      if (serv.includes('Rooms') && serv.length === 1) this.countTitle = '$vuetify.dashboard.totalCountRoom';
      if (!serv.includes('Rooms') && serv.length > 0) this.countTitle = '$vuetify.dashboard.totalCountService';
    },
    loadName(serv) {
      if (serv.length === 0 || (serv.includes('Rooms') && serv.length > 1)) return '$vuetify.dashboard.totalCountDuo';
      if (serv.includes('Rooms') && serv.length === 1) return '$vuetify.dashboard.totalCountRoom';
      if (!serv.includes('Rooms') && serv.length > 0) return '$vuetify.dashboard.totalCountService';
      return '$vuetify.dashboard.totalCountDuo';
    },
  },
  data() {
    return {
      countTitle: this.loadName(this.$store.state.filter.roomServices),
      filter: null,
    };
  },
};
</script>
<style lang="scss">
</style>

<!-- Data Analisis epandible Table  -->
<template>
  <v-card class="ma-3 pa-5">
    <v-card-title>
      <v-row class="pb-2">
        <v-col
          md="4"
          class="px-3"
        >
          <v-text-field
            :placeholder="$vuetify.lang.t('$vuetify.search')"
            v-model="search"
            dense
            append-icon="mdi-magnify"
            single-line
            color="secondary"
            class="px-1 py-0 text-end"
            hide-details
          />
        </v-col>
        <v-spacer />
        <v-btn
          class="primary align-self-center mr-3"
          rounded
          @click="exportCsv"
        >
          <v-progress-circular
            v-if="exporting"
            indeterminate
            size="24"
            class="mr-2"
          />
          {{ $vuetify.lang.t('$vuetify.export') }}
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="rows"
        item-key="idHotel"
        :search="search"
        :loading="loading"
        class="elevation-0"
        multi-sort
      >
        <template v-slot:item="{item, expand, isExpanded}">
          <tr
            :data-id="item.idHotel"
            :class="(isExpanded ? 'primary lighten-1 ' : '') +
              (item.total ? 'primary lighten-1' : '')"
          >
            <td class="text-start">
              {{ item.destination }}
            </td>
            <td class="text-start">
              {{ item.name }}
            </td>
            <td>
              <v-btn
                v-if="item.total != true"
                icon
                @click="expand(!isExpanded); loadMonthDetails(isExpanded, item)"
              >
                <v-icon v-if="isExpanded" class="mdi mdi-chevron-up" />
                <v-icon v-else class="mdi mdi-chevron-down" />
              </v-btn>
            </td>
            <td class="text-center">
              {{ fixedDigits(item.totalRevenue,0).toLocaleString($vuetify.lang.current) }}
              <i class="mdi mdi-currency-eur" />
            </td>
            <td class="text-center">
              {{ fixedDigits(item.adrPlus, 0).toLocaleString($vuetify.lang.current) }}
              <i class="mdi mdi-currency-eur" />
            </td>
            <td class="text-center">
              {{ item.roomNights.toLocaleString($vuetify.lang.current) }}
            </td>
            <td class="text-center">
              {{ item.reservations }}
            </td>
            <td class="text-center">
              {{ item.occupancy }}%
            </td>
            <td class="text-center">
              {{ fixedDigits(item.revParPlus, 2).toLocaleString($vuetify.lang.current) }}
              <i class="mdi mdi-currency-eur" />
            </td>
            <td class="text-center">
              <span v-if="item.competitorAvg !== 0">
                {{ fixedDigits(item.competitorAvg, 0).toLocaleString($vuetify.lang.current) }}
                <i class="mdi mdi-currency-eur" />
              </span>
              <span v-else><v-icon>mdi-minus</v-icon></span>
            </td>
            <td class="text-center">
              <span v-if="item.suggestedVariation !== 0">
                {{ fixedDigits(item.suggestedVariation, 0).toLocaleString($vuetify.lang.current) }}
                <i class="mdi mdi-currency-eur" />
              </span>
              <span v-else><v-icon>mdi-minus</v-icon></span>
            </td>
            <td class="text-center">
              <v-btn
                v-if="item.total != true"
                color="secondary"
                rounded
                small
                @click="()=> selectStructure(item)"
              >
                {{ $vuetify.lang.t('$vuetify.dataAnalysis.details') }}
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{item}">
          <td
            v-if="!monthsLoading[item.name]"
            colspan="2"
          />
          <td
            :colspan="monthsLoading[item.name] ? headers.length : headers.length-2"
            class="text-center"
          >
            <v-progress-linear
              stream
              v-model="progress"
              :buffer-value="total == 0 ? 0 : 100"
              color="primary darken-1"
              v-if="monthsLoading[item.name]"
            />
            <v-data-table
              :headers="monthHeaders"
              hide-default-footer
              hide-default-header
              :items="monthRows[item.name]"
              item-key="idHotelMonth"
              :search="search"
              class="elevation-0"
              multi-sort
              v-else
            >
              <!-- eslint-disable-next-line vue/no-template-shadow -->
              <template v-slot:item="{item}">
                <tr>
                  <td class="text-start" width="103">
                    {{ item.month[$vuetify.lang.current] }}
                  </td>
                  <td class="text-center" width="140">
                    {{ fixedDigits(item.totalRevenue,0).toLocaleString($vuetify.lang.current) }}
                    <i class="mdi mdi-currency-eur" />
                  </td>
                  <td class="text-center" width="110">
                    {{ fixedDigits(item.adrPlus, 0).toLocaleString($vuetify.lang.current) }}
                    <i class="mdi mdi-currency-eur" />
                  </td>
                  <td class="text-center" width="120">
                    {{ item.roomNights.toLocaleString($vuetify.lang.current) }}
                  </td>
                  <td class="text-center" width="125">
                    {{ item.reservations }}
                  </td>
                  <td class="text-center" width="120">
                    {{ item.occupancy }}%
                  </td>
                  <td class="text-center" width="95">
                    {{ fixedDigits(item.revParPlus, 2).toLocaleString($vuetify.lang.current) }}
                    <i class="mdi mdi-currency-eur" />
                  </td>
                  <td class="text-center" width="120">
                    <span v-if="item.competitorAvg !== 0">
                      {{ fixedDigits(item.competitorAvg, 0).
                        toLocaleString($vuetify.lang.current) }}
                      <i class="mdi mdi-currency-eur" />
                    </span>
                    <span v-else><v-icon>mdi-minus</v-icon></span>
                  </td>
                  <td class="text-center" width="120">
                    <span v-if="item.suggestedVariation !== 0">
                      {{ fixedDigits(item.suggestedVariation, 0).
                        toLocaleString($vuetify.lang.current) }}
                      <i class="mdi mdi-currency-eur" />
                    </span>
                    <span v-else><v-icon>mdi-minus</v-icon></span>
                  </td>
                  <td/>
                </tr>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '@/api';
import chartUtil from '@/components/lib/chartUtil';
import { DateTime } from 'luxon';

export default {
  name: 'PeriodDetails',
  mounted() {
    this.filter = this.$store.state.filter;
    this.loadDetails();
    this.monthHeaders = JSON.parse(JSON.stringify(this.headers));
    // eslint-disable-next-line no-restricted-syntax
    for (const i of '123') this.monthHeaders.shift();
    this.monthHeaders.unshift({
      text: 'Month',
      align: 'center',
      value: 'month',
      width: 103,
    });
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        if (JSON.stringify(this.filter) !== JSON.stringify(state.filter)) {
          this.filter = state.filter;
          this.loadDetails();
        }
      }
    }));
    this.setHeadersLang();
  },
  watch: {
    '$vuetify.lang.current': {
      handler() {
        this.setHeadersLang();
      },
    },
  },
  methods: {
    fixedDigits: chartUtil.approximateNumber,
    async loadDetails() {
      this.loading = true;
      this.monthRows = {};
      this.rows = await api.hotelHistory.fetchDetailsByStructure(this.$store.state.filter);
      this.rows.push({
        total:
          true,
        destination:
          'Totale',
        name:
          '',
        totalRevenue:
          this.rows.map((x) => x.totalRevenue).reduce((a, b) => a + b, 0),
        adrPlus:
          Math.round(this.rows.map((x) => x.adrPlus).reduce((a, b) => a + b, 0) / this.rows.length),
        roomNights:
          this.rows.map((x) => x.roomNights).reduce((a, b) => a + b, 0),
        reservations:
          this.rows.map((x) => x.reservations).reduce((a, b) => a + b, 0),
        revParPlus:
          Math.round(this.rows.map((x) => x.revParPlus)
            .reduce((a, b) => a + parseFloat(b), 0) / this.rows.length),
        occupancy:
          (this.rows.map((x) => x.occupancy).reduce((a, b) => a + b, 0) / this.rows.length)
            .toFixed(2),
        competitorAvg:
          Math.round(this.rows.map((x) => x.competitorAvg)
            .reduce((a, b) => a + parseFloat(b), 0) / this.rows.length),
        suggestedVariation:
          Math.round(this.rows.map((x) => x.suggestedVariation)
            .reduce((a, b) => a + parseFloat(b), 0) / this.rows.length),
      });
      this.loading = false;
    },
    async loadMonthDetails(show, item) {
      if (show) return;
      if (this.monthRows[item.name]) return;
      this.monthsLoading[item.name] = true;
      if (!this.hotelData) this.hotelData = await api.hotels.listAll();
      // Deep copy of filters
      const modFilter = JSON.parse(JSON.stringify(this.$store.state.filter));
      let fieldId = 0;
      this.hotelData.some((hotel) => {
        if (hotel.name === item.name) {
          fieldId = hotel.id;
          return true;
        }
        return false;
      });
      // Search for specific hotel
      modFilter.structures = [fieldId];
      // Build date Array
      const dates = this.genDates(modFilter.periodStart, modFilter.periodEnd);
      this.monthRows[item.name] = [];
      dates.forEach(() => { this.monthRows[item.name].push(null); });
      // Manage a wait while loading this request
      let pos = 0;
      let prog = 0;
      this.total = dates.length;
      await Promise.all(dates.map(async (date) => {
        // eslint-disable-next-line prefer-destructuring
        modFilter.periodStart = date[0];
        // eslint-disable-next-line prefer-destructuring
        modFilter.periodEnd = date[1];
        const x = pos;
        pos += 1;
        const [row] = await api.hotelHistory.fetchDetailsByStructure(modFilter);
        // eslint-disable-next-line prefer-destructuring
        if (row) row.month = date[2];
        this.monthRows[item.name][x] = row;
        prog += 1;
        this.progress = (prog / this.total) * 100;
      }));
      this.progress = 0;
      const temp = [];
      this.monthRows[item.name].forEach((i) => { if (i != null) temp.push(i); });
      this.monthRows[item.name] = temp;
      this.monthsLoading[item.name] = false;
    },
    async exportCsv() {
      if (this.exporting) return;
      this.exporting = true;
      await api.hotelHistory.downloadDetailsByStructureMonthly(this.$store.state.filter);
      this.exporting = false;
    },
    genDates(start, end) {
      const startDate = DateTime.fromISO(start).setLocale(this.$vuetify.lang.current);
      const endDate = DateTime.fromISO(end).setLocale(this.$vuetify.lang.current);
      // Starts and ends on same month
      if (startDate.month === endDate.month) {
        const monthString = {};
        monthString.it = startDate.setLocale('it-IT').toFormat('LLLL');
        monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
        monthString.en = startDate.setLocale('en-US').toFormat('LLLL');
        return [[start, end, monthString]];
      }
      // Ends on different months
      let monthString = {};
      monthString.it = startDate.setLocale('it-IT').toFormat('LLLL');
      monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
      monthString.en = startDate.setLocale('en-US').toFormat('LLLL');
      const dates = [[start, startDate.endOf('month').toFormat("yyyy'-'LL'-'dd"), monthString]];
      let som = startDate.plus({ month: 1 }).startOf('month');
      let eom = startDate.plus({ month: 1 }).endOf('month');
      while (eom.month !== endDate.month || eom.year !== endDate.year) {
        monthString = {};
        monthString.it = som.setLocale('it-IT').toFormat('LLLL');
        monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
        monthString.en = som.setLocale('en-US').toFormat('LLLL');
        dates.push([som.toFormat("yyyy'-'LL'-'dd"), eom.toFormat("yyyy'-'LL'-'dd"), monthString]);
        som = som.plus({ month: 1 }).startOf('month');
        eom = eom.plus({ month: 1 }).endOf('month');
      }
      monthString = {};
      monthString.it = endDate.setLocale('it-IT').toFormat('LLLL');
      monthString.it = monthString.it.charAt(0).toUpperCase() + monthString.it.slice(1);
      monthString.en = endDate.setLocale('en-US').toFormat('LLLL');
      dates.push([endDate.startOf('month').toFormat("yyyy'-'LL'-'dd"), end, monthString]);
      return dates;
    },
    selectStructure(item) {
      this.$emit('selectedStructure', item);
    },
    setHeadersLang() {
      this.headers[0].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.destination');
      this.headers[1].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.name');
      this.headers[2].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.expand');
      this.headers[3].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.revenue');
      this.headers[4].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.adrPlus');
      this.headers[5].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.roomNights');
      this.headers[6].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.reservations');
      this.headers[7].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.occupancy');
      this.headers[8].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.revParPlus');
      this.headers[9].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.competitorMed');
      this.headers[10].text = this.$vuetify.lang.t('$vuetify.dataAnalysis.medCompSet');
    },
  },
  data() {
    return {
      rows: [],
      search: '',
      loading: false,
      filter: null,
      hotelData: null,
      monthRows: {},
      progress: 0,
      total: 0,
      monthsLoading: {},
      headers: [
        {
          text: 'Destination',
          align: 'start',
          value: 'destination',
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Month',
          value: 'data-table-expand',
          sortable: false,
          filterable: false,
          width: 103,
        },
        {
          text: 'Revenue',
          align: 'center',
          value: 'totalRevenue',
          width: 140,
        },
        {
          text: 'ADR',
          align: 'center',
          value: 'adrPlus',
          width: 110,
        },
        {
          text: 'Room Nights',
          align: 'center',
          value: 'roomNights',
          width: 120,
        },
        {
          text: 'Reservations',
          align: 'center',
          value: 'reservations',
          width: 125,
        },
        {
          text: 'Occupancy',
          align: 'center',
          value: 'occupancy',
          width: 120,
        },
        {
          text: 'RevPar',
          align: 'center',
          value: 'revParPlus',
          width: 95,
        },
        {
          text: 'Competitor Median Price',
          align: 'center',
          value: 'competitorAvg',
          width: 120,
        },
        {
          text: 'Media CompSet',
          align: 'center',
          value: 'suggestedVariation',
          sortable: false,
          filterable: false,
          width: 120,
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          filterable: false,
          width: 150,
        },
      ],
      monthHeaders: [],
      exporting: false,
    };
  },
};
</script>

<style scoped>
.style-1 {
  background-color: rgb(255, 0, 0)
}
</style>

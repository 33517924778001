<template>
  <v-menu
    top
    offset-x
    offset-y
    max-width="80"
    :nudge-right="0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        :active="true"
        :color="selected? 'secondary lighten-1': 'white'"
        text-color="grey darken-4"
        class="period-selector px-4"
        light
        v-bind="attrs"
        v-on="on"
      >
        <span>
          {{ $vuetify.lang.t('$vuetify.filterBar.year') }}
        </span>
      </v-chip>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in years"
        :key="index"
        dense
        @click="selectYear(item, index)"
        :class="selectedIdx === index? 'secondary white--text': 'white'"
      >
        <v-list-item-title
          :class="selectedIdx === index? 'text-center white--text font-weight-bold': 'text-center'"
        >
          <span>{{ item.displayValue }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'YearPicker',
  props: {
    globalIntervals: {
      type: Object,
      required: true,
    },
    mode: null,
    selected: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    selected(newVal) {
      if (!newVal) {
        this.selectedIdx = null;
      }
    },
  },
  methods: {
    selectYear(item, idx) {
      this.selectedIdx = idx;
      this.$emit('selection', {
        mode: 'none',
        value: item.interval,
      });
    },
  },
  data() {
    let firstYear = 3000;
    let lastYear = 0;
    Object.values(this.globalIntervals).forEach((item) => {
      const min = moment(item.min).year();
      const max = moment(item.max).year();
      if (firstYear >= min) {
        firstYear = min;
      }
      if (lastYear <= max) {
        lastYear = max;
      }
    });
    const years = [];
    for (let i = firstYear; i <= lastYear; i += 1) {
      years.push({
        interval: [`${i}-01-01`, `${i}-12-31`],
        displayValue: `${i}`,
      });
    }
    return {
      years,
      selectedIdx: null,
    };
  },
};
</script>

<style scoped>

</style>

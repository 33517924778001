import Vue from 'vue';
import '@/plugins/logger';
import '@/plugins/axios';
import Keycloak from 'keycloak-js';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import moment from 'moment';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

Vue.config.productionTip = false;

const keyCloakOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
  onLoad: 'login-required',
  checkLoginIframe: false,
};

const keycloak = Keycloak(keyCloakOptions);
Vue.prototype.$keycloak = keycloak;

const storeAuthData = () => {
  const payload = {
    idToken: keycloak.idToken,
    accessToken: keycloak.token,
  };
  if (payload.idToken !== '' && payload.accessToken !== '') {
    store.commit('auth', payload);
  } else {
    store.commit('logout');
    Vue.$log.error('logout detected');
    window.location.reload();
  }
};
keycloak.init(keyCloakOptions)
  .then(() => {
    storeAuthData();
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App, { props: { keycloak } }),
    }).$mount('#app');
    setInterval(() => {
      keycloak.updateToken(70)
        .then(storeAuthData)
        .catch(() => {
          Vue.$log.error('Failed to refresh token');
          window.location.reload();
        });
    }, 30000);
  })
  .catch(() => {
    Vue.$log.error('Authenticated Failed');
    window.location.reload();
  });

moment.updateLocale('it', {
  months: [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio',
    'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
  ],
  monthsShort: [
    'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug',
    'Ago', 'Set', 'Ott', 'Nov', 'Dic',
  ],
});

<template>
  <v-app-bar
    app
    class="app-bar"
    clipped-left
    color="secondary"
  >
    <div>
      <img
        v-if="showLogo"
        class="logo"
        src="@/assets/LogoMangias.png"
        @click="reloadPage"
      >
    </div>
    <v-spacer />
    <LocaleSelectionMenu />
    <NotificationMenu />
    <AccountMenu />
  </v-app-bar>
</template>
<script>
import LocaleSelectionMenu from '@/components/main/app_bar_components/LocaleSelectionMenu.vue';
import NotificationMenu from '@/components/main/app_bar_components/NotificationMenu.vue';
import AccountMenu from '@/components/main/app_bar_components/AccountMenu.vue';

const env = process.env.NODE_ENV;
const showLogo = process.env.NODE_ENV !== 'demo';

export default {
  name: 'TopAppBar',
  components: { AccountMenu, NotificationMenu, LocaleSelectionMenu },
  data() {
    return {
      showLogo,
      env,
    };
  },
  methods: {
    reloadPage() {
      this.$router.push({ name: 'Dashboard' });
      window.location.reload();
    },
  },
};
</script>
<style lang="scss">
@import "src/scss/mixins";
@import "src/scss/variables";

.app-bar {
  @include horizontal_gradient(var(--v-appBarLeft-base), var(--v-appBarRight-base), 100%);
  .v-toolbar__content{
    padding-left: 0!important;
  }
  .logo{
    cursor: pointer;
    height: 60px;
    margin-bottom: -13px;
    &:hover {
      opacity: .8;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mb-4",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"roomId","hide-default-header":"","show-expand":_vm.showExpand},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('RoomHurdleRates',{attrs:{"hurdle-rates":item.hurdleRates,"competitor-prices":_vm.competitorPrices &&
          _vm.competitorPrices[item.roomId]? _vm.competitorPrices[item.roomId] : [],"show-competitors":_vm.showCompetitors}})],1)]}},{key:"item.avgHr",fn:function(ref){
          var item = ref.item;
return [_c('HurdleRateSkittle',{attrs:{"value":_vm.fixedDigits( item.avgHr, 2),"disabled":true}})]}},{key:"item.barPrice",fn:function(ref){
          var item = ref.item;
return [(!item.newPrice)?_c('span',[_vm._v(" "+_vm._s(item.barPrice)+" "),_c('i',{staticClass:"mdi mdi-currency-eur"})]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.barPrice)+" "),_c('i',{staticClass:"mdi mdi-currency-eur"}),_c('i',{staticClass:"mdi mdi-arrow-right"}),_vm._v(" "+_vm._s(item.newPrice)+" "),_c('i',{staticClass:"mdi mdi-currency-eur"})])]}},{key:"item.prepaid",fn:function(ref){
          var item = ref.item;
return [(!item.newPrice && (!_vm.newPrepaid || _vm.newPrepaid === _vm.prepaid))?_c('span',[_vm._v(" "+_vm._s(_vm.fixedDigits((_vm.prepaid/100.0) * item.barPrice, 2))+" "),_c('i',{staticClass:"mdi mdi-currency-eur"})]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.fixedDigits((_vm.prepaid/100.0) * item.barPrice, 2))+" "),_c('i',{staticClass:"mdi mdi-currency-eur"}),_c('i',{staticClass:"mdi mdi-arrow-right"}),_vm._v(" "+_vm._s(_vm.fixedDigits((_vm.newPrepaid/100.0) * item.newPrice, 2))+" "),_c('i',{staticClass:"mdi mdi-currency-eur"})])]}},{key:"item.rate",fn:function(ref){
          var item = ref.item;
return [(!item.newPrice && (!_vm.newPrepaid || _vm.newPrepaid === _vm.prepaid))?_c('span',[_vm._v(" "+_vm._s(_vm.fixedDigits((1 - _vm.prepaid/100.0) * item.barPrice, 2))+" "),_c('i',{staticClass:"mdi mdi-currency-eur"})]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.fixedDigits((1- _vm.prepaid/100.0) * item.barPrice, 2))+" "),_c('i',{staticClass:"mdi mdi-currency-eur"}),_c('i',{staticClass:"mdi mdi-arrow-right"}),_vm._v(" "+_vm._s(_vm.fixedDigits((1 - _vm.newPrepaid/100.0) * item.newPrice, 2))+" "),_c('i',{staticClass:"mdi mdi-currency-eur"})])]}},{key:"item.compset",fn:function(ref){
          var item = ref.item;
return [(_vm.avgByRoom[item.roomId])?_c('AvgCompetitorPriceSkittle',{attrs:{"value":_vm.avgByRoom[item.roomId].avg}}):_c('AvgCompetitorPriceSkittle')]}},{key:"header",fn:function(ref){
          var headers = ref.props.headers;
return [_c('tr',_vm._l((headers),function(header,i){return _c('th',{key:("pth-" + i),class:((i === 0? 'text-left': 'text-center') + " pa-3"),staticStyle:{"border-bottom":"1px solid rgba(0,0,0,0.12)"},attrs:{"width":header.width||''}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(header.text))+" ")])}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
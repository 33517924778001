import moment from 'moment';

const reducer = (acc, val) => {
  const key = `${val.key.idHotel}_${val.key.roomReferenceType}`;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(val);
  return acc;
};

const average = (array) => (!array || array.length === 0
  ? 0
  : array.reduce((acc, item) => acc + item.hurdleRate / array.length, 0));

const filterRates = (start, end) => (rate) => moment(rate.key.date).isSameOrAfter(moment(start), 'day')
  && moment(rate.key.date).isSameOrBefore(end, 'day');

const mergeHurdleRates = (bars, baseRates, start, end) => {
  if (!bars) return [];
  let rates = baseRates;
  if (start && end) {
    rates = baseRates.filter(filterRates(start, end));
  }
  const keyedHRs = rates.reduce(reducer, {});
  return bars.map((bar) => ({
    ...bar,
    hurdleRates: keyedHRs[`${bar.hotelId}_${bar.roomReferenceType}`],
    avgHr: average(keyedHRs[`${bar.hotelId}_${bar.roomReferenceType}`]),
  }));
};

const mergeHurdleFitRates = (rows, baseRates, start, end) => {
  if (!rows) return [];
  const fitMap = {};
  const fitCount = {
    'FIT 20': 0,
    'FIT 23': 0,
    'FIT 25': 0,
  };
  rows.forEach((fitRow) => {
    if (!fitMap[fitRow.roomReferenceType]) {
      fitMap[fitRow.roomReferenceType] = {
        idHotel: fitRow.idHotel,
        night: fitRow.night,
        roomName: fitRow.roomReferenceName,
      };
    }
    fitMap[fitRow.roomReferenceType][fitRow.rateName] = fitRow.promo;
    if (!fitMap[fitRow.roomReferenceType][fitRow.rateName.replace('FIT', 'VAL')]) {
      fitMap[fitRow.roomReferenceType][fitRow.rateName.replace('FIT', 'VAL')] = 0;
    }
    fitMap[fitRow.roomReferenceType][fitRow.rateName.replace('FIT', 'VAL')] += fitRow.avgPricePromo;
    fitCount[fitRow.rateName] += 1;
  });
  Object.keys(fitMap).forEach((key) => {
    fitMap[key]['VAL 20'] = Math.round(fitMap[key]['VAL 20'] / (fitCount['FIT 20'] / Object.keys(fitMap).length));
    fitMap[key]['VAL 23'] = Math.round(fitMap[key]['VAL 23'] / (fitCount['FIT 23'] / Object.keys(fitMap).length));
    fitMap[key]['VAL 25'] = Math.round(fitMap[key]['VAL 25'] / (fitCount['FIT 25'] / Object.keys(fitMap).length));
  });
  const fits = [];
  // eslint-disable-next-line array-callback-return
  Object.keys(fitMap).map((key) => {
    fits.push({
      hotelId: fitMap[key].idHotel,
      roomId: key,
      roomName: fitMap[key].roomName,
      date: fitMap[key].night,
      fit20: fitMap[key]['FIT 20'],
      val20: fitMap[key]['VAL 20'],
      fit23: fitMap[key]['FIT 23'],
      val23: fitMap[key]['VAL 23'],
      fit25: fitMap[key]['FIT 25'],
      val25: fitMap[key]['VAL 25'],
    });
  });
  let rates = baseRates;
  if (start && end) {
    rates = baseRates.filter(filterRates(start, end));
  }
  const keyedHRs = rates.reduce(reducer, {});
  return fits.map((bar) => ({
    ...bar,
    hurdleRates: keyedHRs[`${bar.hotelId}_${bar.roomId}`],
    avgHr: average(keyedHRs[`${bar.hotelId}_${bar.roomId}`]),
  }));
};

export default {
  mergeHurdleRates,
  mergeHurdleFitRates,
};

<template>
  <v-row class="pricing-autocomplete">
    <v-col
      md="3"
      class="pt-0"
    >
      <v-autocomplete
        class="pt-0"
        v-model="selectedHotel"
        color="secondary"
        auto-select-first
        clearable
        item-value="id"
        :item-text="(i) => i.name "
        :items="hotels"
        :label="$vuetify.lang.t('$vuetify.filterBar.destination')"
        @change="handleHotelSelection()"
      >
        <template v-slot:item="{ item }">
          <span class="text-capitalize">
            {{ item.name.toLowerCase() }}
          </span>
        </template>
        <template v-slot:selection="{item}">
          <span class="text-capitalize">
            {{ item.name.toLowerCase() }}
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col
      md="3"
      class="pt-0"
    >
      <v-autocomplete
        class="pt-0"
        v-model="selectedRoom"
        color="secondary"
        auto-select-first
        clearable
        :item-value="(i) => i.key.roomReferenceType "
        :item-text="(i) => roomDisplayName(i) "
        :items="rooms"
        :placeholder="$vuetify.lang.t('$vuetify.filterBar.rooms.types')"
        :disabled="!selectedHotel"
        @click:clear="selectedRoom = null"
      >
        <template v-slot:item="{ item }">
          <span class="text-capitalize">
            {{ item.roomType.roomId.toLowerCase() }}
          </span>
        </template>
        <template v-slot:selection="{ item }">
          <span class="text-capitalize">
            {{ item.roomType.roomId.toLowerCase() }}
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-spacer />
    <v-menu
      rounded
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary font-weight-bold"
          dark
          v-bind="attrs"
          v-on="on"
          fab
          small
          elevation="0"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list v-if="pricingType=='bar'">
        <GlobalBarChangeDialog
          v-if="selectedHotel"
          :id-hotel="selectedHotel"
          @change="handleGlobalChange"
        />
        <v-list-item
          class="text-right"
          @click="exportRollingForecast"
          :disabled="exportingRollingForecast"
        >
          <v-progress-circular
            v-if="exportingRollingForecast"
            indeterminate
            color="primary"
            size="20"
            width="2"
            class="mr-3"
          />
          <v-spacer />
          <v-list-item-title>
            <span>
              {{ $vuetify.lang.t('$vuetify.pricing.exportRollingForecast') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="text-right"
          @click="exportHotelBars"
          :disabled="exportingLiveRates"
        >
          <v-progress-circular
            v-if="exportingLiveRates"
            indeterminate
            color="primary"
            size="20"
            width="2"
            class="mr-3"
          />
          <v-spacer />
          <v-list-item-title>
            <span>
              {{ $vuetify.lang.t('$vuetify.pricing.exportLiveFees') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="text-right"
          @click="exportChosenBars"
          :disabled="exportingBars"
        >
          <v-progress-circular
            v-if="exportingBars"
            indeterminate
            color="primary"
            size="20"
            width="2"
            class="mr-3"
          />
          <v-spacer />
          <v-list-item-title>
            <span>
              {{ $vuetify.lang.t('$vuetify.pricing.exportChosenBars') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <!-- <SendBARSConfirm v-if="pricingType=='bar'"/> -->
      </v-list>
      <v-list v-if="pricingType=='fit'">
        <GlobalFitChangeDialog
          v-if="selectedHotel"
          :id-hotel="selectedHotel"
          :hotels="hotels"
          @change="handleGlobalChange"
        />
        <v-list-item
          class="text-right"
          @click="exportChosenRates"
          :disabled="exportingRates"
        >
          <v-progress-circular
            v-if="exportingLiveRates"
            indeterminate
            color="primary"
            size="20"
            width="2"
            class="mr-3"
          />
          <v-spacer />
          <v-list-item-title>
            <span>
              {{ $vuetify.lang.t('$vuetify.pricing.exportLiveFees') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <!-- <SendRatesConfirm/> -->
      </v-list>
    </v-menu>
  </v-row>
</template>

<script>
import api from '@/api';
import chartUtil from '@/components/lib/chartUtil';
import GlobalBarChangeDialog from '@/components/Pricing/GlobalBarChangeDialog.vue';
import GlobalFitChangeDialog from '@/components/FitRates/GlobalFitChangeDialog.vue';
import SendBARSConfirm from '@/components/Pricing/SendBARSConfirm.vue';
import SendRatesConfirm from '@/components/Pricing/SendRatesConfirm.vue';

export default {
  name: 'PricingFilters',
  components: {
    GlobalBarChangeDialog,
    GlobalFitChangeDialog,
  },
  props: {
    hotels: null,
    rooms: null,
    pricingType: null,
  },
  watch: {
    selectedRoom(val) {
      const { roomId } = this.rooms
        .filter((i) => i.key.roomReferenceType === val)[0].roomType;
      this.$emit('change', {
        hotelId: this.selectedHotel,
        roomReferenceType: val,
        roomId,
      });
    },
  },
  methods: {
    async exportHotelBars() {
      if (this.exportingLiveRates) return;
      this.exportingLiveRates = true;
      try {
        await api.bars.exportHotelBars();
      } finally {
        this.exportingLiveRates = false;
      }
    },
    async exportRollingForecast() {
      if (this.exportingRollingForecast) return;
      this.exportingRollingForecast = true;
      try {
        await api.bars.exportRollingForecast();
      } finally {
        this.exportingRollingForecast = false;
      }
    },
    async exportChosenBars() {
      if (this.exportingBars) return;
      this.exportingBars = true;
      try {
        await api.bars.exportChosenBars();
      } finally {
        this.exportingBars = false;
      }
    },
    async exportChosenRates() {
      if (this.exportingRates) return;
      this.exportingRates = true;
      try {
        await api.fit.exportChosenRates();
      } finally {
        this.exportingRates = false;
      }
    },
    setSelectedHotel(hotelId) {
      this.selectedHotel = hotelId;
    },
    setSelectedRoom(roomReferenceType) {
      this.selectedRoom = roomReferenceType;
    },
    roomDisplayName(item) {
      return item.roomType.roomId;
    },
    handleHotelSelection() {
      this.rooms = [];
      this.selectedRoom = null;
      this.$emit('change', {
        hotelId: this.selectedHotel,
        roomReferenceType: null,
      });
      this.loadRoomTypes();
    },
    handleGlobalChange() {
      this.$emit('global');
    },
    async loadRoomTypes() {
      if (!this.selectedHotel) {
        this.selectedRoom = null;
        return;
      }
      this.rooms = await api.hotels.listAllRooms(this.selectedHotel);
    },
    toLocaleId: chartUtil.toLocaleId,
  },
  data() {
    return {
      selectedHotel: null,
      selectedRoom: null,
      exportingLiveRates: false,
      exportingBars: false,
      exportingRates: false,
      exportingRollingForecast: false,
    };
  },
};
</script>

<style lang="scss">
.pricing-autocomplete {
  .v-list-item__title {
    text-transform: capitalize!important;
  }
}
</style>

<template>
  <v-dialog
    v-model="isOpen"
    width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <HurdleRateSkittle
        :attrs="attrs"
        :on="on"
        :disabled="!!!item"
        :value="(item||{}).hurdleRate"
      />
    </template>
    <v-card v-if="item">
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="row no-gutters ml-0 font-weight-bold">
          {{ $vuetify.lang.t('$vuetify.nav.hurdleRate') }}
          <i class="ml-2">{{ formatDate(date) }}</i>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            md="4"
            class="pa-0 pb-2"
            v-for="field in displayValues"
            :key="field"
          >
            <div class="row px-3 py-2">
              <div class="font-weight-bold col col-md-6 align-self-center">
                {{ $vuetify.lang.t(`$vuetify.hurdleRate.${field}`) }}
              </div>
              <div class="col col-md-6 align-self-center" v-if="field != 'closed'">
                {{ item[field] }}
                <span />
              </div>
              <div class="col col-md-6 align-self-center" v-else>
                {{
                  item[field] == 0 ?
                    $vuetify.lang.t('$vuetify.hurdleRate.open') :
                    $vuetify.lang.t('$vuetify.hurdleRate.close')
                }}
                <span />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import HurdleRateSkittle from '@/components/Pricing/skittles/HurdleRateSkittle.vue';
import moment from 'moment';

const displayValues = [
  'consolidatedFit',
  'demandFit',
  'consolidated',
  'demand',
  'occupancyConsolidated',
  'occupancyDemand',
  'price',
  'revparConsolidated',
  'capacity',
  'hurdleRate',
  'priceVsHurdleRate',
  'closed',
];

export default {
  name: 'HurdleRateDialog',
  components: { HurdleRateSkittle },
  props: {
    item: null,
    hotelName: null,
    date: null,
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
  data() {
    return {
      isOpen: false,
      displayValues,
    };
  },
};
</script>

<style scoped>

</style>

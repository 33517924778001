<template>
  <v-container
    fluid
    class="ma-3 pa-3"
    v-if="$store.state.filter"
  >
    <v-card
      class="pa-10"
      flat
    >
      <CompetitorCharts />
      <CompetitorTable />
    </v-card>
  </v-container>
</template>

<script>
import CompetitorTable from '@/components/Competition/CompetitorTable.vue';
import CompetitorCharts from '@/components/Competition/CompetitorCharts.vue';

export default {
  name: 'Competition',
  components: { CompetitorCharts, CompetitorTable },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    fluid
    class="ma-3 pa-3"
    v-if="$store.state.filter"
  >
    <v-card class="pa-10">
      <PricingFilters
        ref="pricingFilter"
        :hotels="hotels"
        :rooms="rooms"
        pricing-type="bar"
        @change="applyFilter"
        @global="loadBars"
      />
      <v-sheet
        tile
        height="54"
        class="d-flex"
      >
        <v-btn
          icon
          class="ma-2"
          @click="back"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer />
        <v-menu
          v-model="monthSelection"
          transition="scroll-y-transition"
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="text-h5 text-capitalize justify-center"
              label
              color="white"
              v-bind="attrs"
              v-on="on"
              style="width: 290px"
            >
              {{ displayedMonth.locale($vuetify.lang.current).format('MMMM YYYY') }}
            </v-chip>
          </template>
          <v-date-picker
            type="month"
            no-title
            v-model="month"
            @input="monthSelection = false"
            @change="pickMonth"
          />
        </v-menu>
        <v-spacer />
        <v-btn
          icon
          class="ma-2"
          @click="forward"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-sheet>
        <v-progress-linear
          v-if="loading || loadingHurdleRates"
          indeterminate
        />
        <div
          style="height: 4px"
          v-else
        />
        <!-- Main Calendar Component (Events are included here as :events prop) -->
        <v-calendar
          ref="calendar"
          type="month"
          v-model="calendarValue"
          :event-color="getEventColor"
          :events="bars || []"
          :event-height="30"
          :show-month-on-first="false"
          @click:event="showEvent"
        >
          <template v-slot:day-label="day">
            <div class="custom-day-label">
              <span class="day">
                {{ day.day }}
              </span>
              <div
                class="day-suggestion-wrapper"
              >
                <DaySuggestionDialog
                  v-if="stagingChosenBar && stagingChosenBar[day.date] && day.date[3] != '3'"
                  :staging-chosen-bar="stagingChosenBar[day.date]"
                  :current-bar="barsByDay[day.date]"
                  :competitor-prices="competitorPrices"
                  @saved="loadBars"
                />
              </div>
            </div>
          </template>
          <!-- Skittles field -->
          <template v-slot:day="date">
            <div class="hurdle-rate-box pb-1 px-2 d-flex">
              <HurdleRate
                v-if="filter && filter.roomReferenceType && !loadingHurdleRates"
                :item="hurdleRates[date.date]"
                :date="date.date"
              />
              <HurdleRate
                v-else-if="filter"
                :date="date.date"
              />
              <v-spacer />
              <AvgHotelPriceSkittle
                v-if="filter && competitorPrices['hotelAverage']"
                :value="competitorPrices['hotelAverage'][date.date]"
              />
              <AvgHotelPriceSkittle v-else-if="filter" />
              <v-spacer />
              <OccupancySkittle
                v-if="filter"
                :value="occupancies[date.date]"
              />
              <!-- OccupancySkittle v-else-if="filter" /-->
              <v-spacer />
              <AvgCompetitorPriceSkittle
                v-if="filter && roomName && competitorPrices[roomName]"
                :value="competitorPrices[roomName][date.date]"
              />
              <AvgCompetitorPriceSkittle
                v-else-if="filter && !roomName && competitorPrices['global']"
                :value="competitorPrices['global'][date.date]"
              />
              <AvgCompetitorPriceSkittle v-else-if="filter" />
            </div>
          </template>
          <template v-slot:event="{event}">
            <div class="d-flex pt-1 text-md-body-1 text-sm-body-2 font-weight-bold">
              <span class="pl-2">
                {{ event.description }}
                <span v-if="filter && filter.roomReferenceType && roomPrices">
                  {{ roomPrices[filter.roomReferenceType][event.idBar] }}
                  <i class="mdi mdi-currency-eur" />
                </span>
              </span>
              <v-spacer />
              <span class="pr-2">
                {{ event.prepaid }}%
              </span>
            </div>
          </template>
        </v-calendar>
        <!-- Dialog activated on event click -->
        <BarDialog
          :key="(selectedEvent || {}).r"
          :selected-element="selectedElement"
          :selected-open="selectedOpen"
          :interval="selectedEvent"
          :show-competitors="true"
          :competitor-prices="competitorPrices"
          :prepaid="(selectedEvent || {}).prepaid"
          @change="handleBarChange()"
          @close="closeDialog()"
        />
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api';
import BarDialog from '@/components/Pricing/BarDialog.vue';
import PricingFilters from '@/components/Pricing/PricingFilters.vue';
import HurdleRate from '@/components/Pricing/HurdleRateDialog.vue';
import AvgCompetitorPriceSkittle from '@/components/Pricing/skittles/AvgCompetitorPriceSkittle.vue';
import AvgHotelPriceSkittle from '@/components/Pricing/skittles/AvgHotelPriceSkittle.vue';
import OccupancySkittle from '@/components/Pricing/skittles/OccupancySkittle.vue';
import DaySuggestionDialog from '@/components/Pricing/DaySuggestionDialog.vue';
import colors from '@/plugins/colors';

const { eventsColor } = colors;

export default {
  name: 'Pricing',
  components: {
    DaySuggestionDialog,
    OccupancySkittle,
    AvgHotelPriceSkittle,
    AvgCompetitorPriceSkittle,
    HurdleRate,
    PricingFilters,
    BarDialog,
  },
  computed: {
    start() {
      return moment(this.calendarValue || moment.now()).startOf('month');
    },
    end() {
      return moment(this.calendarValue || moment.now()).endOf('month');
    },
    startApi() {
      return moment(this.calendarValue || moment.now())
        .startOf('month')
        .startOf('week')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    },
    endApi() {
      return moment(this.calendarValue || moment.now())
        .endOf('month')
        .endOf('week')
        .add(1, 'day')
        .format('YYYY-MM-DD');
    },
    displayedMonth() {
      return moment(this.month);
    },
  },
  created() {
    this.loadHotels();
    this.loadRoomTypes();
  },
  mounted() {
    const { idHotel, roomReferenceType, month } = this.$route.params;
    if (!idHotel) return;
    if (month) {
      this.calendarValue = month.format('YYYY-MM-DD');
      this.month = month.startOf('month').format('YYYY-MM');
    }
    this.$refs.pricingFilter.setSelectedHotel(idHotel);
    this.$refs.pricingFilter.setSelectedRoom(roomReferenceType);
    this.applyFilter({
      hotelId: idHotel,
      roomReferenceType,
    });
  },
  methods: {
    async loadHotels() {
      this.hotels = await api.hotels.listAll();
    },
    async loadRoomTypes() {
      if (!this.filter || !this.filter.hotelId) {
        return;
      }
      this.rooms = await api.hotels.listAllRooms(this.filter.hotelId);
    },
    pickMonth() {
      this.calendarValue = `${this.month}-01`;
      this.loadBars();
      this.loadHurdleRates();
    },
    getEventColor(event) {
      return event.color;
    },
    closeDialog() {
      this.$refs.calendar.$el.click();
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.selectedElement = null;
    },
    handleBarChange() {
      this.closeDialog();
      this.loadBars();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 50);
      };
      this.selectedEvent = { ...event, r: Math.random() };
      this.selectedElement = nativeEvent.target;
      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.$refs.calendar.$el.click();
        setTimeout(open, 50);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    applyFilter(filter) {
      const hotelChanged = !this.filter
        || this.filter.hotelId !== filter.hotelId
        || !filter.hotelId;
      const roomChanged = !this.filter
        || this.filter.roomReferenceType !== filter.roomReferenceType;
      this.filter = filter;
      if (hotelChanged && this.filter.hotelId) {
        this.hurdleRates = {};
        this.bars = [];
        this.loadBars();
        this.loadRoomTypes();
      } else if (!this.filter.hotelId) {
        this.bars = [];
      }
      if (roomChanged && this.filter.roomReferenceType) {
        this.hurdleRates = {};
        this.roomName = filter.roomId;
        this.loadHurdleRates();
      }
    },
    async loadHurdleRates() {
      if (!this.filter || !this.filter.roomReferenceType) {
        return;
      }
      const body = {
        structures: [this.filter.hotelId],
        roomTypes: [{
          idHotel: this.filter.hotelId,
          roomReferenceType: this.filter.roomReferenceType,
        }],
        periodStart: this.startApi,
        periodEnd: this.endApi,
      };
      this.loadingHurdleRates = true;
      const response = await api.hurdleRates.raws(body);
      const data = response.map((i) => ({
        ...i,
        ...i.key,
      }));
      this.hurdleRates = data.reduce((acc, item) => {
        const { date } = item.key;
        const res = { ...acc };
        res[date] = item;
        return res;
      }, {});
      this.loadingHurdleRates = false;
    },
    async loadOccupancy() {
      if (!this.filter.hotelId) return;
      const apiFilter = {
        periodStart: this.start.format('YYYY-MM-DD'),
        periodEnd: this.end.format('YYYY-MM-DD'),
        structures: [this.filter.hotelId],
        comparison: 'forecast',
      };
      if (this.filter.roomReferenceType) {
        apiFilter.roomTypes = [{
          idHotel: this.filter.hotelId,
          roomReferenceType: this.filter.roomReferenceType,
        }];
      }
      const res = await api.hotelHistory.fetchOccupancyStats(apiFilter);
      this.occupancies = res.datasets[1].data.reduce((map, obj) => {
        // eslint-disable-next-line no-param-reassign
        map[moment(obj.x).format('YYYY-MM-DD')] = `${obj.y}%`;
        return map;
      }, {});
    },
    async loadBars() {
      if (!this.filter || !this.filter.hotelId) {
        return;
      }
      this.stagingChosenBar = {};
      this.competitorPrices = [];
      this.occupancies = [];
      const body = {
        structures: [this.filter.hotelId],
        roomReferenceTypes: [this.filter.roomReferenceType],
        periodStart: this.startApi,
        periodEnd: this.endApi,
      };
      this.loading = true;
      const data = await api.bars.fetch(body);
      this.roomPrices = data.prices;
      this.bars = data.events.map((e) => ({
        ...e,
        name: `
          ${e.description}
            Prepaid: ${e.prepaid}%
          `,
        color: eventsColor[e.idBar],
        title: true,
        r: Math.random(),
      }));
      const bbd = {};
      this.bars.forEach((e) => {
        for (let m = moment(e.start); m.isSameOrBefore(e.end); m.add(1, 'days')) {
          bbd[m.format('YYYY-MM-DD')] = e;
        }
      });
      this.barsByDay = bbd;
      await this.loadCompetitorPrices();
      await this.loadOccupancy();
      await this.loadStagingChosenBar();
      this.loading = false;
    },
    async loadStagingChosenBar() {
      const data = await api.stagingChosenBar.getByFilter({
        structures: [this.filter.hotelId],
        periodStart: this.startApi,
        periodEnd: this.endApi,
      });
      data.forEach((cb) => {
        this.stagingChosenBar[cb.date] = cb;
      });
    },
    async loadCompetitorPrices() {
      const body = {
        structures: [this.filter.hotelId],
        periodStart: this.start.format('YYYY-MM-DD'),
        periodEnd: this.end.format('YYYY-MM-DD'),
      };
      this.competitorPrices = await api.bars.fetchCompetitorPrices(body);
    },
    back() {
      this.$refs.calendar.prev();
      this.month = moment(this.calendarValue).startOf('month').format('YYYY-MM');
      this.loadBars();
      this.loadHurdleRates();
    },
    forward() {
      this.$refs.calendar.next();
      this.month = moment(this.calendarValue).startOf('month').format('YYYY-MM');
      this.loadBars();
      this.loadHurdleRates();
    },
  },
  data() {
    const currentMonth = moment().startOf('month');
    return {
      calendarValue: currentMonth.format('YYYY-MM-DD'),
      month: currentMonth.format('YYYY-MM'),
      events: [],
      bars: [],
      loading: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      monthSelection: false,
      filter: null,
      hurdleRates: {},
      loadingHurdleRates: false,
      hotels: [],
      rooms: [],
      competitorPrices: [],
      occupancies: [],
      roomName: null,
      roomPrices: null,
      stagingChosenBar: {},
      barsByDay: {},
    };
  },
};
</script>

<style lang="scss">
.v-calendar {
  .v-event {
    margin-left: 2.5% !important;
    margin-top: 24px!important;
  }
}
.hurdle-rate-box {
  position: absolute;
  top: 40px;
  width: 100%;
}
.v-calendar-weekly__week {
  min-height: 115px!important;
}
.v-calendar-weekly__day-label {
  margin-top: 0!important;
  .v-btn{
    font-weight: bold!important;
    font-size: 16px!important;
  }
}
.custom-day-label{
  position: relative;
  min-height: 50px;
  text-align: center;
  .day {
    font-weight: bold!important;
    font-size: 16px!important;
  }
  .day-suggestion-wrapper{
    position: absolute;
    top: -5px;
    right: 5px;
  }
}
</style>

<!-- Second PopUp on FIT Rates -->
<template>
  <v-dialog
    v-model="isOpen"
    width="950px"
  >
    <template
      v-slot:activator="{ on, attrs }"
      v-if="interval"
    >
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="isOpen = true"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card
      color="grey lighten-4"
      min-width="350px"
      flat
      v-if="start"
      :key="interval.r"
    >
      <v-toolbar
        :color="interval.color"
        dark
      >
        <v-toolbar-title class="row no-gutters ml-0 font-weight-bold">
          {{ $vuetify.lang.t('$vuetify.pricing.updateRate') }}
          <span class="font-italic ml-4">
            {{ interval.hotelName }}&nbsp;
            {{ formatDate(interval.start) }}
            <i class="mdi mdi-arrow-right" />
            {{ formatDate(interval.end) }}
          </span>
        </v-toolbar-title>
        <ConfirmBarChange
          :disabled="!valid"
          @confirm="updateFits()"
        />
        <v-btn icon>
          <v-icon @click="isOpen = false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form
          class="row my-4"
          v-model="valid"
        >
          <v-col md="6">
            <v-select
              :items="fits"
              label="Fit"
              multiple
              v-model="fit"
              @change="computePreviewIntervals"
            />
          </v-col>
          <v-col md="6">
            <v-text-field
              type="number"
              min="0"
              max="100"
              suffix="%"
              v-model="prepaid"
              label="Promo"
              :rules="[v => !!v && v>= 0 && v <= 100
                || $vuetify.lang.t('$vuetify.pricing.prepaidValidation') ]"
              required
              @change="computePreviewIntervals"
            />
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedStart"
                  label="from"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="start"
                :min="interval.start"
                :max="end"
                @input="startDatePicker = false"
                @change="computePreviewIntervals"
              />
            </v-menu>
          </v-col>
          <v-col md="6">
            <v-menu
              v-model="endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedEnd"
                  label="to"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="end"
                :min="start"
                :max="interval.end"
                @input="endDatePicker = false"
                @change="computePreviewIntervals"
              />
            </v-menu>
          </v-col>
        </v-form>
        <div class="title">
          {{ $vuetify.lang.t('$vuetify.pricing.preview') }}
        </div>
        <!-- Show tables on the full date range, one per each different rates -->
        <div
          v-for="item in preview"
          :key="`price-preview-${item.id}`"
        >
          <div class="subtitle-2 mb-0 mt-4 row">
            <v-col
              md="6"
            >
              <span class="font-italic">
                {{ formatDate(item.start) }}
                <i class="mdi mdi-arrow-right" />
                {{ formatDate(item.end) }}
              </span>
            </v-col>
            <v-spacer />
            <v-col
              md="6"
              class="text-end"
            >
              <span
                class="mr-5"
                v-if="fit.length == 3 && item.update"
              >
                <span> FIT 20, 23, 25 </span>
              </span>
              <span
                v-else-if="item.update"
                class="font-weight-bold mr-5"
              >
                {{ 'FIT ' }}
                {{ fit.includes(0) ? (fit.length > 1 ? '20, ' : '20 ') : '' }}
                {{ fit.includes(1) ? (fit.includes(2) ? '23, ' : '23 ') : '' }}
                {{ fit.includes(2) ? '25 ' : '' }}
              </span>
              <span
                class="mr-2"
                v-if="interval.prepaid === item.prepaid"
              >
                {{ $vuetify.lang.t('$vuetify.pricing.prepaid') }} {{ item.prepaid }}%
              </span>
              <span
                v-else
                class="font-weight-bold"
              >
                {{ $vuetify.lang.t('$vuetify.pricing.prepaid') }} {{ interval.prepaid }}%
                <i class="mdi mdi-arrow-right" />
                {{ item.prepaid }}%
              </span>
            </v-col>
          </div>
          <RateTable
            v-if="item.prices"
            :items="item.prices"
            :new-prepaid="item.prepaid"
            :prepaid="interval.prepaid"
            :competitor-prices="competitorPrices"
            :show-competitors="showCompetitors"
            :start-date="item.start"
            :end-date="item.end"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import RateTable from '@/components/FitRates/RateTable.vue';
import api from '@/api';
import moment from 'moment';
import ConfirmBarChange from '@/components/Pricing/ConfirmBarChange.vue';
import hrUtils from '@/components/lib/hurdleRateUtils';

const fits = [
  { value: 0, text: 'FIT 20' },
  { value: 1, text: 'FIT 23' },
  { value: 2, text: 'FIT 25' },
];
export default {
  name: 'EditRateDialog',
  components: { ConfirmBarChange, RateTable },
  props: {
    interval: null,
    currentPrices: null,
    competitorPrices: null,
    startDate: null,
    endDate: null,
    showCompetitors: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    window.moment = moment;
    if (this.startDate.substring(0, 4) === '2023') this.fits = [fits[2]];
    else this.fits = [...fits];
    this.start = this.interval.start;
    this.end = this.interval.end;
    this.hotelName = this.interval.hotelName;
    this.fit = this.fits.map((x) => x.value);
    this.prepaid = this.interval.prepaid;
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.start = this.interval.start;
        this.end = this.interval.end;
        this.hotelName = this.interval.hotelName;
        this.fit = this.fits.map((x) => x.value);
        this.prepaid = this.interval.prepaid;
        this.fetchHotelBars();
      }
    },
    currentPrices: {
      handler(val) {
        this.updatedPrices = val.map((i) => ({ ...i }));
        this.computePreviewIntervals();
      },
      deep: true,
    },
  },
  computed: {
    formattedStart() {
      return this.formatDate(this.start);
    },
    formattedEnd() {
      return this.formatDate(this.end);
    },
    rates() {
      return (this.currentPrices || []).flatMap((bar) => bar.hurdleRates || []);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async updateFits() {
      this.isOpen = false;
      try {
        const fitRates = [];
        if (this.fit.includes(0)) fitRates.push(this.interval.rateId20);
        if (this.fit.includes(1)) fitRates.push(this.interval.rateId23);
        if (this.fit.includes(2)) fitRates.push(this.interval.rateId25);
        const fitNames = [];
        if (this.fit.includes(0)) fitNames.push(fits[0].text);
        if (this.fit.includes(1)) fitNames.push(fits[1].text);
        if (this.fit.includes(2)) fitNames.push(fits[2].text);
        await api.fit.updateFits({
          rateIds: fitRates,
          rateNames: fitNames,
          from: this.start,
          to: this.end,
          promo: this.prepaid,
        });
        this.$emit('change');
      } catch (e) {
        this.$log.info(e);
      }
    },
    sameRow(a, b) {
      return a.roomId === b.roomId
      && a.roomName === b.roomName
      && a.hotelId === b.hotelId;
    },
    async fetchHotelBars() {
      this.loading = true;
      const preview = JSON.parse(JSON.stringify(this.currentPrices));
      const mergedPrices = JSON.parse(JSON.stringify(this.currentPrices));
      for (let i = 0; i < mergedPrices.length; i += 1) {
        mergedPrices[i] = {
          ...preview[i],
          newPrice20: preview[i].val20,
          newPrice23: preview[i].val23,
          newPrice25: preview[i].val25,
        };
      }
      this.updatedPrices = mergedPrices;
      this.computePreviewIntervals();
      this.loading = false;
    },
    computePreviewIntervals() {
      if (!this.interval || !this.fit) return;
      const data = [];
      this.updatedPrices.forEach((v, i) => {
        if (this.fit.includes(0)) {
          this.updatedPrices[i].fit20 = this.prepaid;
          this.updatedPrices[i].newPrice20 = Math.round(
            Math.round(
              (this.currentPrices[i].val20 / ((100 - this.interval.prepaid) / 100)),
            )
            * ((100 - this.prepaid) / 100),
          );
        } else {
          this.updatedPrices[i].fit20 = this.currentPrices[i].fit20;
          this.updatedPrices[i].newPrice20 = this.currentPrices[i].val20;
        }
        if (this.fit.includes(1)) {
          this.updatedPrices[i].fit23 = this.prepaid;
          this.updatedPrices[i].newPrice23 = Math.round(
            (this.currentPrices[i].val23 / ((100 - this.interval.prepaid) / 100))
            * ((100 - this.prepaid) / 100),
          );
        } else {
          this.updatedPrices[i].fit23 = this.currentPrices[i].fit23;
          this.updatedPrices[i].newPrice23 = this.currentPrices[i].val23;
        }
        if (this.fit.includes(2)) {
          this.updatedPrices[i].fit25 = this.prepaid;
          this.updatedPrices[i].newPrice25 = Math.round(
            (this.currentPrices[i].val25 / ((100 - this.interval.prepaid) / 100))
            * ((100 - this.prepaid) / 100),
          );
        } else {
          this.updatedPrices[i].fit25 = this.currentPrices[i].fit25;
          this.updatedPrices[i].newPrice25 = this.currentPrices[i].val25;
        }
      });
      if (this.interval.start !== this.start && this.interval.end === this.end) {
        data.push({
          ...this.interval,
          end: moment(this.start).subtract(1, 'days').format('YYYY-MM-DD'),
          prices: this.currentPrices,
          id: Math.random(),
        });
        data.push({
          ...this.interval,
          start: this.start,
          idBar: this.fit,
          prepaid: this.prepaid,
          prices: this.updatedPrices,
          update: true,
          id: Math.random(),
        });
      } else if (this.interval.end !== this.end && this.interval.start === this.start) {
        data.push({
          ...this.interval,
          end: this.end,
          idBar: this.fit,
          prepaid: this.prepaid,
          prices: this.updatedPrices,
          update: true,
          id: Math.random(),
        });
        data.push({
          ...this.interval,
          start: moment(this.end).add(1, 'days').format('YYYY-MM-DD'),
          prices: this.currentPrices,
          id: Math.random(),
        });
      } else if (this.interval.end !== this.end && this.interval.start !== this.start) {
        data.push({
          ...this.interval,
          end: moment(this.start).subtract(1, 'days').format('YYYY-MM-DD'),
          prices: this.currentPrices,
          id: Math.random(),
        });
        data.push({
          idBar: this.fit,
          prepaid: this.prepaid,
          prices: this.updatedPrices,
          update: true,
          start: this.start,
          end: this.end,
          id: Math.random(),
        });
        data.push({
          ...this.interval,
          start: moment(this.end).add(1, 'days').format('YYYY-MM-DD'),
          prices: this.currentPrices,
          id: Math.random(),
        });
      } else {
        data.push({
          ...this.interval,
          idBar: this.fit,
          prepaid: this.prepaid,
          prices: (this.fit.length === 3 && this.prepaid === this.interval.prepaid)
            ? this.currentPrices
            : this.updatedPrices,
          update: true,
          id: Math.random(),
        });
      }
      this.preview = data;
    },
  },
  data() {
    return {
      preview: [],
      updatedPrices: [],
      start: null,
      end: null,
      hotelName: null,
      bar: null,
      prepaid: null, // Bar Prepaid = Fit Promo (this.promo)
      fits,
      loading: false,
      isOpen: false,
      startDatePicker: false,
      endDatePicker: false,
      valid: true,
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <v-menu
    v-model="selectionOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        label
        color="white"
        v-bind="attrs"
        v-on="on"
        class="filter-text"
      >
        <span
          v-if="selection.length === 0"
          class="selection-label"
        >
          {{ $vuetify.lang.t('$vuetify.filterBar.destination') }}
        </span>
        <v-chip
          v-else
          color="secondary"
          label
          small
        >
          <span>{{ displayValue }}</span>
        </v-chip>
        <span
          v-if="selection.length > 1"
          class="grey--text caption ml-1"
        >
          (+{{ selection.length - 1 }})
        </span>
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="py-2 pl-0 pr-2">
        <v-treeview
          selectable
          v-model="selection"
          return-object
          dense
          :items="choices"
        >
          <template v-slot:label="data">
            <span
              v-if="data.leaf"
              class="selection-label"
            >{{ data.item.name }}</span>
            <span
              v-else
              class="selection-label"
            >{{ $vuetify.lang.t(`$vuetify.regions.${data.item.name.toLowerCase()}`) }}</span>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'StructurePicker',
  props: {
    choices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    forwardSelection() {
      this.$emit('change', this.selection);
    },
  },
  computed: {
    displayValue() {
      if (this.selection.length === 0) return '';
      return this.selection[0].name;
    },
  },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.selection = state.filter.structures
          .map((idHotel) => this.hotelIdsToNode[idHotel]);
      }
    }));
  },
  mounted() {
    const hotelIdsToNode = {};
    this.choices.forEach((e) => {
      e.children.forEach((children) => {
        hotelIdsToNode[children.id_hotel] = children;
      });
    });
    this.hotelIdsToNode = hotelIdsToNode;
  },
  watch: {
    selectionOpen(val) {
      if (!val) {
        this.forwardSelection();
      }
    },
    choices() {
      this.selection = [];
    },
  },
  data() {
    return {
      selectionOpen: false,
      selection: [],
      hotelIdsToNode: {},
    };
  },
};
</script>

<style lang="scss">
.selection-label {
  text-transform: capitalize!important;
}
</style>

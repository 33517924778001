<template>
  <v-container
    fluid
    class="ma-3 pa-3"
    v-if="$store.state.filter"
  >
    <v-card
      class="pa-5"
      flat
    >
      <v-card-text>
        <v-row class="pb-4">
          <v-col class="text-end">
            <v-btn
              class="primary align-self-center"
              rounded
              @click="exportCsv"
            >
              {{ $vuetify.lang.t('$vuetify.export') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          :loading.sync="loading"
          :page.sync="page"
          :options.sync="options"
          :server-items-length="itemsCount"
          :items-per-page="options.itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30]
          }"
        >
          <template v-slot:item.priceVsHurdleRate="{ item }">
            <v-chip
              style="width: 100%"
              class="justify-center"
              :color="getColor(item.priceVsHurdleRate)"
              dark
              small
            >
              {{ item.priceVsHurdleRate }}
              <i class="mdi mdi-currency-eur ml-1" />
            </v-chip>
          </template>
          <template v-slot:item.key.idHotel="{ item }">
            {{ item.hotelName }}
          </template>
          <template v-slot:item.occupancyDemand="{ item }">
            {{ fixedDigits(item.occupancyDemand, 2).toLocaleString($vuetify.lang.current) }}%
          </template>
          <template v-slot:item.occupancyConsolidated="{ item }">
            {{ fixedDigits(item.occupancyConsolidated, 2).toLocaleString($vuetify.lang.current) }}%
          </template>
          <template v-slot:item.key.roomReferenceType="{ item }">
            <span v-if="item.roomType">
              {{ item.roomType.roomId }}
            </span>
            <span v-else>{{ item.key.roomReferenceType }}</span>
          </template>
          <template v-slot:item.price="{ item }">
            <span v-if="item.price">
              {{ item.price }}<i class="mdi mdi-currency-eur" />
            </span>
            <span v-else><i class="mdi mdi-minus" /></span>
          </template>
          <template v-slot:item.closed="{ item }" >
            <v-row class=" ma-0 pa-0 justify-center">
              <v-switch
                v-model="item.closed"
                inset
                dense
                class="ma-0 red-off-state"
                :ripple="false"
                :color="false ? 'red' : 'primary'"
                @change="updateClosed(item)"
              />
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import chartUtil from '@/components/lib/chartUtil';

const headers = [
  {
    text: 'date',
    value: 'key.date',
    width: '7%',
  },
  {
    text: 'idHotel',
    value: 'key.idHotel',
    align: 'center',
    width: '7%',
  },
  // {
  //   text: 'policy',
  //   value: 'key.cancellation',
  //   align: 'center',
  //   width: '5%',
  // },
  {
    text: 'consolidatedFit',
    value: 'consolidatedFit',
    align: 'center',
  },
  {
    text: 'demandFit',
    value: 'demandFit',
    align: 'center',
  },
  {
    text: 'consolidated',
    value: 'consolidated',
    align: 'center',
  },
  {
    text: 'demand',
    value: 'demand',
    align: 'center',
  },
  {
    text: 'occupancyConsolidated',
    value: 'occupancyConsolidated',
    align: 'center',
  },
  {
    text: 'occupancyDemand',
    value: 'occupancyDemand',
    align: 'center',
  },
  {
    text: 'priceFit',
    value: 'price',
    align: 'center',
    width: '5%',
  },
  {
    text: 'revparConsolidated',
    value: 'revparConsolidated',
    align: 'center',
  },
  // {
  //   text: 'minStay',
  //   value: 'minStay',
  //   width: '5%',
  //   align: 'center',
  // },
  // {
  //   text: 'maxStay',
  //   value: 'maxStay',
  //   width: '5%',
  //   align: 'center',
  // },
  // {
  //   text: 'dateCta',
  //   value: 'dateCta',
  //   width: '5%',
  //   align: 'center',
  // },
  // {
  //   text: 'dateCtd',
  //   value: 'dateCtd',
  //   width: '5%',
  //   align: 'center',
  // },
  {
    text: 'capacity',
    value: 'capacity',
    align: 'center',
  },
  {
    text: 'hurdleRate',
    align: 'center',
    value: 'hurdleRate',
  },
  {
    text: 'priceVsHurdleRate',
    value: 'priceVsHurdleRate',
    align: 'center',
    width: '7%',
  },
  {
    text: 'closed',
    value: 'closed',
    align: 'center',
    width: '7%',
  },
];

export default {
  name: 'HurdleRates',
  mounted() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        this.options.page = 1;
        this.fetchData();
      }
    }));
  },
  methods: {
    toLocaleId: chartUtil.toLocaleId,
    fixedDigits: chartUtil.approximateNumber,
    getColor(revpar) {
      if (revpar < 0) return '#CB6868';
      if (revpar > 0) return '#9EAEA4';
      return 'indigo';
    },
    async updateClosed(item) {
      const res = await api.hurdleRatesFit.updateClosed({
        date: item.key.date,
        hotelId: item.key.idHotel,
        closed: item.closed ? 0 : 1,
      });
    },
    async exportCsv() {
      await api.hurdleRatesFit.exportCSV(this.$store.state.filter);
    },
    async fetchData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const data = await api.hurdleRatesFit.get({
        filter: this.$store.state.filter,
        pageSize: this.options.itemsPerPage,
        pageNumber: this.options.page - 1,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0] || false,
      });
      data.content.forEach((e) => {
        e.closed = (e.closed === 0);
      });
      this.updateTable(data);
    },
    updateTable(data) {
      this.items = data.content;
      this.pageCount = data.totalPages;
      this.itemsCount = data.totalElements;
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.pageCount > 1) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      page: 1,
      pageCount: 2,
      itemsPerPage: 10,
      itemsCount: 10,
      options: {
        sortBy: ['key.date'],
        sortDesc: [false],
        itemsPerPage: 10,
        page: 1,
      },
      headers: headers.map((item) => (
        {
          ...item,
          text: this.$vuetify.lang.t(`$vuetify.hurdleRate.${item.text}`),
        })),
      sortBy: 'key.date',
      sortDesc: false,
      sortDirection: 'ASC',
    };
  },
};
</script>

<style scoped>

</style>

<!-- Bottom Bar Chart, with Sales Count, Occupancy and Reservations -->
<template>
  <v-container fluid>
    <v-card
      class="horizontal-box"
      flat
    >
      <v-card-title class="justify-center secondary--text pt-5">
        <v-row no-gutters>
          <v-col
            md="4"
            offset-md="4"
          >
            <v-menu
              origin="center center"
              auto
              :value="chartSelectionOpen"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  color="white"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2"
                >
                  <span class="font-weight-bold title secondary--text">
                    {{ $vuetify.lang.t(currentChart.name) }}
                    <v-icon>mdi-menu-down</v-icon>
                  </span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="chart in charts"
                  :key="chart.type"
                  dense
                  link
                >
                  <v-list-item-title
                    class="text-center"
                    @click="changeChart(chart)"
                  >
                    <span class="justify-center">{{ $vuetify.lang.t(chart.name) }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer />
          <v-progress-circular
            color="primary"
            size="24"
            width="3"
            indeterminate
            v-if="loading"
          />
        </v-row>
      </v-card-title>
      <v-card-text v-if="stats">
        <ADRBarChart
          v-if="!noData"
          class="chart-container"
          :chart-data="stats"
          :options="chartOptions"
        />
        <div
          v-else-if="!loading"
          class="chart-container no-data row"
        >
          <div class="col align-self-center text-center">
            {{ $vuetify.lang.t('$vuetify.dashboard.noData') }}
          </div>
        </div>
        <div class="pt-4 pl-2 d-flex">
          <div class="d-inline-flex mr-2">
            {{ $vuetify.lang.t('$vuetify.dashboard.groupBy') }}
          </div>
          <div class="d-inline-flex mr-10">
            <DateTypePicker
              class="d-inline"
              :show-icon="true"
              :items="dateTypes"
              text="$vuetify.dashboard.groupBy"
              @change="changeGroupDate"
            />
          </div>
          <div class="d-inline-flex">
            <span class="align-self-center mr-2">
              {{ $vuetify.lang.t('$vuetify.dashboard.comparison') }}
            </span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  label
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  class="filter-text"
                >
                  {{ $vuetify.lang.t(currentComparison.displayName) }}
                  <v-icon>mdi-menu-down</v-icon>
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  class="text-center"
                  v-for="(item, index) in comparisonTypes.filter(
                    (it) =>{
                      return !isComparisonDisabled(it)
                    })"
                  :key="index"
                  :disabled="isComparisonDisabled(item)"
                  @click="selectComparison(item)"
                >
                  <v-list-item-title>{{ $vuetify.lang.t(item.displayName) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="d-inline-flex ml-auto">
            <v-btn
              @click="exportData"
              :loading="exporting"
              color="primary"
              fab
              x-small
              dark
              elevation="0"
              class="text-left"
              :disabled="loading || exporting"
            >
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-card-text
        v-else
        class="text-center"
      >
        <v-progress-circular
          class="mt-15"
          color="primary"
          indeterminate
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import api from '@/api';
import colors from '@/plugins/colors';
import util from '@/components/lib/chartUtil';
import DateTypePicker from '@/components/main/filter_bar_components/DateTypePicker.vue';
import moment from 'moment';
import ADRBarChart from '@/components/Dashboard/charts/ADRBarChart.vue';
import FileDownload from 'js-file-download';

const colorsLength = colors.barChartColor.length;
const comparisonTypes = [
  {
    type: 'none',
    displayName: '$vuetify.dashboard.actual',
    disabled: false,
  },
  {
    type: 'structure',
    displayName: '$vuetify.dashboard.structure',
    disabled: false,
  },
  {
    type: 'historical',
    displayName: '$vuetify.dashboard.historical',
    disabled: false,
  },
  {
    type: 'forecast',
    displayName: '$vuetify.dashboard.forecast',
    disabled: true,
  },
  {
    type: 'budget',
    displayName: '$vuetify.dashboard.budget',
    disabled: true,
  },
  {
    type: 'consolidato',
    displayName: '$vuetify.dashboard.consolidated',
    disabled: false,
    vpOnly: true,
  },
];

const charts = [
  {
    type: 'totalNights',
    name: '$vuetify.dashboard.totalCountDuo',
    forecastEnabled: true,
    budgetEnabled: true,
  },
  // {
  //   type: 'pax',
  //   name: '$vuetify.dashboard.pax',
  // },
  {
    type: 'occupancy',
    name: '$vuetify.dashboard.occupancy',
    forecastEnabled: true,
    budgetEnabled: true,
  },
  {
    type: 'reservations',
    name: '$vuetify.dashboard.reservation',
  },
];
const dateTypes = [
  {
    type: 'night',
    name: '$vuetify.dashboard.stayDate',
  },
  {
    type: 'bookingDate',
    name: '$vuetify.dashboard.bookingDate',
  },
  {
    type: 'dateUpdate',
    name: '$vuetify.dashboard.lastUpdate',
  },
];
export default {
  name: 'RoomNight',
  components: { ADRBarChart, DateTypePicker },
  created() {
    this.$store.subscribe(((mutation, state) => {
      if (mutation.type === 'filterChange') {
        if (JSON.stringify(this.filter) !== JSON.stringify(state.filter)) {
          this.filter = state.filter;
          this.mutateName(state.filter.roomServices);
          this.loadStats();
        }
      }
    }));
  },
  mounted() {
    this.filter = this.$store.state.filter;
    this.loadStats();
  },
  methods: {
    mutateName(serv) {
      if (serv.length === 0 || (serv.includes('Rooms') && serv.length > 1)) this.charts[0].name = '$vuetify.dashboard.totalCountDuo';
      if (serv.includes('Rooms') && serv.length === 1) this.charts[0].name = '$vuetify.dashboard.totalCountRoom';
      if (!serv.includes('Rooms') && serv.length > 0) this.charts[0].name = '$vuetify.dashboard.totalCountService';
    },
    isComparisonDisabled(item) {
      if (!this.filter) return true;
      if (item.type === 'historical' && !this.filter.periodStart) return true;
      if (item.type === 'forecast') return !this.currentChart.forecastEnabled;
      if (item.type === 'budget') return !this.currentChart.budgetEnabled;
      if (item.type === 'consolidato') return !(this.filter.channels.includes('VUOTO PIENO') && this.filter.roomServices.includes('Rooms'));
      return item.disabled;
    },
    selectComparison(comparison) {
      this.currentComparison = comparison;
      this.loadStats();
    },
    async changeFilterDate(dateType) {
      if (dateType.type === this.filterDate.type) return;
      this.filterDate = dateType;
      await this.loadStats();
    },
    async changeGroupDate(dateType) {
      if (dateType.type === this.groupDate.type) return;
      this.groupDate = dateType;
      await this.loadStats();
    },
    async changeChart(chart) {
      if (chart.type === this.currentChart.type) return;
      this.currentChart = chart;
      if (this.currentComparison.type === 'forecast' && !chart.forecastEnabled) {
        // eslint-disable-next-line prefer-destructuring
        this.currentComparison = comparisonTypes[0];
      }
      await this.loadStats();
    },
    async loadHistoricalStats() {
      let stats;
      const apiFilter = {
        ...this.filter,
        periodStart: moment(this.filter.periodStart)
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        periodEnd: moment(this.filter.periodEnd)
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        groupingDate: this.groupDate.type,
        comparison: this.currentComparison.type,
      };
      if (this.currentChart.type === 'totalNights') {
        stats = await api.hotelHistory.fetchRoomNightStats(apiFilter);
      } else if (this.currentChart.type === 'pax') {
        stats = await api.hotelHistory.fetchPaxStats(apiFilter);
      } else if (this.currentChart.type === 'reservations') {
        stats = await api.hotelHistory.fetchReservationStats(apiFilter);
      } else if (this.currentChart.type === 'occupancy') {
        stats = await api.hotelHistory.fetchOccupancyStats(apiFilter);
      }
      return stats.datasets.map((ds) => ({
        ...ds,
        label: `${this.$vuetify.lang.t('$vuetify.dashboard.historical')}`,
        data: ds.data.map((e) => ({
          ...e,
          x: moment(e.x).add(1, 'year')
            .format('YYYY-MM-DD'),
        })),
      }));
    },
    async loadStats() {
      if (!this.filter || this.loading) return;
      this.loading = true;
      const apiFilter = {
        ...this.filter,
        groupingDate: this.groupDate.type,
        comparison: this.currentComparison.type,
      };
      if (this.currentComparison.type === 'consolidato'
        && (!this.filter.channels.includes('VUOTO PIENO')
        || !this.filter.roomServices.includes('Rooms'))) {
        // eslint-disable-next-line prefer-destructuring
        this.currentComparison = comparisonTypes[0];
      }
      let stats;
      if (this.currentChart.type === 'totalNights') {
        stats = await api.hotelHistory.fetchRoomNightStats(apiFilter);
      } else if (this.currentChart.type === 'pax') {
        stats = await api.hotelHistory.fetchPaxStats(apiFilter);
      } else if (this.currentChart.type === 'reservations') {
        stats = await api.hotelHistory.fetchReservationStats(apiFilter);
      } else if (this.currentChart.type === 'occupancy') {
        stats = await api.hotelHistory.fetchOccupancyStats(apiFilter);
        if (this.currentComparison.type === 'consolidato') {
          stats.datasets[0].data.forEach((base) => {
            const number = stats.datasets[1].data.find((element) => element.x === base.x);
            if (number) {
              number.y = (number.y / base.y) * 100;
              number.y = Math.min(number.y, 100).toFixed(2);
            }
            // eslint-disable-next-line no-param-reassign
            base.y = 100;
          });
        }
      }
      if (this.currentComparison.type === 'historical' && this.filter.periodStart) {
        const historical = await this.loadHistoricalStats();
        stats.datasets = stats.datasets.concat(historical);
      }
      stats.datasets = stats.datasets.map((d, i) => ({
        ...d,
        backgroundColor: colors.barChartColor[i % colorsLength],
      }));
      const boundary = util.computeChartBoundaries(stats);
      this.chartOptions = {
        ...util.buildTimeChartOptions(this.filter, boundary.min, boundary.max),
      };
      this.noData = stats.datasets.filter((dataset) => dataset.data.length > 0).length === 0;
      this.stats = stats;
      if (this.filter.channels.includes('VUOTO PIENO') && this.filter.roomServices.includes('Rooms')) this.vpFilter = false;
      else this.vpFilter = true;
      this.loading = false;
    },
    exportData() {
      if (this.loading || this.exporting) return;
      this.exporting = true;
      const date = moment().format('DD-MM-YYYY-HH:mm');
      const chartName = this.$vuetify.lang.t(this.currentChart.name);
      const days = new Set();
      this.stats.datasets.flatMap((x) => x.data
        .map((dp) => moment(dp.x).format('MM-DD-YYYY')))
        .forEach((x) => days.add(x));
      const rows = [...days];
      rows.sort();
      const headers = ['Date'];
      if (this.stats.datasets.length === 1) {
        headers.push(chartName);
      } else {
        this.stats.datasets.forEach((ds) => headers.push(`${ds.label}`));
      }
      const matrix = [];
      const daysIdx = {};
      matrix.push(headers);
      for (let i = 0; i < rows.length; i += 1) {
        matrix[i + 1] = new Array(headers.length);
        matrix[i + 1][0] = moment(rows[i]).format('DD-MM-YYYY');
        daysIdx[moment(rows[i]).format('DD-MM-YYYY')] = i + 1;
      }
      this.stats.datasets.forEach((ds, dsIdx) => {
        ds.data.forEach((dp) => {
          matrix[daysIdx[moment(dp.x).format('DD-MM-YYYY')]][dsIdx + 1] = dp.y;
        });
      });
      const csvText = matrix.map((array) => array.join(',')).join('\n');
      FileDownload(csvText, `${chartName.toLowerCase()
        .replace(' ', '_')}_export_${date}.csv`);
      this.exporting = false;
    },
  },
  data() {
    return {
      loading: false,
      chartSelectionOpen: false,
      charts,
      currentChart: charts[0],
      dateTypes,
      groupDate: dateTypes[0],
      comparisonTypes,
      currentComparison: comparisonTypes[0],
      filter: null,
      vpFilter: true,
      stats: null,
      chartOptions: null,
      noData: true,
      exporting: false,
    };
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filter && _vm.filter.structures.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.loading,"icon":""},on:{"click":_vm.previous}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"transition":"scroll-y-transition","offset-y":"","close-on-content-click":false,"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"text-h5 text-capitalize justify-center",staticStyle:{"width":"290px"},attrs:{"label":"","color":"white"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.competitors.pricing'))+" ")])]}}],null,false,879875653),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","disabled":_vm.loading},on:{"change":_vm.pickDate},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.loading,"icon":""},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.competitorHeaders,"items":_vm.competitors,"item-key":"idHotel","dense":"","disable-pagination":"","disable-sort":"","loading":_vm.loading,"group-by":['hotelName','room', 'mealpan'],"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header,i){return _c('th',{key:("pth-" + i),class:((i === 0? 'text-left': 'text-center') + " pa-3"),staticStyle:{"border-bottom":"1px solid rgba(0,0,0,0.12)"},attrs:{"role":"columnheader","width":header.width||''}},[(i <=4 )?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(header.text)))]):_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(header.text))+" ")])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
var idx = ref.idx;
return [_c('tr',{class:item.competitorName === item.hotelName ? 'font-weight-bold': ''},[(item.competitorName === item.hotelName)?_c('td',[_c('a',{staticStyle:{"color":"#96b2dd"},on:{"click":function($event){return _vm.toPricing(item)}}},[_vm._v(" "+_vm._s(item.competitorName)+" ")])]):_c('td',[_vm._v(" "+_vm._s(item.competitorName)+" ")]),_c('td'),_c('td'),_vm._l((_vm.competitorHeaders.length - 4),function(i){return _c('td',{key:("v_" + idx + "_" + i),staticClass:"text-center"},[_vm._v(" "+_vm._s(item[_vm.competitorHeaders[i+3].value])+" "),(item[_vm.competitorHeaders[i+3].value])?_c('i',{staticClass:"mdi-currency-eur mdi"}):_c('i',{staticClass:"mdi-minus mdi"})])})],2)]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"primary lighten-1"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1),_vm._v(" "+_vm._s(group)+" ")],1),_c('td',{staticClass:"primary lighten-1 text-center"},[_vm._v(" "+_vm._s(items[0].room)+" ")]),_c('td',{staticClass:"primary lighten-1 text-center"},[_vm._v(" BB ")]),_c('td',{staticClass:"primary lighten-1 text-right",attrs:{"colspan":_vm.range}})]}}],null,false,1046075178)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"align-self-center mr-3",attrs:{"color":"primary","rounded":""},on:{"click":_vm.exportCsv}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.export'))+" ")])],1)],1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }